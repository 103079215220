import styled from '@optios-frontend-website/shared-ui/src/theme'

export const ContentSectionWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

export const ContentSectionHeading = styled.h2`
  margin: 0;
  color: #36404e;
  font-size: 1.2em;
  padding: 6px;
`

export const ContentSection = styled.div`
  padding: 0 6px;
`

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`
