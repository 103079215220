import React, { FunctionComponent } from 'react'

import { Button } from './style'
import { IProps } from './IProps'

const ButtonModal: FunctionComponent<IProps> = ({ type, className, handleClick, children, customStyle }) => (
  <Button type={type} className={className} onClick={handleClick} style={customStyle}>
    {children}
  </Button>
)

export default ButtonModal
