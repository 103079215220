import styled from '@optios-frontend-website/shared-ui/src/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const NavigationContainer = styled.div`
  background: white;
`

export const Title = styled.h2`
  margin: 0;
  color: #36404e;
  font-size: 1.2em;
  padding: 18px;
`

export const SubText = styled.p`
  margin: 0;
  color: #545f71;
  font-size: 1em;
  padding: 0 18px;
`

export const ListItem = styled.div<{ selected: boolean; hover: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 20px;
  color: ${({ selected, hover }) => (selected ? '#303843' : hover ? '#5998df' : '#545f71')};
  background: ${({ selected }) => (selected ? '#e8f3fe' : 'transparent')};
  border-radius: ${({ selected }) => (selected ? '0 25px 25px 0' : '0')};
  cursor: ${({ hover }) => (hover ? 'pointer' : 'auto')};
  width: 80%;
  font-weight: 700;
  transition: background 0.3s ease, color 0.3s ease, border-radius 0.3s ease;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: ${({ selected }) => (selected ? '4px' : '0')};
    height: ${({ selected }) => (selected ? '100%' : '0')};
    background-color: ${({ selected }) => (selected ? '#5998df' : 'transparent')};
    transition: width 0.3s ease, height 0.3s ease, background 0.3s ease, color 0.3s ease, border-radius 0.3s ease;
  }
`

export const ItemIcon = styled(FontAwesomeIcon)<{ selected: boolean; hover: boolean }>`
  margin-right: 10px;
  color: ${({ selected, hover }) => (selected ? '#5998df' : hover && !selected ? '#5998df' : '#545f71')};
`
