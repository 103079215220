import React, { FC, useCallback, useEffect, useState } from 'react'

import InputField from 'shared/InputField'
import SelectField from 'shared/SelectField'
import { AddIcon, Button, FormContainer, SelectFieldTextWrapper, SelectFieldWrapper, Text } from './style'
import { Regexp } from '@optios-frontend-website/shared-ui/src/constants'
import { useTranslation } from 'react-i18next'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const socials = [
  { label: 'Facebook', value: 'facebook' },
  { label: 'Twitter', value: 'twitter' },
  { label: 'Instagram', value: 'instagram' },
]

type Props = {
  addNewSocial: (data: { name: string; link: string }) => void
  media?: FormValues
  submitClicked: boolean
}

type FormValues = {
  name: string
  link: string
}[]

type ErrorValues = {
  name: string
  link: string
}[]
const Footer: FC<Props> = (props: Props) => {
  const { addNewSocial } = props
  const [formValue, setValue] = useState<FormValues>([{ link: '', name: '' }])
  const [errors, setError] = useState<ErrorValues>([{ name: '', link: '' }])
  const { t } = useTranslation()

  const validateForm = useCallback(() => {
    let isValid = true
    const errorArray = formValue.map((formVal, index) => {
      const errors = { name: '', link: '' }
      if (formVal.name.length === 0) {
        isValid = false
        errors.name = 'This field is required'
      }
      if (!RegExp(Regexp.URL).test(formVal.link)) {
        isValid = false
        errors.link = 'url is not valid'
      }
      return errors
    })
    setError(errorArray)
    return isValid
  }, [formValue])

  const onSave = useCallback(() => {
    if (validateForm()) {
      formValue.forEach((formVal, index) => {
        if (formVal.name && formVal.link) {
          addNewSocial(formVal)
        }
      })
      setValue([{ link: '', name: '' }])
      setError([{ name: '', link: '' }])
    }
  }, [formValue, validateForm, addNewSocial])

  useEffect(() => {
    if (props.submitClicked) {
      onSave()
    }
  }, [props.submitClicked, onSave])

  const handleAddFields = () => {
    const values = [...formValue]
    values.push({ name: '', link: '' })
    setValue(values)
    const newErrors = [...errors]
    newErrors.push({ name: '', link: '' })
    setError(newErrors)
  }

  const handleChange = (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault()
    const { name, value } = e.target
    const values = [...formValue]
    values[index] = { ...values[index], [name]: value }
    setValue(values)
  }

  const handleSelect = (index: number, name: string, value: string) => {
    const values = [...formValue]
    values[index] = { ...values[index], [name]: value }
    setValue(values)
  }

  return (
    <FormContainer>
      {formValue.map((item, index) => (
        <div key={index}>
          <SelectFieldWrapper>
            <SelectFieldTextWrapper>
              <p>{t('Social media')}</p>
            </SelectFieldTextWrapper>
            <SelectField
              list={socials}
              name="name"
              defaultValue={item.name ? socials.find((social) => social.value === item.name) : undefined}
              label={t('Social media')}
              handleSelect={(name, value) => handleSelect(index, name, value)}
              error={errors[index].name}
            />
          </SelectFieldWrapper>

          <InputField
            onChange={(e) => handleChange(index, e)}
            value={item.link}
            error={errors[index].link}
            name="link"
            label={t('footer:media_link')}
          />
        </div>
      ))}
      <div style={{ textAlign: 'right', paddingTop: '0.5em' }}>
        <Button
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            handleAddFields()
          }}
        >
          <AddIcon icon={faPlus} />
          <Text>{t('Add social media')}</Text>
        </Button>
      </div>
    </FormContainer>
  )
}

export default Footer
