import React, { FunctionComponent, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useScreenClass } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { SortableContainer, SortEnd } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'

import NavigationList from 'components/Navigation/NavigationList'
import { IPageItem } from '../../../../shared-ui/src/types/page'
import { Wrapper as ItemWrapper } from './NavigationItem/style'
import { editPage } from 'services/website-service'
import { useSelector } from 'react-redux'
import { RootState } from 'store/index'

import { NavigationLogo, NavigationLogoWrapper, NavigationTitle, NavigationWrapper } from 'components/Navigation/style'
import { NavigationBurger } from 'components/Navigation/NavigationBurger/NavigationBurger'
import { ITheme } from 'store/theme/types'
import { useWindowDimensions } from '../../helpers/hooks/useWindowDimensions/useWindowDimensions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SortableNavigationList = SortableContainer(NavigationList)

interface IProps {
  currentPage: IPageItem
  pages: IPageItem[]
  logoSrc: string | undefined
  theme: ITheme
  setLogoOpen: (logoIsOpen: boolean) => void
  localPages: IPageItem[]
  setPages: (pages: IPageItem[]) => void
  websiteId: string | undefined
  isLogoVisible: boolean | undefined
  isTitleVisible: boolean | undefined
  title: string | undefined
}

export const Navigation: FunctionComponent<IProps> = ({
  currentPage,
  pages,
  logoSrc,
  theme,
  setLogoOpen,
  localPages,
  setPages,
  isLogoVisible,
  isTitleVisible,
  title,
}: IProps) => {
  const locale = useSelector<RootState, string>((state) => state.config.locale)
  const screenClass = useScreenClass()
  const { t } = useTranslation()
  const { windowWidth } = useWindowDimensions()

  useEffect(() => {
    const newPages = pages
      .filter((page) => page.locale === currentPage.locale && page.nav_location === 'navigation')
      .sort((a, b) => (a.order > b.order ? 1 : -1))

    setPages(newPages)
  }, [pages, currentPage.locale, setPages])

  const onSortEnd = (sort: SortEnd) => {
    let newPages = arrayMoveImmutable<IPageItem>(localPages, sort.oldIndex, sort.newIndex)
    setPages(newPages)

    newPages = newPages.map((beforeSort: IPageItem, index): IPageItem => {
      let afterSort = beforeSort
      if (index !== beforeSort.order) {
        afterSort = {
          ...beforeSort,
          order: index,
        }
        editPage(afterSort)
      }

      return afterSort
    })

    setPages(newPages)
  }

  return (
    <>
      {windowWidth < 900 ? (
        <NavigationBurger
          localPages={localPages}
          currentPage={currentPage}
          onSortEnd={onSortEnd}
          locale={locale}
          screenClass={screenClass}
          setLogoOpen={setLogoOpen}
          logoSrc={logoSrc}
        />
      ) : (
        <>
          {theme.title !== 'Classic' && (isLogoVisible || isTitleVisible) ? (
            <NavigationLogoWrapper>
              {isLogoVisible && (
                <NavigationLogo onClick={() => setLogoOpen(true)} src={logoSrc + '&w=100&h=80'} alt="navigation-logo" />
              )}
              {isTitleVisible && (
                <NavigationTitle onClick={() => setLogoOpen(true)}>{title}</NavigationTitle>
              )}
            </NavigationLogoWrapper>
          ) : (
            <NavigationLogoWrapper>
              <NavigationTitle onClick={() => setLogoOpen(true)}>{title}</NavigationTitle>
            </NavigationLogoWrapper>
          )}

          <NavigationWrapper>
            <SortableNavigationList
              /* @ts-expect-error */
              items={localPages as unknown}
              currentPage={currentPage}
              pages={localPages}
              onSortEnd={onSortEnd}
              axis={'x'}
              distance={10}
              open={false}
            />
            <ItemWrapper width={windowWidth} themeTitle={theme.title}>
              <Link className={screenClass} to="/page/new">
                {windowWidth < 1700 ? (
                  <FontAwesomeIcon icon={'circle-plus'} />
                ) : (
                  `${'+ ' + t('page:add_page', { lng: locale })}`
                )}
              </Link>
            </ItemWrapper>
          </NavigationWrapper>
        </>
      )}
    </>
  )
}
