import React, { FunctionComponent } from 'react'
import { IProps } from './IProps'
import { Icon } from 'components/PricingCrud/style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const ActionButtons: FunctionComponent<IProps> = ({
  deleteService,
  serviceIndex,
  category,
}) => {
  return (
    <>
      <Icon onClick={() => deleteService(serviceIndex, category)} className={`delete`}>
        <FontAwesomeIcon icon={'circle-minus'} />
      </Icon>
    </>
  )
}
