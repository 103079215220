import React, { useState, FC, useEffect, useMemo } from 'react'
import { useForm, Resolver } from 'react-hook-form'

import { WEBSITE_LOGO } from '../../../../constants'
import Dropzone from 'shared/Dropzone'
import Modal from 'shared/Modal'
import InputFiled from 'shared/InputField'
import Button from 'shared/ButtonModal'
import { IHeader } from 'store/page/types'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'store/index'

type FileData = {
  name: string | null
  data: string | ArrayBuffer | null
}

type Props = {
  addWebsiteFile: (asset_type: typeof WEBSITE_LOGO, file: File) => void
  open: boolean
  data: IHeader
  setIsOpen: (open: boolean) => void
  onSubmit: (data: IHeader) => void
}

const resolver: Resolver<IHeader> = async (values: IHeader) => {
  const oneIsVisible = {
    is_logo_visible: {
      type: 'required',
      message: 'One should be visible.',
    },
  }
  const visibleError = !values.is_logo_visible && !values.is_title_visible ? oneIsVisible : {}

  return {
    values: values || {},
    errors: visibleError,
  }
}

const ModalLogo: FC<Props> = (props: Props) => {
  const defaultFormObject = {
    title: props.data.title,
    is_logo_visible: props.data.is_logo_visible,
    is_title_visible: props.data.is_title_visible,
  }

  const { register, handleSubmit, errors, reset } = useForm<IHeader>({
    defaultValues: useMemo(() => defaultFormObject, [defaultFormObject]),
    resolver,
  })

  useEffect(() => {
    reset(props.data)
  }, [props.data, reset])

  const { t } = useTranslation()
  const locale = useSelector<RootState, string>((state) => state.config.locale)
  const [file, setFile] = useState<FileData>({
    name: '',
    data: null,
  })

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const data = reader.result
        setFile({ data, name: file.name })
        props.addWebsiteFile(WEBSITE_LOGO, file)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const onRequestClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    props.setIsOpen(false)
  }

  return (
    <Modal title={t('logo:title_modal', { lng: locale })} isOpen={props.open} onRequestClose={onRequestClose}>
      <form
        onSubmit={handleSubmit((data) => {
          props.onSubmit({
            ...data,
            background_image: props.data.background_image,
          })
          reset(data)
        })}
      >
        <InputFiled label={t('logo:title', { lng: locale })} name="title" registerRef={register} />
        <InputFiled
          label={t('logo:is_title_visible', { lng: locale })}
          type="checkbox"
          name="is_title_visible"
          registerRef={register}
        />
        <InputFiled
          label={t('logo:is_logo_visible', { lng: locale })}
          type="checkbox"
          name="is_logo_visible"
          error={errors.is_logo_visible?.message}
          registerRef={register}
        />
        <Dropzone onDrop={onDrop} fileName={file.name} locale={locale} />
        <div style={{ textAlign: 'right' }}>
          <Button handleClick={onRequestClose} className="dialog-close">
            {t('page:cancel', { lng: locale })}
          </Button>
          <Button type="submit">{t('page:save', { lng: locale })}</Button>
        </div>
      </form>
    </Modal>
  )
}

export default ModalLogo
