import {
  ADD_FIXED_BUTTON_SECTION,
  ADD_INLINE_SECTION,
  ADD_NEW_SECTION,
  ADD_PAGES,
  DELETE_SECTION,
  EDIT_PAGE,
  EDIT_PAGE_SECTION,
  IAdminPage,
  IAdminPageItem,
  IInlineSections,
  IPageItem,
  ISection,
  PageActionTypes,
  SET_CURRENT_PAGE,
  SORT_PAGES,
  IInline,
} from './types'
import { initialNewSection } from 'services/sections'
import { SectionNames } from '../../constants'
import { editPage } from 'services/website-service'

const initialState: IAdminPage = {
  currentPage: null,
  list: [],
}

export const pageReducer = (state = initialState, action: PageActionTypes): IAdminPage => {
  switch (action.type) {
    case ADD_PAGES:
      return {
        ...state,
        list: action.payload.map((page: IPageItem): IAdminPageItem => {
          return { ...page, isUpdated: true }
        }),
        // .sort((a, b) => (a.order > b.order ? 1 : -1)),
      }
    case SORT_PAGES:
      return {
        ...state,
        list: state.list.map((page: IAdminPageItem): IAdminPageItem => {
          return {
            ...page,
            order: action.payload.findIndex((sortedPage: IPageItem) => sortedPage.id === page.id),
          }
        }),
        // .sort((a, b) => (a.order > b.order ? 1 : -1)),
      }
    case EDIT_PAGE:
      return {
        ...state,
        list: state.list.map((item) => {
          if (item.id === action.payload.id) {
            return { ...action.payload, isUpdated: false }
          } else {
            return item
          }
        }),
        currentPage: state.currentPage ? { ...state.currentPage, isUpdated: false } : null,
      }
    case SET_CURRENT_PAGE: {
      if (!action.payload) {
        return state
      }

      return { ...state, currentPage: { ...action.payload, isUpdated: false } }
    }
    case EDIT_PAGE_SECTION: {
      if (!state.currentPage) {
        return state
      }
      const { position, section, col, name } = action.payload
      const currentSection = state.currentPage.sections[position]
      if (currentSection.name === SectionNames.INLINE && col !== undefined) {
        if ('sections' in currentSection.data) {
          ;(currentSection.data.sections as IInlineSections[]).splice(col, 1, {
            name,
            data: section,
          } as IInlineSections)
        }
        state.currentPage.sections[position].data = currentSection.data
      } else if (currentSection.name === SectionNames.FIXEDBUTTON && col !== undefined) {
        state.currentPage.sections[position].data = currentSection.data
      } else {
        state.currentPage.sections[position].data = section
      }

      editPage(state.currentPage)

      return {
        ...state,
        currentPage: { ...state.currentPage, sections: [...state.currentPage.sections], isUpdated: false },
      }
    }

    case ADD_NEW_SECTION: {
      const { position, name, data } = action.payload
      if (state.currentPage && data) {
        switch (name) {
          case SectionNames.FOOTER: {
            state.currentPage?.sections?.push({ name, data })
            break
          }
          case SectionNames.HEADER: {
            state.currentPage?.sections?.unshift({ name, data })
            break
          }
          case SectionNames.HTML:
          case SectionNames.FORM:
          case SectionNames.PHOTO:
          case SectionNames.EMPLOYEE:
          case SectionNames.BUTTON:
          case SectionNames.CONTACT: {
            state.currentPage?.sections?.splice(position + 1, 0, {
              name: SectionNames.INLINE,
              data: {
                sections: [{ name, data }],
                backgroundImage: undefined,
                backgroundFontColor: '#000000',
                backgroundContrast: 0,
              } as IInline,
            })
            break
          }
          default: {
            state.currentPage?.sections?.splice(position + 1, 0, { name, data })
          }
        }

        editPage(state.currentPage)

        return {
          ...state,
          currentPage: { ...state.currentPage, sections: [...(state.currentPage?.sections || [])], isUpdated: false },
        }
      } else {
        return state
      }
    }
    case ADD_INLINE_SECTION: {
      const { position, name } = action.payload
      const newSection = initialNewSection(name)
      const inlineSection: ISection | undefined = state.currentPage?.sections
        ? state.currentPage?.sections[position]
        : undefined
      if (state.currentPage && inlineSection && inlineSection.name === SectionNames.INLINE) {
        if ('sections' in inlineSection.data) {
          ;(inlineSection.data.sections as IInlineSections[]).push({ name, data: newSection } as IInlineSections)
        }
        state.currentPage?.sections?.splice(position, 1, inlineSection)
        return { ...state, currentPage: { ...state.currentPage, isUpdated: true } }
      } else if (state.currentPage && inlineSection) {
        state.currentPage.sections.splice(position, 1, {
          name: SectionNames.INLINE,
          data: [inlineSection as IInlineSections, { name, data: newSection } as IInlineSections],
        })
        return { ...state, currentPage: { ...state.currentPage, isUpdated: true } }
      }
      return state
    }
    case ADD_FIXED_BUTTON_SECTION: {
      const { position, name } = action.payload
      const newSection = initialNewSection(name)
      const inlineSection: ISection | undefined = state.currentPage?.sections
        ? state.currentPage?.sections[position]
        : undefined
      if (state.currentPage && inlineSection && inlineSection.name === SectionNames.INLINE) {
        ;(inlineSection.data as IInlineSections[]).push({ name, data: newSection } as IInlineSections)
        state.currentPage?.sections?.splice(position, 1, inlineSection)
        return { ...state, currentPage: { ...state.currentPage, isUpdated: true } }
      } else if (state.currentPage && inlineSection) {
        state.currentPage.sections.splice(position, 1, {
          name: SectionNames.INLINE,
          data: [inlineSection as IInlineSections, { name, data: newSection } as IInlineSections],
        })
        return { ...state, currentPage: { ...state.currentPage, isUpdated: true } }
      }
      return state
    }
    case DELETE_SECTION: {
      const { col, position } = action.payload
      if (state.currentPage) {
        const section = state.currentPage.sections[position]
        if (section && section.name === SectionNames.INLINE && col !== undefined && 'sections' in section.data) {
          const sections = section.data.sections as IInlineSections[]
          if (sections.length === 1) {
            state.currentPage.sections?.splice(position, 1)
          } else {
            sections.splice(col, 1)
          }
        } else {
          state.currentPage.sections?.splice(position, 1)
        }
        return { ...state, currentPage: { ...state.currentPage, isUpdated: true } }
      } else {
        return state
      }
    }
    default:
      return state
  }
}
