import { keyframes } from '@emotion/core'
import styled from '@optios-frontend-website/shared-ui/src/theme'
import Modal from 'react-modal'

const slideDown = keyframes`
  from {
    transform: translate(-50%,-100%);
  }
  to {
    transform: translate(-50%, 0%);
  }
`

export const ReactModal = styled(Modal)<{ minWidth?: string }>`
  min-width: ${({ minWidth }) => (minWidth ? minWidth : '30em')};
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  animation: ${slideDown} ease-out;
  animation-duration: 300ms;
  &.dialog-size-large {
    width: 70em;
    &.lg,
    &.md,
    &.sm,
    &.xs {
      width: 100%;
    }
  }
  &.sm,
  &.xs {
    min-width: 0;
    width: 100%;
  }
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: normal;
  font-style: normal;
  max-height: 100%;
`
export const DialogContent = styled.div`
  padding: 1em 1.5em 1.5em;
`

export const CloseButton = styled.i`
  position: absolute;
  top: 0.8em;
  right: 0.7em;
  font-size: 1.4em;
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
`

export const Title = styled.h1`
  text-align: center;
  font-size: 1.3em;
  font-weight: 400;
  margin: 0 0 1.2em;
  padding: 0 1.5em;
  line-height: 1.2;
`
export const DialogActions = styled.div`
  text-align: right;
`

export const Button = styled.button`
  display: inline-block;
  appearance: none;
  padding: 0.8em 1.1em;
  margin: 0 0 0 0.3em;
  border: 3px solid;
  border-radius: 99px;
  background: none;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 600;
  font-size: 1.1em;
  transition: all 0.2s ease;
  cursor: pointer;
  vertical-align: middle;
  &:focus {
    outline: none;
  }
  &:hover {
    background: ${(props) => props.theme.colors.primary};
    border-color: transparent;
    color: #fff;
  }
  &.dialog-close {
    color: rgba(0, 0, 0, 0.3);
    &:hover {
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
    }
  }
  &.dialog-delete {
    color: #e41b17;
    &:hover {
      background: #e41b17;
      color: #fff;
    }
  }
`
