import React, { FunctionComponent } from 'react'
import { SortableElement } from 'react-sortable-hoc'

import NavigationItem from '../NavigationItem'
import { IPageItem } from '../../../../../shared-ui/src/types/page'

import { Wrapper } from './style'

type Props = {
  items: IPageItem[]
  currentPage: IPageItem
  open: boolean
  pages?: IPageItem[]
}

const SortableNavigationItem = SortableElement(NavigationItem)

const NavigationList: FunctionComponent<Props> = ({ items, currentPage, pages, open }: Props) => {
  return (
    <Wrapper open={open}>
      <div className="inner-wrapper">
        {items.map((page, i) => {
          return (
            <SortableNavigationItem
              /* @ts-expect-error */
              item={page}
              open={open}
              isActive={page.id === currentPage.id}
              index={i}
              pages={pages}
              key={`navitem${page.id}`}
            />
          )
        })}
      </div>
    </Wrapper>
  )
}

export default NavigationList