import styled from '../../../theme'
import { BaseText } from '../../../base-style'

export const ItemBLock = styled.section`
  clear: none;
  margin-left: 0;
  &.md,
  &.sm,
  &.xs {
    width: 100%;
  }
`

export const Box = styled.div`
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  padding: 16px;
  &.md,
  &.sm,
  &.xs {
    position: relative;
    margin-top: 1rem;
    &.first {
      margin-top: 0;
    }
  }
`

type Props = {
  bgColor: string
}

export const Icon = styled.i<Props>`
  text-align: center;
  cursor: default;
  background-color: ${(props) => props.bgColor};
  background-image: linear-gradient(top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15));
  color: ${({ theme }) => theme.textAndImage.iconColor};
  border-radius: 100%;
  display: inline-block;
  width: 5em;
  height: 5em;
  line-height: 5.5em;
  box-shadow: 0 0 0 7px white, 0 0 0 8px ${(props) => props.theme.textAndImage.borderColor};
  margin: 0 0 2em 0;
  transform-origin: 50% 75%;
  transition: transform 0.8s ease 0s;

  &:before {
    font-size: ${({ theme }) => theme.textAndImage.iconSize};
  }
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  &.xs {
    width: 4em;
    height: 4em;
    line-height: 4em;
    &:before {
      font-size: ${({ theme }) => theme.sizes.large};
    }
  }
  &.md,
  &.sm,
  &.xs {
    text-align: left;
    position: absolute;
    padding-left: 7em;
    margin: 0;
    left: 0;
    top: 0.25em;
  }
  &.xs {
    padding-left: calc(4em + 30px);
  }
  svg {
    font-size: 1.6em;
  }
`
export const Title = styled.h2`
  font-size: ${({ theme }) => theme.textAndImage.title.fontSize};
  font-family: ${({ theme }) => theme.textAndImage.title.fontFamily};
  font-weight: ${({ theme }) => theme.textAndImage.title.fontWeight};
  color: #000000;
  line-height: ${({ theme }) => theme.textAndImage.title.lineHeight};

  &.md,
  &.sm,
  &.xs {
    margin-bottom: 0.5em;
  }

  &:after {
    content: ${({ theme }) => (theme.textAndImage.title.isSemiUnderlined ? '' : null)}
    display: block;
    position: relative;
    width: 20%;
    border-bottom: 2px solid ${({ theme }) => theme.textAndImage.title.color}
    margin-top: 8px;
    left: 40%;
  }
`

export const Text = styled(BaseText)`
  color: #000000;
  white-space: normal;
  word-break: break-word;
`
