import Axios from 'axios'
import Cookie from 'js-cookie'

const axiosClient = Axios.create({
  baseURL: `${process.env.REACT_APP_CLIENT_API}`,
})

axiosClient.interceptors.request.use(function (config) {
  let token = Cookie.get('access_token') ? Cookie.get('access_token') : ''
  if (token === '') {
    const matches = window.location.pathname.match(/\/auth\/([a-z0-9A-Z-]+)/)
    if (null !== matches && matches.length >= 2) {
      token = matches[1]
    }
  }
  config.headers.common['X-Custom-Auth'] = token
  config.headers.common['Content-Type'] = 'application/json'
  return config
})

export default axiosClient
