import styled from '@optios-frontend-website/shared-ui/src/theme'
import { Link } from 'react-router-dom'

export const Title = styled.h3`
  margin-bottom: 1em;
`

export const Wrapper = styled.div`
  margin: 50px auto;
  width: 30em;
`

const CommonBtn = `
  font: inherit;
  color: #fff;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0 1.5em;
  line-height: 2.75em;
  min-width: 9em;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.025em;
  outline: none;
  margin: 0;
  margin-top: 10px;
  float: right;
  &.xs {
    width: 100%;
  }
`
export const Button = styled.button`
  ${CommonBtn}
  float: right;
  background-color: ${(props) => props.theme.colors.submit};
  :hover {
    background-color: ${(props) => props.theme.colors.submit_hover};
  }
`
export const DeleteButton = styled.button`
  ${CommonBtn}
  float: right;
  background: #e41b17;
  margin-right: 0.5em;
  :hover {
    background-color: #e81a17;
  }
`

export const IconBack = styled.i`
  margin-right: 1em;
`

export const LinkToBack = styled(Link)`
  text-decoration: none;
  margin-bottom: 2em;
  display: inline-block;
  color: ${(props) => props.theme.colors.link};
`
