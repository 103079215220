import React, { FC } from 'react'
import SharedLanguages from '@optios-frontend-website/shared-ui/src/components/Languages'
import { ITheme } from 'store/theme/types'

type Props = {
  theme: ITheme
  currentLanguage: string
  updateLanguage: (language: string) => void
  languages: string[]
}
const Languages: FC<Props> = (props: Props) => {
  if (props.languages.length <= 1) {
    return null
  }

  return (
    <SharedLanguages
      updateLanguage={props.updateLanguage}
      languages={props.languages}
      currentLanguage={props.currentLanguage}
      theme={props.theme}
    />
  )
}

export default Languages
