import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContentSection,
  ContentSectionHeading,
  ContentSectionWrapper,
  Image,
} from 'shared/SectionControl/NewSectionModal/components/ModalContent/pages/style'

import ContactSectionImage from '../../assets/ContactSection/contact-section.png'

export const ContactPage = () => {
  const { t } = useTranslation()

  return (
    <ContentSectionWrapper>
      <ContentSectionHeading>{t('Contact section')}</ContentSectionHeading>
      <ContentSection>
        <Image alt="" src={ContactSectionImage} />
      </ContentSection>
    </ContentSectionWrapper>
  )
}
