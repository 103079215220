export const ADD_THEMES = '@@theme/THEME_ADDED'

export interface ITheme {
  id: string
  primary_color: string
  secondary_color: string
  title: string
}

export interface ThemeAddAction {
  type: typeof ADD_THEMES
  payload: ITheme[]
}

export type ThemeActionTypes = ThemeAddAction
