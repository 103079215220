import React, { FC, ReactNode, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useScreenClass } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { SectionNames } from '@optios-frontend-website/shared-ui/src/constants'
import { ApplicationState, RootState } from 'store'
import { IPageItem } from 'store/page/types'
import { addNewSection, deleteSection } from 'store/page/actions'
import {
  // AddNew,
  Button, Collapse, Container, Content,
  // DeleteFixedButtonIcon, ExpandBit, SelectArrow
} from './style'
import SelectField from 'shared/SelectField'
import Modal from 'shared/Modal'
import ButtonModal from 'shared/ButtonModal'
import { initialNewSection } from 'services/sections'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  children: ReactNode
  isAdd?: boolean
  isDelete?: boolean
  position: number
  col?: number
  isPageManagement?: boolean
  currentPage: IPageItem | null
}

type Item = {
  label: string
  value: SectionNames
}

const list: Item[] = [
  { label: 'section:fixedButton', value: SectionNames.FIXEDBUTTON },
]

const FixedButtonSectionControl: FC<Props & typeof mapDispatch> = (props: Props & typeof mapDispatch) => {
  const {
    // Webshop button DO NOT REMOVE !!
    // children,
    // isAdd = true,
    // isDelete = true,
    position,
    deleteSection,
    addNewSection,
    currentPage,
    col,
    // Webshop button DO NOT REMOVE !!
    // isPageManagement,
  } = props
  const [selectedItem, setItem] = useState<Item | null>(null)
  const [active, setActive] = useState(false)
  const [activeList, setActiveList] = useState<Item[]>(list)
  const [isOpen, setOpen] = useState(false)
  const { t } = useTranslation()
  const locale = useSelector<RootState, string>((state) => state.config.locale)

  const handleSelect = (name: string, value: string) => {
    setItem({ label: name, value: value as SectionNames })
  }
  const screenClass = useScreenClass()

  useEffect(() => {
    const singleSections = [SectionNames.HEADER, SectionNames.FOOTER]

    if (currentPage && currentPage.sections?.length) {
      const sectionNames = currentPage.sections.map((section) => section.name)
      const newActiveList = list.reduce((state: Item[], item: Item) => {
        if (sectionNames.includes(item.value) && singleSections.includes(item.value)) {
          return state
        } else {
          state.push(item)
          return state
        }
      }, [])
      setActiveList(newActiveList)
    }
  }, [currentPage])
  const handleModalOpen = () => {
    setOpen(!isOpen)
  }

  const handleDeleteSection = () => {
    if (position >= 0) {
      deleteSection(position, col)
    }
  }

  const handleSubmit = () => {
    if (position >= 0 && selectedItem) {
      const data = initialNewSection(selectedItem.value)
      addNewSection(position, selectedItem.value, data)
    }
    setActive(false)
  }

  return (
    <Container>
      {/*{isDelete && <DeleteFixedButtonIcon onClick={handleModalOpen}><FontAwesomeIcon icon={'trash'} /></DeleteFixedButtonIcon>}*/}
      {/*{children}*/}

      {/*Webshop button DO NOT REMOVE !!*/}
      {/*{isAdd && (position !== 0 || isPageManagement) && (*/}
      {/*  <ExpandBit onClick={() => setActive(!active)}>*/}
      {/*    <AddNew>{t('page:add_new_section', { lng: locale })}</AddNew>*/}
      {/*    <SelectArrow className={active ? 'active' : ''} />*/}
      {/*  </ExpandBit>*/}
      {/*)}*/}
      <Collapse className={active ? 'active' : ''}>
        <Content  className={screenClass}>
          <SelectField
            list={activeList}
            name="section"
            label={t('page:add_new_section', { lng: locale })}
            handleSelect={handleSelect}
          />
          <Button onClick={handleSubmit}> {t('page:save', { lng: locale })}</Button>
        </Content>
      </Collapse>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleModalOpen}
        onDelete={() => handleDeleteSection()}
        title={t('page:delete', { lng: locale })}
      >
        {t('page:are_you_sure', { lng: locale })}
        <div style={{ textAlign: 'right' }}>
          <ButtonModal handleClick={handleModalOpen} className="dialog-close">
            {t('page:cancel', { lng: locale })}
          </ButtonModal>
          <ButtonModal className="dialog-delete" handleClick={handleDeleteSection}>
            {t('page:delete', { lng: locale })}
          </ButtonModal>
        </div>
      </Modal>
    </Container>
  )
}

const mapState = (state: ApplicationState) => ({
  currentPage: state.page.currentPage,
})

const mapDispatch = {
  addNewSection,
  deleteSection,
}

export default connect(mapState, mapDispatch)(FixedButtonSectionControl)
