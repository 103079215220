import styled from '@optios-frontend-website/shared-ui/src/theme'

export const Icon = styled.i`
  cursor: pointer;
  margin-right: 0.5em;
  &.delete {
    color: red;
  }
  &.add {
    color: green;
  }
`
export const Error = styled.span`
  color: ${(props) => props.theme.colors.danger};
`



