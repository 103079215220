import React, { useState } from 'react'
import {
  Backdrop,
  Button,
  ButtonIcon,
  ButtonWrapper,
  Card,
  ContentWrapper,
  ModalWrapper,
  NavigationWrapper,
  SelectSectionWrapper,
} from 'shared/SectionControl/NewSectionModal/style'
import { IProps } from 'shared/SectionControl/NewSectionModal/IProps'
import { ModalNavigation } from 'shared/SectionControl/NewSectionModal/components/ModalNavigation/ModalNavigation'
import { SectionNames } from '@optios-frontend-website/shared-ui/src/constants'

import {
  TextPage,
  IconTextPage,
  GalleryPage,
  PricingPage,
  PhotoPage,
  MapPage,
  FeedbackPage,
  ContactPage,
  EmployeesPage,
  FormPage,
  ButtonPage,
} from './components/ModalContent/pages/index'
import { useTranslation } from 'react-i18next'
import { initialNewSection } from 'services/sections'
import { addNewSection } from 'store/page/actions'
import { useDispatch } from 'react-redux'

export const NewSectionModal = ({ setIsModalOpen, position, selectedItem, setItem, activeList }: IProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedValue, setSelectedValue] = useState<string>('')

  const renderSections = () => {
    switch (selectedValue) {
      case SectionNames.HTML:
        return <TextPage />
      case SectionNames.TEXTANDIMAGES:
        return <IconTextPage />
      case SectionNames.GALLERY:
        return <GalleryPage />
      case SectionNames.PRICING:
        return <PricingPage />
      case SectionNames.PHOTO:
        return <PhotoPage />
      case SectionNames.MAP:
        return <MapPage />
      case SectionNames.FEEDBACK:
        return <FeedbackPage />
      case SectionNames.CONTACT:
        return <ContactPage />
      case SectionNames.EMPLOYEE:
        return <EmployeesPage />
      case SectionNames.FORM:
        return <FormPage />
      case SectionNames.BUTTON:
        return <ButtonPage />
      default:
        return <SelectSectionWrapper>{t('Please select a section')}</SelectSectionWrapper>
    }
  }

  const handleCancel = () => {
    setSelectedValue('')
    setIsModalOpen(false)
    setSelectedValue('')
  }

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (position >= 0 && selectedItem) {
      const data = initialNewSection(selectedItem.value)
      dispatch(addNewSection(position, selectedItem.value, data))
    }
    setIsModalOpen(false)
    setSelectedValue('')
  }

  const handleContentMissClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
  }

  return (
    <ModalWrapper>
      <Backdrop onClick={() => setIsModalOpen(false)}>
        <Card>
          <NavigationWrapper>
            <ModalNavigation
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              setItem={setItem}
              activeList={activeList}
            />
          </NavigationWrapper>
          <ContentWrapper onClick={handleContentMissClick}>
            {renderSections()}
            {selectedValue && (
              <ButtonWrapper>
                <Button onClick={handleCancel}>{t('Cancel')}</Button>
                <Button onClick={(e) => handleSave(e)}>
                  <ButtonIcon icon={'save'} />
                  {t('Save')}
                </Button>
              </ButtonWrapper>
            )}
          </ContentWrapper>
        </Card>
      </Backdrop>
    </ModalWrapper>
  )
}
