import { Theme } from '../theme'

const error = '#d24444'
const info = '#0090ff'
const infoHover = '#70befa'

const themeClassic: Theme = {
  textAndImage: {
    title: {
      fontSize: '1.2em',
      fontFamily: 'proxima-nova, sans-serif',
      fontWeight: 600,
      color: 'inherit',
      lineHeight: '1.75em',
      isSemiUnderlined: false,
    },
    iconColor: '#ffffff',
    iconSize: '24px',
    borderColor: '#e0e0e0',
  },
  colors: {
    backgroundFirst: '#ffffff',
    backgroundSecond: '#f7f7f7',
    primary: '#ffffff',
    secondary: 'rgba(27, 27, 27, 0.75)',
    feedbackTitle: '#859391',
    feedbackParagraph: '#050505',
    feedbackParagraphFallback: '#050505',
    blackFont: '#000000',
    whiteFont: '#ffffff',
    success: '#69d321',
    warning: '#fbb03b',
    error: error,
    info: info,
    infoHover: infoHover,
    destructive: error,
    danger: error,
    link: info,
  },
  shadows: {
    light: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    medium: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    dark: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  },
  sizes: {
    xsmall: '0.8em',
    small: '1em',
    medium: '1.2em',
    large: '1.5em',
    xlarge: '2em',
  },
  fonts: {
    primary: 'proxima-nova, sans-serif',
    secondary: 'proxima-nova, sans-serif',
    third: 'proxima-nova, sans-serif',
    fourth: 'proxima-nova, sans-serif',
  },
  fontWeights: {
    light: '300',
    regular: '400',
    medium: '500',
    bold: '700',
  },
  // Theme sections customization

  // Navigation
  title: {
    fontDecoration: 'normal',
    fontDecorationBottom: '1px solid black',
  },
  //Pricing
  pricing: {
    pricingContainerWidth: '750px',
    titleDirection: 'left',
    titleDecoration: 'none',
    descriptionDecoration: '#d4d4d4',
    bookButtonMarginLeft: '24px'
  },
  // Contact
  contact: {
    titleDecoration: '1px solid #d1d1d1;',
  },
  // Contact form
  contactForm: {
    inputDirection: 'block',
  },
}

export default themeClassic
