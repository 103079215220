import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContentSection,
  ContentSectionHeading,
  ContentSectionWrapper,
  Image,
} from 'shared/SectionControl/NewSectionModal/components/ModalContent/pages/style'

import TextSectionImage from '../../assets/TextContentSection/text-section.png'

export const TextPage = () => {
  const { t } = useTranslation()

  return (
    <ContentSectionWrapper>
      <ContentSectionHeading>{t('Text section')}</ContentSectionHeading>
      <ContentSection>
        <Image alt="" src={TextSectionImage} />
      </ContentSection>
    </ContentSectionWrapper>
  )
}
