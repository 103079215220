import styled from '@optios-frontend-website/shared-ui/src/theme'

export const BaseTitle = styled.h3`
  margin-bottom: 1em;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.sizes.medium};
  letter-spacing: -0.025em;
  font-weight: 600;
  line-height: 1.75em;
  &.md {
    margin-bottom: 0.5em;
  }
  &.sm,
  &.xs {
    font-size: ${({ theme }) => theme.sizes.small};
    letter-spacing: 0;
    line-height: 1.35em;
  }
`
export const BaseText = styled.p`
  margin: 0 0 2em 0;
  line-height: 1.5;
  font-weight: 300 !important;
  width: 100%;
  text-align: center;
  word-break: break-all;
`
