import styled from '@optios-frontend-website/shared-ui/src/theme'
import { BaseText, BaseTitle } from '../../base-style'

export const Container = styled.section`
`

export const Title = styled(BaseTitle)`
  :first-of-type {
    margin-top: 0px;
  }
  margin-bottom: 0.4em;
  margin-top: 0.8em;
`
export const Text = styled(BaseText)``

export const HTMLData = styled.div<{
  fontColor: string
}>`
  margin: 0;
  padding: 0;
  ol {
    padding-left: 1.5em;
    list-style-type: decimal;
  }
  ul {
    padding-left: 1.5em;
    list-style-type: disc;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  h1,
  h2,
  h3 {
    line-height: 1.75em;
    margin-bottom: 0.4em;
  }
  h1 {
    font-size: ${({ theme }) => theme.sizes.xsmall};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.blackFont};
    text-transform: uppercase;
  }
  h2 {
    font-size: ${({ theme }) => theme.sizes.xlarge};
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.feedbackTitle};
    line-height: 1.2;
    border-bottom: none;
  }

  h2:after {
    content: '';
    display: block;
    position: relative;
    width: 10%;
    border-bottom: 2px solid ${({ theme }) => theme.colors.blackFont};
    margin-top: 8px;
  }

  h3 {
    font-size: ${({ theme }) => theme.sizes.medium};
    font-family: ${({ theme }) => theme.fonts.primary};
    color: ${({ theme, fontColor }) => (fontColor ? fontColor : theme.colors.blackFont)};
  }
  p {
    font-size: ${({ theme }) => theme.sizes.small};
    font-family: ${({ theme }) => theme.fonts.primary};
    letter-spacing: 0.3px;
    line-height: 1.7;
    color: ${({ theme, fontColor }) => (fontColor ? fontColor : theme.colors.blackFont)};
    -webkit-font-smoothing: antialiased;
  }
`
