export const ADD_WEBSITE = '@@website/WEBSITE_ADDED'
export const EDIT_WEBSITE = '@@website/WEBSITE_EDITED'
export const UPDATE_CURRENT_LANGUAGE = '@@website/UPDATE_CURRENT_LANGUAGE'

export interface IWebsite {
  establishmentId: string
  id?: string
  organization_id?: string
  slug: string
  domain: string
  title: string
  theme_id?: string
  languages: string[]
  currentLanguage: string
  default_language: string
  email?: string
  facebookPageIdForChat?: string
}

export interface WebsiteAddAction {
  type: typeof ADD_WEBSITE
  payload: IWebsite
}

export interface WebsiteUpdateCurrentLanguageAction {
  type: typeof UPDATE_CURRENT_LANGUAGE
  payload: string
}

export interface WebsiteEditAction {
  type: typeof EDIT_WEBSITE
  payload: IWebsite
}

export type WebsiteActionTypes = WebsiteAddAction | WebsiteEditAction | WebsiteUpdateCurrentLanguageAction
