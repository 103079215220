import { Action } from 'redux'
import {
  IFeedbackDataConfig,
  IFileDataConfig,
  IBookingDataConfig,
  IConfigSection,
} from '@optios-frontend-website/shared-ui/src/types/config'
import { IMenuCategory } from 'components/PricingCrud/components/ActivitySelect/interface/IMenuCategory'
import { IActivityCategory } from 'components/PricingCrud/components/ActivitySelect/interface/IActivity'
import { store } from 'store/index'
export * from '@optios-frontend-website/shared-ui/src/types/config'

export const ADD_CONFIG = '@@config/CONFIG_ADDED'
export const ADD_CONFIG_SLUG = '@@config/ADD_CONFIG_SLUG'

export const GET_MENU_CATEGORIES_SUCCESS = 'GET_MENU_CATEGORIES_SUCCESS'

export const GET_MENU_CATEGORIES_FAILURE = 'GET_MENU_CATEGORIES_FAILURE'

export const GET_MENU_CATEGORIES = 'GET_MENU_CATEGORIES'
export const GET_EXTRA_ACTIVITIES_SUCCESS = 'GET_EXTRA_ACTIVITIES_SUCCESS'

export const GET_EXTRA_ACTIVITIES_FAILURE = 'GET_EXTRA_ACTIVITIES_FAILURE'

export const GET_EXTRA_ACTIVITIES = 'GET_EXTRA_ACTIVITIES'

export interface ITextAndImageConfig {
  color: { [key: string]: string }
  icon: string[]
}

export interface ISectionsConfig {
  booking: IConfigSection
  contact: IConfigSection
  feedbacks: IConfigSection
  footer: IConfigSection
  gallery: IConfigSection
  header: IConfigSection
  map: IConfigSection
  pricing: IConfigSection
  textandimages: IConfigSection
}

export interface IFacebookPage {
  external_business_id: string
  external_business_name: string
  business_manager_id: string
  status?: string
}

export interface IStorageConfig {
  asset_types: string[]
  upload_url: string
  url: string
  shopify_url: string
  woocommerce_url: string
}

export interface IData {
  textandimages: ITextAndImageConfig | null
  feedback: IFeedbackDataConfig | null
  gallery: IFileDataConfig | null
  banner: IFileDataConfig | null
  logo: IFileDataConfig | null
  booking: IBookingDataConfig | null
  menu: IMenuCategory[] | null
  activities: IActivityCategory[] | null
}

export interface IConfig {
  languages: {
    [key: string]: string
  }
  locale: string
  is_shopify_enabled: boolean
  is_woo_commerce_enabled: boolean
  sections: ISectionsConfig
  storageConfig: IStorageConfig
  facebook_pages: IFacebookPage[]
  data: IData
  isLoading: boolean
}

export type SectionName =
  | 'booking'
  | 'contact'
  | 'feedbacks'
  | 'footer'
  | 'gallery'
  | 'header'
  | 'map'
  | 'pricing'
  | 'textandimages'

export interface ConfigAddAction extends Action {
  type: typeof ADD_CONFIG
  payload: IConfig
}

export interface ConfigAddBySlugAction extends Action {
  type: typeof ADD_CONFIG_SLUG
  payload: { name: string; section: IConfigData }
}

export interface GetActivityAction extends Action {
  type: typeof GET_EXTRA_ACTIVITIES
  payload: { isLoading: true }
}

export interface GetActivityActionSuccess extends Action {
  type: typeof GET_EXTRA_ACTIVITIES_SUCCESS
  payload: { name: string; activity: IActivityCategory[]; isLoading: false }
}

export interface GetActivityActionFailure extends Action {
  type: typeof GET_EXTRA_ACTIVITIES_FAILURE
  payload: { isLoading: false }
}

export interface GetMenuAction extends Action {
  type: typeof GET_MENU_CATEGORIES
  payload: { isLoading: true }
}

export interface GetMenuActionSuccess extends Action {
  type: typeof GET_MENU_CATEGORIES_SUCCESS
  payload: { name: string; menu: IMenuCategory[]; isLoading: false }
}

export interface GetMenuActionFailure extends Action {
  type: typeof GET_MENU_CATEGORIES_FAILURE
  payload: { isLoading: false }
}

export type IConfigData = ITextAndImageConfig | IFeedbackDataConfig | IFileDataConfig | IBookingDataConfig
export type ConfigActionTypes =
  | ConfigAddAction
  | ConfigAddBySlugAction
  | GetActivityAction
  | GetMenuAction
  | GetActivityActionSuccess
  | GetActivityActionFailure
  | GetMenuActionSuccess
  | GetMenuActionFailure
export type AppDispatch = typeof store.dispatch
