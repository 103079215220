import React, { FC, Ref } from 'react'

import { Label, Input, Text, Checkbox, TextArea, Error, InputBlock } from './style'

type InputFieldProps = {
  disabled?: boolean
  hide?: boolean
  style?: React.CSSProperties
  name: string
  defaultValue?: string | number
  label: string
  value?: string | boolean
  type?: string
  error?: string
  registerRef?: Ref<HTMLInputElement | HTMLTextAreaElement>
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  children?: React.ReactNode
}

const InputField: FC<InputFieldProps> = (props: InputFieldProps) => {
  const { name, value, onChange, onBlur, type, label, children, error, style, defaultValue, disabled, hide } = props

  const renderInput = (type?: string) => {
    switch (type) {
      case 'checkbox':
        return (
          <Checkbox
            hideComponent={hide}
            disabled={disabled}
            ref={props?.registerRef as Ref<HTMLInputElement>}
            type="checkbox"
            defaultValue={defaultValue}
            name={name}
            checked={value as boolean}
            onChange={onChange}
          />
        )
      case 'textarea':
        return (
          <TextArea
            disabled={disabled}
            ref={props?.registerRef as Ref<HTMLTextAreaElement>}
            name={name}
            defaultValue={defaultValue}
            value={value as string}
            onChange={onChange}
          />
        )
      default:
        return (
          <Input
            disabled={disabled}
            ref={props?.registerRef as Ref<HTMLInputElement>}
            type={type}
            name={name}
            defaultValue={defaultValue}
            value={value as string}
            onChange={onChange}
            onBlur={onBlur}
          />
        )
    }
  }

  return (
    <Label hideComponent={hide} style={style}>
      <Text hideComponent={hide}>{label}</Text>
      <InputBlock>
        {renderInput(type)}
        {error && <Error>{error}</Error>}
      </InputBlock>
      {children}
    </Label>
  )
}

export default InputField
