import styled from '@emotion/styled'
import themeStyled from '../../theme'

import { BaseTitle, BaseText } from '../../base-style'

export const Section = styled.section``
export const Address = styled(BaseText.withComponent('address'))`
  overflow-wrap: break-word;
  display: block;
  font-style: italic;
  margin: 0;
  width: 186px;
  border-bottom: ${({ theme }) => theme.contact.titleDecoration};
`

export const Title = styled(BaseTitle)`
  :first-of-type {
    margin-top: 0px;
  }
  margin-bottom: 0.4em;
  margin-top: 0.8em;
`

export const Link = themeStyled(BaseText.withComponent('a'))`
  color: inherit;
  overflow-wrap: break-word;
  text-decoration: none;
    width: 186px;
      border-bottom: ${({ theme }) => theme.contact.titleDecoration};
  &:hover {
    color: ${(props) => props.theme.colors.link};
    border-bottom-color: transparent;
  }
  margin: 0;
`
