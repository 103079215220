import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContentSection,
  ContentSectionHeading,
  ContentSectionWrapper,
  Image,
} from 'shared/SectionControl/NewSectionModal/components/ModalContent/pages/style'

import FeedbackSectionImage from '../../assets/FeedbackSection/feedback-section.png'

export const FeedbackPage = () => {
  const { t } = useTranslation()

  return (
    <ContentSectionWrapper>
      <ContentSectionHeading>{t('Feedback section')}</ContentSectionHeading>
      <ContentSection>
        <Image alt="" src={FeedbackSectionImage} />
      </ContentSection>
    </ContentSectionWrapper>
  )
}
