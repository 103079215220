import {
  ADD_PAGES,
  SORT_PAGES,
  CREATE_PAGE,
  EDIT_PAGE,
  SET_CURRENT_PAGE,
  EDIT_PAGE_SECTION,
  ADD_NEW_SECTION,
  DELETE_SECTION,
  ADD_INLINE_SECTION,
  ADD_FIXED_BUTTON_SECTION,
  PageSectionTypes,
  IPageAddAction,
  IPageCreateAction,
  IPageEditAction,
  IPageItem,
  ISetCurrentPageAction,
  IAddNewSectionAction,
  IDeleteSectionAction,
  IEditPageSectionAction,
  IAddInlineSectionAction,
  IPageSortAction,
  IAddFixedButtonSectionAction,
} from './types'

import { SectionNames } from '@optios-frontend-website/shared-ui/src/constants'

export const addPages = (pages: IPageItem[]): IPageAddAction => {
  return {
    type: ADD_PAGES,
    payload: pages,
  }
}

export const sortPages = (pages: IPageItem[]): IPageSortAction => {
  return {
    type: SORT_PAGES,
    payload: pages,
  }
}

export const createPage = (page: IPageItem): IPageCreateAction => {
  return {
    type: CREATE_PAGE,
    payload: page,
  }
}

export const editPage = (page: IPageItem): IPageEditAction => {
  return {
    type: EDIT_PAGE,
    payload: page,
  }
}

export const setCurrentPage = (page: IPageItem | null): ISetCurrentPageAction => {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  }
}

export const updatePageSection = (
  name: string,
  section: PageSectionTypes,
  position: number,
  col?: number,
): IEditPageSectionAction => {
  return {
    type: EDIT_PAGE_SECTION,
    payload: { name, section, position, col },
  }
}


export const addNewSection = (
  position: number,
  name: SectionNames,
  data: PageSectionTypes | undefined,
): IAddNewSectionAction => {
  return {
    type: ADD_NEW_SECTION,
    payload: { position: position, name: name, data },
  }
}

export const deleteSection = (position: number, col?: number): IDeleteSectionAction => {
  return {
    type: DELETE_SECTION,
    payload: { position, col },
  }
}

export const addInlineSection = (position: number, name: SectionNames): IAddInlineSectionAction => {
  return {
    type: ADD_INLINE_SECTION,
    payload: { position: position, name: name },
  }
}

export const addFixedButtonSection = (position: number, name: SectionNames): IAddFixedButtonSectionAction => {
  return {
    type: ADD_FIXED_BUTTON_SECTION,
    payload: { position: position, name: name },
  }
}
