import styled from '@emotion/styled'

export const SlickSlide = styled.div`
  flex-shrink: 0;
  display: block;
  position: relative;
  overflow: hidden;
`

export const DeleteImageIcon = styled.i`
  position: absolute;
  bottom: calc(50% - 20px);
  right: 33%;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  color: #fff;
  background: rgba(192, 57, 43, 0.7);
  border-radius: 0.25em;
  text-shadow: -1px -1px 0px rgb(0 0 0 / 40%);
  line-height: 40px;
  width: 33%;
  text-align: center;
  z-index: 5;
  &:hover {
    background: rgba(192, 57, 43, 1);
  }
`
