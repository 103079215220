import React, { FunctionComponent, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ColorSwatch,
  ColorSwatchContainer,
  ColorSwatchWrapper,
  ToolbarContainer,
  ToolbarInput,
  ToolbarInputContainer,
  ToolbarInputLabel,
  ToolbarSlider,
  ToolbarText,
  ToolbarWrapper,
  UploadImageButton,
} from 'shared/SectionBackground/style'
import { colorSwatches } from 'shared/SectionBackground/helpers/colorSwatches'
import { IProps } from 'shared/SectionBackground/IProps'
import { addFile } from 'services/website-service'
import { SectionNames, WEBSITE_BACKGROUND_IMAGE } from '../../../constants'

import { IInline } from '../../../../../shared-ui/src/types/sections'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faCloudUpload } from '@fortawesome/free-solid-svg-icons'
import { errorToast, successToast } from 'services/toasts'

export const SectionBackground: FunctionComponent<IProps> = ({
  fontColor,
  setFontColor,
  sliderValue,
  setSliderValue,
  setImageUrl,
  updateSection,
  position,
  data,
  isToolbarVisible,
  setIsToolbarVisible,
  prefixUrl
}) => {
  const { t } = useTranslation()
  const [colorValue, setColorValue] = useState<string>('1')
  const [isImageVisible, setIsImageVisible] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  useEffect(() => {
    window.addEventListener('resize', () => setWindowWidth(window.innerWidth))
    return () => {
      window.removeEventListener('resize', () => setWindowWidth(window.innerWidth))
    }
  }, [])



  useEffect(() => {
    setImageUrl(`${prefixUrl}/?id=${data.backgroundImage}`)
    setFontColor(typeof data.backgroundFontColor === 'undefined' ? '#000000' : data.backgroundFontColor)
    setSliderValue(typeof data.backgroundContrast === 'undefined' ? 0 : data.backgroundContrast)
  }, [data, setFontColor, setSliderValue, setImageUrl, prefixUrl])

  const handleImageChange = (event: any) => {
    const files = event.target.files
    if (files && files.length > 0) {
      setSelectedFile(files[0])
      setIsImageVisible(true)
      setIsToolbarVisible(true)
    }
  }

  const uploadImage = () => {
    if (selectedFile) {
      addFile(WEBSITE_BACKGROUND_IMAGE, selectedFile).then((response) => {
        if (response.status >= 299) {
          return errorToast()
        }

        const newData: IInline = {
          ...data,
          backgroundImage: response.data.data.id,
          backgroundFontColor: fontColor,
          backgroundContrast: sliderValue,
        }
        updateSection(SectionNames.INLINE, newData, position)
        setImageUrl(`${prefixUrl}/?id=${response.data.data.id}`)
        successToast()
      })
    }
  }

  const handleTransparencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSliderValue(parseInt(event.target.value))
  }

  const handleFontColor = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, hex: string) => {
    setFontColor(hex)
    setColorValue(event.currentTarget.id)
  }
  return (
    <ToolbarContainer>
      <ToolbarWrapper windowWidth={windowWidth}>
        {isImageVisible && (
          <>
            <ToolbarText>{t('Change contrast')} :</ToolbarText>
            <ToolbarSlider
              type="range"
              min={0}
              max={9}
              value={sliderValue}
              onChange={(event) => handleTransparencyChange(event)}
            />
            <ToolbarText>{t('Font color')} :</ToolbarText>
            <ColorSwatchWrapper windowWidth={windowWidth}>
              {colorSwatches.map((color) => (
                <>
                  <ColorSwatchContainer
                    id={color.id}
                    selected={colorValue === color.id}
                    onClick={(event) => handleFontColor(event, color.hex)}
                  >
                    <ColorSwatch key={color.hex} color={color.hex} />
                  </ColorSwatchContainer>
                </>
              ))}
            </ColorSwatchWrapper>
            <UploadImageButton windowWidth={windowWidth} onClick={uploadImage}>
              <FontAwesomeIcon icon={faCloudUpload} />
            </UploadImageButton>
          </>
        )}

        <ToolbarInputLabel>
          <ToolbarInputContainer windowWidth={windowWidth} isToolbarVisible={isToolbarVisible}>
            <FontAwesomeIcon icon={faImage} />
          </ToolbarInputContainer>
          <ToolbarInput type="file" onChange={(event) => handleImageChange(event)} />
        </ToolbarInputLabel>
      </ToolbarWrapper>
    </ToolbarContainer>
  )
}
