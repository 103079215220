import React, { FC, useState, Ref, useEffect } from 'react'

import { Label, Select, SelectMenu, List, Item, Input, SelectArrow, Selected, Error } from './style'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from 'store/index'

type Item = {
  label: string
  value: string
}
type SelectFieldProps = {
  name: string
  label: string
  defaultValue?: Item | undefined
  registerRef?: Ref<HTMLInputElement>
  error?: string
  handleSelect(name: string, value: string): void
  list: Item[]
}

const SelectField: FC<SelectFieldProps> = (props: SelectFieldProps) => {
  const locale = useSelector<RootState, string>((state) => state.config.locale)
  const [isActive, setActive] = useState(false)
  const [data, setValue] = useState<Item | undefined>()
  const { t } = useTranslation()
  const { name, handleSelect, list, error } = props
  const activeClass = isActive ? 'active' : ''

  useEffect(() => {
    if (props.defaultValue) {
      setValue(props.defaultValue)
    }
  }, [props.defaultValue])

  return (
    <Label>
      <Select className={activeClass}>
        <SelectMenu
          onClick={() => {
            setActive(!isActive)
          }}
        >
          <Selected>{data && data.label}</Selected>
          <Input type="hidden" name={name} ref={props.registerRef} />
          <SelectArrow className={activeClass} />
        </SelectMenu>
        <List className={activeClass}>
          {list.map((item, index) => (
            <Item
              key={index}
              onClick={() => {
                setValue(item)
                handleSelect(name, item.value)
                setActive(false)
              }}
            >
              {t(item.label, { lng: locale })}
            </Item>
          ))}
        </List>
        {error && <Error>{error}</Error>}
      </Select>
    </Label>
  )
}

export default SelectField
