import styled from '@optios-frontend-website/shared-ui/src/theme'

type Props = {
  bgColor: string | undefined
}

export const ColorsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const Color = styled.div<Props>`
  width: 1.6em;
  height: 1.6em;
  float: left;
  margin: 0.4em;
  cursor: pointer;
  border: 1px solid #babdbe;
  background-color: ${(props) => props.bgColor};
  &.active {
    width: 2.4em;
    height: 2.4em;
    margin: 0;
    box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.5);
  }
`
export const IconWrap = styled.div`
  display: inline-block;
  margin-right: 1.5em;
  margin-top: 0.5em;
  opacity: 0.3;
  cursor: pointer;
  &.active {
    opacity: 1;
  }
`
export const Icon = styled.i<Props>`
  cursor: pointer;
  background-color: #2196f3;
  text-align: center;
  background-color: ${(props) => props.bgColor || '#0090ff'};
  color: #fff;
  border-radius: 100%;
  display: inline-block;
  width: 3em;
  height: 3em;
  line-height: 3em;
  box-shadow: 0 0 0 7px white, 0 0 0 8px ${(props) => props.theme.colors.border};
  margin: 0 0 2em 0;
  &::before {
    font-size: 16px;
  }
`
export const AddNewButton = styled.button`
  color: #fff;
  margin: auto;
  font: inherit;
  background-color: ${(props) => props.theme.colors.primary};
  border-color: #0090ff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
  outline: none;
  &:hover {
    background: #5899f3;
    box-shadow: 0px 6px 15px #5899f3;
  }
`
export const Title = styled.div`
  color: rgba(0, 0, 0, 0.5);
  flex: 0 0 30%;
`

export const Error = styled.div`
  color: #d24444;
`
