import React, { FunctionComponent } from 'react'
import { Col } from 'react-grid-system'
import { IProps } from './IProps'
import InputFiled from 'shared/InputField'

export const ActivityField: FunctionComponent<IProps> = ({ data, handleChange, itemIndex, category }) => {
  return (
    <>
      <Col md={5} sm={10}>
        <InputFiled
          label="Service"
          disabled
          name="category"
          value={data.category}
          onChange={(e) => handleChange(e, itemIndex, 'category', category)}
        />
      </Col>
      <Col md={5} sm={10}>
        <InputFiled label="Price" disabled name="price" value={data.price} onChange={(e) => handleChange(e, itemIndex, 'price', category)} />
      </Col>
    </>
  )
}
