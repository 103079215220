import React, { useState } from 'react'
import {
  ItemIcon,
  ListItem,
  NavigationContainer,
  SubText,
  Title,
} from 'shared/SectionControl/NewSectionModal/components/ModalNavigation/style'
import { useTranslation } from 'react-i18next'
import { IProps } from 'shared/SectionControl/NewSectionModal/components/ModalNavigation/IProps'
import { SectionNames } from '@optios-frontend-website/shared-ui/src/constants'

export const ModalNavigation = ({ selectedValue, setSelectedValue, setItem, activeList }: IProps) => {
  const { t } = useTranslation()
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)


  const handleItemClick = (value: string, label: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setSelectedValue(value)
    event.stopPropagation()
    setItem({ label: 'section', value: value as SectionNames })
  }

  const handleItemHover = (index: number) => {
    setHoveredIndex(index)
  }

  const handleItemLeave = () => {
    setHoveredIndex(null)
  }

  return (
    <NavigationContainer>
      <Title>{t('Library Section')}</Title>
      <SubText>{t('Choose a section type and select a specific layout')}</SubText>
      {activeList.map((item, index) => (
        <ListItem
          key={item.value}
          selected={item.value === selectedValue}
          onClick={(event) => handleItemClick(item.value, item.label, event)}
          onMouseEnter={() => handleItemHover(index)}
          onMouseLeave={handleItemLeave}
          hover={index === hoveredIndex}
        >
          <ItemIcon icon={item.icon} selected={item.value === selectedValue} hover={index === hoveredIndex} />
          {item.label}
        </ListItem>
      ))}
    </NavigationContainer>
  )
}
