import styled from '@optios-frontend-website/shared-ui/src/theme'
import { css } from '@emotion/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Container = styled.div<{ isOdd?: number[]; themeTitle: string }>`
  ${({ themeTitle, isOdd, theme }) =>
    themeTitle === 'Classic'
      ? css`
          background: #ffffff;
        `
      : css`
          &:nth-of-type(even) {
            background: ${isOdd ? theme.colors.backgroundFirst : 'initial'};
          }

          &:nth-of-type(odd) {
            background: ${isOdd ? theme.colors.backgroundSecond : 'initial'};
          }
        `}

  .parent-container:nth-of-type(1n) {
    background: transparent;
    display: -ms-flexbox;
    justify-content: center;
  }

  &:hover .show-image-button {
    display: inline-block;
  }
  &:hover .show-delete-button {
    display: inline-block;
  }

  &:hover .show-add-section-button {
    display: inline-block;
  }
  &.wrapper-background {
    width: 100%;
  }

  .parent-container:nth-of-type(1n):has(.employee-card-wrapper) {
    padding: 0 !important;
  }
`
export const EditSectionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 6px 16px;
`

export const EditSectionWrapper = styled.div`
  background-color: rgba(39, 46, 59, 1);
  border-radius: 4px 0 0 4px;
  max-width: 200px;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: rgba(39, 46, 59, 0.85);
  }
`

export const EditActionWrapper = styled.div`
  background-color: rgba(39, 46, 59, 1);
  border-radius: 0 4px 4px 0;
  max-width: 200px;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: rgba(39, 46, 59, 0.85);
  }
`

export const ActionEditorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 16px;
`

export const ActionEditor = styled.div`
  position: absolute;
  display: flex;
  z-index: 10;
`

export const ActionButton = styled.button`
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: none;
  background: rgba(39, 46, 59, 1);
  color: #ffffff;
  margin-left: 4px;
  &:hover {
    cursor: pointer;
    background-color: rgba(39, 46, 59, 0.85);
  }
`

export const PenIcon = styled(FontAwesomeIcon)`
  color: white;
  margin-left: 5px;
  padding: 6px;
`

export const Text = styled.span`
  color: white;
  margin-left: 5px;
  padding: 6px;
  font-weight: 700;
`

export const Separator = styled.span`
  width: 1px;
  height: 100%;
  background-color: white;
  margin-left: 5px;
`

export const DotsIcon = styled(FontAwesomeIcon)`
  color: white;
  padding: 16px;
`


export const HorizontalLine = styled.div<{ hover: boolean }>`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background: ${({ hover }) => (hover ? 'rgba(39, 46, 59, 0.2)' : 'rgba(39, 46, 59, 1)')};
  transition: background 0.3s;
`
export const AddNew = styled.span<{ hover: boolean }>`
  color: #ffffff;
  font-weight: 700;
  background: ${({ hover }) => (hover ? 'rgba(39, 46, 59, 0.95)' : 'rgba(39, 46, 59, 1)')};
  transition: background 0.3s;
  cursor: ${({ hover }) => (hover ? 'pointer' : 'auto')};
  border-radius: 4px;
  padding: 0.5rem 1rem;
  position: relative;
  z-index: 2;
  min-width: 200px;
  justify-content: center;
  text-align: center;
  margin: 8px auto;
`


export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0 auto;
  &.sm,
  &.xs {
    width: auto;
  }
`





export const Button = styled.button`
  font: inherit;
  background: ${({ theme }) => theme.colors.info};
  color: ${({ theme }) => theme.colors.wText};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  border-radius: 18px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0 1.5em;
  line-height: 2em;
  min-width: 9em;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.025em;
  outline: none;
  margin: 0;
  margin-top: 10px;
  float: right;
  :hover {
    background: ${({ theme }) => theme.colors.infoHover};
  }
  &.xs {
    width: 100%;
  }
`

export const NewSectionWrapper = styled.div`
  position: relative;
  display: flex;
  `
