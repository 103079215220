import React, { FunctionComponent, useState, useMemo, useEffect } from 'react'
import { Container } from 'react-grid-system'

import { RenderPricingList } from '@optios-frontend-website/shared-ui/src/components/RenderPricingList/RenderPricingList'
import Modal from 'shared/Modal'
import SectionControl from 'shared/SectionControl'
import { IActivityCategories } from 'store/page/types'
import Button from 'shared/ButtonModal'
import { useTranslation } from 'react-i18next'
import { IProps } from './IProps'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/index'

import { getActivities, getMenuCategories } from 'store/config/actions'
import Spinner from 'shared/Spinner'
import { PricingList } from 'components/PricingCrud/components/PricingList/PricingList'
export const PricingCrud: FunctionComponent<IProps> = ({
  activityCategories,
  isOnlineBooking,
  phoneNumber,
  position,
  updateSection,
}) => {
  const activityCheck = Object.entries(activityCategories).length !== 0
  const [modalIsOpen, setIsOpen] = useState(false)
  const [services, setServices] = useState<IActivityCategories | Record<string, never>>(
    activityCheck ? activityCategories : {},
  )

  const [renderPricing, setRenderPricing] = useState({})

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const menuData = useSelector((state: RootState) => state.config.data.menu)
  const activityData = useSelector((state: RootState) => state.config.data.activities)
  const isActivityLoading = useSelector((state: RootState) => state.config.isLoading)
  const establishmentId = useSelector((state: RootState) => state.config.data.booking?.id)

  useEffect(() => {
    if (activityData && menuData) {
      const flattenActivity = activityData.flatMap((activity) => activity.activities)
      const filteredActivity = flattenActivity.filter((val) => val.showOnProWebsite)

      let foundObjects: any[] = []

      const findActivities = (categories: any[]) => {
        for (const category of categories) {
          if (category.activities) {
            foundObjects = [...foundObjects, category]
          }
          if (category.categories) {
            findActivities(category.categories)
          }
        }
      }

      for (const topLevelCategory of menuData) {
        if (topLevelCategory.categories) {
          findActivities(topLevelCategory.categories)
        }
      }

      const proWebsiteObjects = foundObjects.filter((obj) => obj.showOnProWebsite)

      const pricing: any = {}

      for (const activity of filteredActivity) {
        pricing[activity.name] = {
          name: activity.name,
          data: [
            {
              category: activity.name,
              description: activity.description,
              price: typeof activity.price === 'undefined' ? '0' : activity.price,
              duration: activity.duration,
              path: `/${activity.id}`,
              uuid: activity.uuid,
              isMenu: false,
            },
          ],
        }
      }

      for (const obj of proWebsiteObjects) {
        for (const activity of obj.activities) {
          if (!pricing[obj.name]) {
            pricing[obj.name] = {
              name: obj.name,
              data: [],
            }
          }
          pricing[obj.name].data.push({
            category: activity.name,
            description: obj.description,
            price: typeof activity.price === 'undefined' ? '0' : activity.price,
            duration: activity.duration,
            path: `/${obj.id}/${activity.id}`,
            uuid: activity.uuid,
            isMenu: obj.isMenu || false,
          })
        }
      }

      setRenderPricing(pricing)
    }
  }, [activityData, menuData])

  useMemo(() => {
    if (establishmentId && !isActivityLoading && !activityData) {
      dispatch(getActivities(establishmentId, dispatch))
    }

    if (establishmentId && !isActivityLoading && !menuData) {
      dispatch(getMenuCategories(establishmentId, dispatch))
    }
  }, [establishmentId, activityData, menuData, isActivityLoading, dispatch])

  useEffect(() => {
    if (activityCheck) {
      setServices(activityCategories)
    }
  }, [activityCategories, activityCheck])

  const onRequestClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setServices({})
    setIsOpen(false)
  }

  const onSave = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    updateSection('pricing', services, position)
    setIsOpen(false)
  }

  const open = () => {
    setServices(activityCategories)
    setIsOpen(true)
  }

  if (menuData === null || activityData === null) {
    return <Spinner isLoaded={false} />
  }

  if (!activityCategories || !renderPricing) {
    return <Spinner isLoaded={false} />
  }

  return (
    <>
      <SectionControl position={position}>
        <RenderPricingList
          isOnlineBooking={isOnlineBooking}
          phoneNumber={phoneNumber}
          pricing={renderPricing}
          handleClick={open}
        />
      </SectionControl>
      <Modal
        isOpen={modalIsOpen}
        onSubmit={onSave}
        onRequestClose={onRequestClose}
        title="Pricing part"
        minWidth={'50em'}
      >
        <Container>
          <PricingList
            services={renderPricing}
            setServices={setRenderPricing}
            activityList={activityData}
            menuList={menuData}
          />
          <div style={{ textAlign: 'right' }}>
            <Button handleClick={onRequestClose} className="dialog-close">
              {t('page:cancel')}
            </Button>
            <Button type="submit" handleClick={onSave}>
              {t('page:save')}
            </Button>
          </div>
        </Container>
      </Modal>
    </>
  )
}
