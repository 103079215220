import styled from '@emotion/styled'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const SelectionContainer = styled.div`
  overflow: auto;
  margin: 50px 0;
  order: 999;
`

export const ActivityContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  margin: 50px 0;
`

export const SelectionName = styled.p`
  margin: 25px 15px 25px 0;
}
`

export const RemoveIconContainer = styled.div`
  background-color: #dee1e7;
  border-radius: 29px;
  padding: 8px 12px;
  cursor: pointer;
`

export const RemoveIcon = styled(FontAwesomeIcon)`
  color: #d24444;
`
