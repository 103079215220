import React, { FunctionComponent, useEffect, useState } from 'react'
import { IProps } from './IProps'

import { Col, Row } from 'react-grid-system'
import { IActivityCategories } from 'store/page/types'
import { ActionButtons } from 'components/PricingCrud/components/PricingList/components/ActionButtons/ActionButtons'
import { ActivitySelect } from 'components/PricingCrud/components/ActivitySelect/ActivitySelect'
import {
  IActivitySelect,
  ISelectionDetails,
} from 'components/PricingCrud/components/ActivitySelect/interface/IActivitySelect'
import { ActivityField } from 'components/PricingCrud/components/PricingList/components/ActivityField/ActivityField'
import {
  addOrRemoveActivity,
  addOrRemoveCategory,
} from 'services/establishment-activity-call/establishment-activity-call'

const emptyActivityDetail = {
  category: '',
  price: '',
  id: '',
  path: null,
}

export const PricingList: FunctionComponent<IProps> = ({ services, setServices, activityList, menuList }) => {
  const [activitySelectObject, setActivitySelectObject] = useState<IActivitySelect>({
    type: 'idle',
    selectionDetails: null,
    activeSelection: null,
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index: number,
    field: 'category' | 'price',
    category: string,
  ) => {
    const isEdit = !!services[category].data[index]

    console.log('from handle changez', category, services)

    const newData = services[category].data.map((v, i) => {
      if (i === index) {
        return { ...v, [field]: e.currentTarget.value }
      }
      return v
    })

    if (!isEdit && index === newData.length - 1 && newData[newData.length - 1].category !== '') {
      newData.push({ ...emptyActivityDetail })
    }

    const updateServiceToObject = {
      ...services,
      [category]: {
        name: category,
        data: newData,
      },
    }
    setServices(updateServiceToObject)
  }

  const deleteService = (index: number, category: string) => {
    const matchingObject = Object.values(services).find((service) => service.name === category)
    if (matchingObject) {
      if (matchingObject.data[0].isMenu) {
        addOrRemoveCategory(matchingObject.data[0].uuid, false)
          .then((response) => {
            console.log('Category removed successfully:', response)
          })
          .catch((error) => {
            console.error('Error removing category:', error)
          })
      } else {
        addOrRemoveActivity(matchingObject.data[0].uuid, false)
          .then((response) => {
            console.log('Activity removed successfully:', response)
          })
          .catch((error) => {
            console.error('Error removing activity:', error)
          })
      }
    }
    const newData = [...services[category].data]
    newData.splice(index, 1)

    const deleteServiceFromObject = {
      ...services,
      [category]: { name: category, data: newData },
    }

    if (deleteServiceFromObject[category].data.length === 0) {
      delete deleteServiceFromObject[category]
    }

    setServices(deleteServiceFromObject)
  }

  useEffect(() => {
    if (activitySelectObject.type === 'idle' || !activitySelectObject.selectionDetails) {
      return
    }

    const selectionDetails: IActivityCategories = activitySelectObject.selectionDetails
      .map(
        (activity): ISelectionDetails => ({
          price: activity.selectionDetails.price,
          name: activity.selectionDetails.name,
          id: activity.selectionDetails.id,
          path: activity.selectionDetails.path,
          category: activity.selectionDetails.category,
        }),
      )
      .reduce((accumulator, currentValue) => {
        const categoryCheck = services[currentValue.category]
        const activityDetails = {
          category: currentValue.name,
          price: currentValue.price,
          id: currentValue.id,
          path: currentValue.path,
        }

        if (!categoryCheck) {
          return {
            ...accumulator,
            [currentValue.category]: { name: currentValue.category, data: [activityDetails] },
          }
        }

        return {
          ...accumulator,
          [categoryCheck.name]: { name: categoryCheck.name, data: [...categoryCheck.data, activityDetails] },
        }
      }, {} as IActivityCategories)

    setServices({ ...services, ...selectionDetails })
    setActivitySelectObject({
      type: 'idle',
      selectionDetails: null,
      activeSelection: null,
    })
  }, [activitySelectObject, services, setServices])

  if (!services) {
    return (
      <ActivitySelect
        activitySelectObject={activitySelectObject}
        setActivitySelectObject={setActivitySelectObject}
        activityList={activityList}
        menuList={menuList}
        services={services}
      />
    )
  }

  return (
    <>
      {Object.entries(services).map((category) => {
        const activityDetails = category[1].data
        const categoryName = category[0]

        return (
          <div key={categoryName}>
            <p>{categoryName}</p>
            {activityDetails.map((details, index) => (
              <form key={details.id}>
                <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                  <ActivityField handleChange={handleChange} data={details} itemIndex={index} category={categoryName} />
                  <Col md={2} sm={2}>
                    <ActionButtons deleteService={deleteService} serviceIndex={index} category={categoryName} />
                  </Col>
                </Row>
              </form>
            ))}
          </div>
        )
      })}
      <ActivitySelect
        activitySelectObject={activitySelectObject}
        setActivitySelectObject={setActivitySelectObject}
        activityList={activityList}
        menuList={menuList}
        services={services}
      />
    </>
  )
}
