import React, { FC } from 'react'
import { useScreenClass } from 'react-grid-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ITextAndImage } from '../../../types/sections'
import { Icon, Box, ItemBLock, Title, Text } from './style'
import { IconName } from '@fortawesome/fontawesome-svg-core'

type Props = {
  item: ITextAndImage
  onClick?(id?: number): void
  className: string
  index: number
}

const Item: FC<Props> = (props: Props) => {
  const screenClass = useScreenClass()
  const cx = `
    ${props.className}
    ${screenClass}
  `
  return (
    <ItemBLock className={cx} onClick={() => props.onClick && props.onClick(props.item.id)}>
      <Box className={cx}>
        <Icon bgColor={props.item.color}>
          <FontAwesomeIcon icon={props.item.icon as IconName} />
        </Icon>
        <Title>{props.item.title}</Title>
        <Text>{props.item.text}</Text>
      </Box>
    </ItemBLock>
  )
}

export default Item
