import React from 'react'
import { useTranslation } from 'react-i18next'
import { IHTML } from '../../types/sections'
import { Title, Text, Container, HTMLData } from './style'

type Props = {
  data: IHTML
  handleClick?: () => void
  id?: () => void
  fontColor: string
}

const CustomHTML = (props: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Container onClick={props.handleClick}>
      {!props.data.content && (
        <div>
          <Title>{t('Title')}</Title>
          <p>{t('Add content ...')}</p>
        </div>
      )}
      {props.data.content && (
        <div className="custom-html-main-wrapper">
          <HTMLData fontColor={props.fontColor} dangerouslySetInnerHTML={{ __html: props.data.content }}></HTMLData>
        </div>
      )}
    </Container>
  )
}

export default CustomHTML
