import styled from '@emotion/styled'

export const ToolbarContainer = styled.div`
  display: block;
`

export const ToolbarInputLabel = styled.label`
  display: inline-block;
`
export const ToolbarInputContainer = styled.div<{
  isToolbarVisible: boolean
  windowWidth: number
}>`
  position: absolute;
  right: 0;
  margin: ${({ isToolbarVisible, windowWidth }) =>
    isToolbarVisible && windowWidth > 990
      ? '-20px 30px 0 0'
      : windowWidth < 990 && isToolbarVisible
      ? '-40px 30px 20px 0'
      : '20px 32px 0 0'};
  display: block;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  text-shadow: -1px -1px 0pxrgba (0, 0, 0, 0.4);
  line-height: 40px;
  padding: 0px;
  text-align: center;
  z-index: 5;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: none;
  background: rgba(39, 46, 59, 1);
  color: #ffffff;
  &:hover {
    cursor: pointer;
    background-color: rgba(39, 46, 59, 0.85);
  }
`
export const ToolbarInput = styled.input`
  display: none;
`

export const ToolbarSlider = styled.input``

export const ToolbarText = styled.p`
  font-family: proxima-nova, sans-serif;
  color: #000000;
  line-height: 1.75em;
  font-size: 1rem;
  padding: 6px 0;
`

export const ColorSwatchWrapper = styled.div<{
  windowWidth: number
}>`
  display: flex;
  margin: ${({ windowWidth }) => (windowWidth < 990 ? '20px 0' : '0')};
`

export const ColorSwatchContainer = styled.div<{
  selected: boolean
}>`
  background-color: #ffffff;
  border: ${({ selected }) => (selected ? '2px solid lightblue' : '1px solid lightgrey')};
  border-radius: 4px;
  margin: 0 4px;
  &:hover {
    cursor: pointer;
    background: #e8e8e8;
  }
`

export const ColorSwatch = styled.div<{
  color: string
}>`
  width: 25px;
  height: 25px;
  background-color: ${({ color }) => color};
  border-radius: 4px;
  margin: 8px;
  border: 1px solid lightgrey;
`

export const ToolbarWrapper = styled.div<{
  windowWidth: number
}>`
  display: flex;
  margin: 20px 0;
  justify-content: ${({ windowWidth }) => (windowWidth < 990 ? 'center' : 'space-between')};
  align-items: center;
  width: 100%;
  flex-direction: ${({ windowWidth }) => (windowWidth < 990 ? 'column' : 'row')};
`

export const UploadImageButton = styled.div<{
  windowWidth: number
}>`
  right: 0;
  display: block;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  margin: ${({ windowWidth }) => (windowWidth < 990 ? '0 0 0 11em' : '0 34px 0 0')};
  background: rgba(39, 46, 59, 1);
  color: #ffffff;
  border-radius: 0.25em;
  text-shadow: -1px -1px 0pxrgba (0, 0, 0, 0.4);
  line-height: 40px;
  height: 40px;
  width: 40px;
  padding: 0px;
  text-align: center;
  z-index: 5;

  &:hover {
    cursor: pointer;
    background-color: rgba(39, 46, 59, 0.85);
  }
`
