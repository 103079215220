import { css } from '@emotion/core'

// import fontAwesome from './fontawesome'

export const globalStyles = css`
  .ql-align-right {
    text-align: right;
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-justify {
    text-align: justify;
  }
`
