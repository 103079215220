import React, { useState, FC } from 'react'

import { SectionNames } from '@optios-frontend-website/shared-ui/src/constants'
import SharedGalery from '@optios-frontend-website/shared-ui/src/components/Gallery'
import Dropzone from 'shared/Dropzone'
import SectionControl from 'shared/SectionControl'
import { addFile } from 'services/website-service'
import { errorToast } from 'services/toasts'

import { WEBSITE_GALLERY } from '../../constants'

import { IProps } from './IProps'
import { SlickSlide } from './style'
import { CustomSlide } from './components/CustomSlide'
import { IGallery } from '../../../../shared-ui/src/types/sections'

type FileData = {
  name: string | null
  data: string | ArrayBuffer | null
}

const Gallery: FC<IProps> = (props: IProps) => {
  const [fileData, setFile] = useState<FileData>({ data: null, name: null })

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const data = reader.result
        setFile({ data, name: file.name })
        addFile(WEBSITE_GALLERY, file).then(
          (response) => {
            if (response.status >= 299) {
              return
            }
            const newImageArray: IGallery[] = [{ id: response.data.data.id }, ...props.data]
            props.updateSection(SectionNames.GALLERY, newImageArray, props.position)
          },
          () => {
            errorToast()
          },
        )
      }
      reader.readAsArrayBuffer(file)
    })
  }



  const onDelete = (imageId: number) => {
    const newImageArray: IGallery[] = props.data.filter((image) => image.id !== imageId)
    props.updateSection(SectionNames.GALLERY, newImageArray, props.position)
  }
  return (
    <SectionControl position={props.position}>
      <SharedGalery>
        <SlickSlide>
          <Dropzone onDrop={onDrop} fileName={fileData.name} />
        </SlickSlide>
        {(Array.isArray(props.data) ? props.data : []).map((item) => (
          <CustomSlide
            key={item.id}
            src={`${props.config.url}/?id=${item.id}&w=360&h=200&fit=crop`}
            deleteImage={() => onDelete(item.id)}
          />
        ))}
      </SharedGalery>
    </SectionControl>
  )
}

export default Gallery
