import React, { FunctionComponent } from 'react'
import { NavA, NavIcon } from '../style'

type MenuItemProps = {
  icon: string
  title: string
  path: string
  classNames: string[]
}
const MenuItem: FunctionComponent<MenuItemProps> = (props: MenuItemProps) => {
  return (
    <NavA href={props.path} className={props.classNames.join(' ')} title={props.title}>
      <NavIcon className={'icon icon-on icon-' + props.icon} />
      <NavIcon className={'icon icon-off icon-' + props.icon + '-neg'} />
    </NavA>
  )
}

export default MenuItem
