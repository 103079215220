import React, { FC, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SharedTextImage from '@optios-frontend-website/shared-ui/src/components/TextImage'
import {
  Icon as SharedIcon,
  Box,
  ItemBLock,
} from '@optios-frontend-website/shared-ui/src/components/TextImage/Item/style'
import { Props, FormValues } from './types'
import Modal from 'shared/Modal'
import InputField from 'shared/InputField'
import { ColorsGrid, Color, IconWrap, Icon, Title, Error } from './style'
import { ITextAndImage } from 'store/page/types'
import SectionControl from 'shared/SectionControl'
import Button from 'shared/ButtonModal'
import { useSelector } from 'react-redux'
import { RootState } from 'store/index'
import { useScreenClass } from 'react-grid-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TextImage: FC<Props> = (props: Props) => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalDeleteIsOpen, setDeleteIsOpen] = useState(false)
  const { register, handleSubmit, errors, setValue, watch, getValues } = useForm<ITextAndImage>()
  const [items, setItems] = useState<ITextAndImage[]>([])
  const [defaultForm, setDefaultForm] = useState<ITextAndImage>()
  const locale = useSelector<RootState, string>((state) => state.config.locale)
  const { t } = useTranslation()
  const screenClass = useScreenClass()

  useEffect(() => {
    setItems(props.data.map((item, index) => ({ ...item, id: index })))
  }, [props.data])

  watch()
  const onRequestClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setIsOpen(false)
  }

  const onSave = (data: FormValues) => {
    const id = parseInt((getValues('id') || '')?.toString())
    const itemIndex = items.findIndex((item) => item.id === id)
    if (itemIndex !== -1) {
      items[itemIndex] = data
    } else {
      items.push({ ...data, id: items.length })
    }

    setItems([...items])

    props.updateSection(
      'textandimages',
      items.map((item) => {
        delete item.id
        return item
      }),
      props.position,
    )
    setIsOpen(false)
  }

  const onDelete = () => {
    const dataId = parseInt((getValues('id') || '')?.toString())
    const itemIndex = items.findIndex((item) => item.id === dataId)
    if (itemIndex !== -1) {
      items.splice(itemIndex, 1)
    }
    setItems([...items])
    props.updateSection('textImage', [...items], props.position)
  }

  const open = (id?: number) => {
    const dataForm = items && items.find((item) => item.id === id)
    setDefaultForm(dataForm)
    if (dataForm) {
      Object.keys(dataForm).forEach((key) => {
        setValue(key as 'title' | 'text' | 'id' | 'color' | 'icon', dataForm[key as keyof ITextAndImage])
      })
      setIsOpen(true)
    } else {
      setIsOpen(true)
    }
  }

  const handleColor = (value: string) => {
    setValue('color', value)
  }

  const handleIcon = (value: string) => {
    setValue('icon', value)
  }

  return (
    <>
      <SectionControl position={props.position}>
        <SharedTextImage
          config={props.config}
          updateSection={props.updateSection}
          position={props.position}
          data={items}
          onClick={open}
        >
          <ItemBLock className={screenClass}>
            <Box
              className={screenClass}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: '4px dashed lightgray',
                padding: '1em 1em 3em 1em',
                position: 'relative',
                top: '-1em',
              }}
            >
              <SharedIcon bgColor={'lightgray'}>
                <FontAwesomeIcon icon={'plus'} />
              </SharedIcon>
              <Button handleClick={() => open()} type={'button'} className={'small'}>
                <FontAwesomeIcon icon={'plus'} aria-hidden="true" style={{ marginRight: '.5em' }} />
                {t('page:add_text_image', { lng: locale })}
              </Button>
            </Box>
          </ItemBLock>
        </SharedTextImage>
      </SectionControl>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={onRequestClose}
        title={t('textandimage:title_modal', { lng: locale })}
      >
        <form
          onSubmit={handleSubmit((data) => {
            onSave(data)
          })}
        >
          <input type="hidden" name="id" defaultValue={defaultForm && defaultForm.id} ref={register} />
          <InputField
            label={t('textandimage:title', { lng: locale })}
            name="title"
            defaultValue={defaultForm?.title}
            registerRef={register({ required: 'this is required' })}
            error={errors.title?.message}
          />
          <InputField
            label={t('textandimage:text', { lng: locale })}
            name="text"
            defaultValue={defaultForm?.text}
            registerRef={register({ required: 'this is required' })}
            error={errors.text?.message}
            type="textarea"
          />
          <InputField
            label={t('textandimage:color', { lng: locale })}
            name="color"
            defaultValue={defaultForm?.color}
            style={{ display: 'none' }}
            registerRef={register({ required: 'this is required' })}
          />
          <label style={{ display: 'flex', margin: '0 0 0.7em' }}>
            <Title>{t('textandimage:color', { lng: locale })}</Title>
            <div>
              <ColorsGrid>
                {props.configs &&
                  Object.values(props.configs.color || {}).map((data) => (
                    <Color
                      onClick={() => {
                        handleColor(data)
                      }}
                      className={data === (getValues('color') || (defaultForm && defaultForm.color)) ? 'active' : ''}
                      bgColor={data}
                      key={data}
                    />
                  ))}
              </ColorsGrid>
              <Error>{errors.color?.message}</Error>
            </div>
          </label>
          <InputField
            style={{ display: 'none' }}
            label="Icon"
            name="icon"
            defaultValue={defaultForm?.icon}
            registerRef={register({ required: 'this is required' })}
          />
          <label style={{ display: 'flex', margin: '0 0 0.7em' }}>
            <Title>{t('textandimage:icon', { lng: locale })}</Title>
            <div>
              <div>
                {props.configs &&
                  props.configs.icon.map((icon) => (
                    <IconWrap
                      key={icon}
                      className={icon === (getValues('icon') || (defaultForm && defaultForm.icon)) ? 'active' : ''}
                    >
                      <Icon
                        onClick={() => {
                          handleIcon(icon)
                        }}
                        bgColor={getValues('color') || defaultForm?.color}
                      >
                        <FontAwesomeIcon
                          icon={icon as never}
                          style={{ backgroundColor: getValues('color') || defaultForm?.color }}
                        />
                      </Icon>
                    </IconWrap>
                  ))}
              </div>
              <Error>{errors.icon?.message}</Error>
            </div>
          </label>
          <div style={{ textAlign: 'right' }}>
            {defaultForm && typeof defaultForm?.id === 'number' && defaultForm.id >= 0 ? (
              <Button
                className="dialog-delete left"
                handleClick={(e: React.MouseEvent) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setDeleteIsOpen(true)
                }}
              >
                {t('page:delete', { lng: locale })}
              </Button>
            ) : null}
            <Button handleClick={onRequestClose} className="dialog-close">
              {t('page:cancel', { lng: locale })}
            </Button>
            <Button type="submit">{t('page:save', { lng: locale })}</Button>
          </div>
          <Modal
            isOpen={modalDeleteIsOpen}
            onRequestClose={() => {
              setDeleteIsOpen(false)
            }}
            title="Delete"
          >
            {t('page:are_you_sure', { lng: locale })}
            <div style={{ textAlign: 'right' }}>
              <Button handleClick={() => setDeleteIsOpen(false)} className="dialog-close">
                {t('page:cancel', { lng: locale })}
              </Button>
              <Button
                className="dialog-delete"
                handleClick={() => {
                  onDelete()
                  setIsOpen(false)
                  setDeleteIsOpen(false)
                }}
              >
                {t('page:delete', { lng: locale })}
              </Button>
            </div>
          </Modal>
        </form>
      </Modal>
    </>
  )
}

export default TextImage
