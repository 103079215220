import styled from '@optios-frontend-website/shared-ui/src/theme'
import { ReactComponent as ArrowSvg } from 'assets/images/select-arrow.svg'

export const Container = styled.div``

export const Content = styled.div`
  width: 50%;
  margin: 0 auto;
  &.sm,
  &.xs {
    width: auto;
  }
`
// Webshop button DO NOT REMOVE !!
// export const DeleteFixedButtonIcon = styled.i`
//   position: absolute;
//   right: 20px;
//   margin-top: 20px;
//
//   cursor: pointer;
//   font-size: 16px;
//   font-weight: 400;
//   display: inline-block;
//   color: #fff;
//   background: rgb(192, 57, 43);
//   border-radius: 0.25em;
//   text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.4);
//
//   line-height: 40px;
//   width: 45px;
//   height: 40px;
//   padding: 0px;
//   text-align: center;
//   z-index: 5;
//
//   &:hover {
//     box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2), inset 0 0 0 99999px rgba(0, 0, 0, 0.2);
//   }
//
//   top: 2.5em;
//   left: 1em;
//
//   @media (max-width: 767px) {
//     top: 0;
//   }
// `

export const Collapse = styled.div`
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
  background: #fff;
  overflow: hidden;
  height: 0;
  transition: height 0.3s;
  &.active {
    height: 200px;
    transition: height 0.3s;
    padding: 1.25rem;
  }
`
export const Button = styled.button`
  font: inherit;
  background-color: ${(props) => props.theme.colors.submit};
  color: #fff;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0 1.5em;
  line-height: 2em;
  min-width: 9em;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.025em;
  outline: none;
  margin: 0;
  margin-top: 10px;
  float: right;
  :hover {
    background-color: ${(props) => props.theme.colors.submit_hover};
  }
  &.xs {
    width: 100%;
  }
`
export const SelectArrow = styled(ArrowSvg)`
  cursor: pointer;
  top: calc(50% - 12px);
  color: ${(props) => props.theme.colors.link};
  position: absolute;
  pointer-events: none;

  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  &.active {
    transform: rotate(180deg);
  }
`
