import { store } from 'store'
import {
  IEmployeeCard,
  IFeedback,
  IFooter,
  IHeader,
  IHTML,
  IMap,
  IForm,
  IGallery,
  IPhoto,
  IPrice,
  ITextAndImage,
  PageSectionTypes,
  IContact,
  IFixedButtonSingle, ILinkButton,
} from 'store/page/types'
import { SectionNames } from '../constants'

export const initialNewSection = (name: SectionNames): PageSectionTypes | undefined => {
  const themeTitle = localStorage.getItem('theme-ui-color-mode') || 'Classic'

  switch (name) {
    case SectionNames.HEADER:
      return {
        background_image: '',
        is_video: false,
        logo: '',
        title: '',
        is_logo_visible: true,
        is_title_visible: true,
        phone: '',
        is_online_booking: true,
        establishment_id: 0,
      } as IHeader
    case SectionNames.PRICING:
      return {} as IPrice
    case SectionNames.CONTACT:
      return {
        address: 'address',
        email: 'email@domain.com',
        phone: '+32 09 999 99 99',
      } as IContact
    case SectionNames.EMPLOYEE:
      return {
        image: '',
        name: 'Employee name',
        description: 'Employee description',
      } as IEmployeeCard
    case SectionNames.MAP:
      return { address: '' } as IMap
    case SectionNames.FOOTER:
      return {
        copyright: 'text',
        vat: 'text',
        social: [],
      } as IFooter
    case SectionNames.TEXTANDIMAGES:
      return [] as ITextAndImage[]
    case SectionNames.BUTTON:
      return {
        content: {
          name: 'Default',
          link: 'www.example.com',
        },
      } as ILinkButton
    case SectionNames.FEEDBACK: {
      try {
        const state = store.getState()
        if (state.config.data.feedback) {
          return state.config.data.feedback?.items.map((item) => item as IFeedback)
        }
      } catch (e) {}
      break
    }
    case SectionNames.HTML:
      return {
        content:
          themeTitle === 'Classic'
            ? '<h3>Title</h3><p>Add content ...</p>'
            : '<div class="custom-html-wrapper"><h1 class="custom-html-first-text">Example text (optional)</h1><h2 class="custom-html-sub-header ">Your title</h2><p class="custom-html-welcome-text">Hello, welcome to Salon name.</p><p class="custom-html-description-text">Your description</p></div>',
      } as IHTML
    case SectionNames.GALLERY:
      try {
        const state = store.getState()
        if (state.config.data.gallery) {
          return state.config.data.gallery?.files.map((item) => item) as IGallery[]
        }
      } catch (e) {}
      break
    case SectionNames.FORM:
      return { email: '' } as IForm
    case SectionNames.INLINE:
      return []
    case SectionNames.PHOTO:
      return { id: 1 } as IPhoto
    case SectionNames.FIXEDBUTTON:
      return [] as IFixedButtonSingle[]
  }
}
