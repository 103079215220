import styled from '@optios-frontend-website/shared-ui/src/theme'

export const NavigationLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NavigationLogo = styled.img`
  display: inline;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  padding: 16px;
`

export const NavigationTitle = styled.p`
  &:hover {
    cursor: pointer;
  }
`

export const NavigationWrapper = styled.div`
  display: flex;
`
