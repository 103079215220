import styled from '@optios-frontend-website/shared-ui/src/theme'
import { css } from '@emotion/core'

export const sideMenuABehavior = css`
  &.calendar.selected {
    background: #ffab0f;
  }
  &.customers.selected {
    background: #69d321;
  }
  &.register.selected {
    background: #8262d8;
  }
  &.products.selected {
    background: #a47433;
  }
  &.punchclock.selected {
    background: #f8549c;
  }
  &.reports.selected {
    background: #d24444;
  }
  &.marketing.selected {
    background: #24a7f3;
  }
  &.minor {
    font-size: 0.7em;
  }
  &.minor.selected {
    background: rgba(255, 255, 255, 0.2);
  }
`

/* LAYOUT SWITCH FOR SOME SUBCLASSES ALSO IN THIS QUERRY*/
export const mainNavMediaQueries = css`
  @media (max-height: 800px) {
    padding-bottom: 0.5em;
  }
  @media (max-width: 1023px) {
    -webkit-flex-basis: 3.75em;
    -ms-flex-basis: 3.75em;
    flex-basis: 3.75em;
  }

  /* LAYOUT SWITCH */
  @media (max-width: 767px) {
    position: fixed;
    width: 48px;
    height: 48px;
    padding: 0;
  }
`
export const MainNav = styled.div`
  /* PRIMARY */
  pointer-events: auto;
  background: #232934;
  -webkit-flex: 0 0 5em;
  -ms-flex: 0 0 5em;
  flex: 0 0 5em;
  padding: 0 0 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  ${mainNavMediaQueries};
`

export const navAMediaQueries = css`
  @media (max-width: 1023px) {
    height: 3.75em;
  }
  @media (max-width: 767px) {
    display: none;
    &.home{
      display: block;
  }
`
export const NavA = styled.a`
  text-align: center;
  height: 5em;
  color: rgba(255, 255, 255, 0.4);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-decoration: none;

  &:hover,
  &.selected {
    color: #fff;
  }
  ${sideMenuABehavior}
  ${navAMediaQueries};
`

export const navIconMediaQueries = css`
  @media (max-width: 1023px) {
    font-size: 1.75em;
  }

  /* LAYOUT SWITCH */
  @media (max-width: 767px) {
    font-size: 1.5em;
    &:before {
      top: 12px;
    }
  }
`
export const NavIcon = styled.i`
  &.icon-off,
  &a.selected .icon-on {
    display: none;
  }
  font-size: 2.25em;
  ${navIconMediaQueries};
`

export const seperatorMediaQueries = css`
  /* LAYOUT SWITCH */
  @media (max-width: 767px) {
    display: none;
  }
`
export const Seperator = styled.div`
  -webkit-flex: 1 1 2em;
  -ms-flex: 1 1 2em;
  flex: 1 1 2em;
  ${seperatorMediaQueries};
`
