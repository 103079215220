import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: 'en',
    lng: 'en',
    // debug: process.env.NODE_ENV !== 'production',
    ns: ['website'],
    defaultNS: 'website',
    keySeparator: false,
    nsSeparator: ';;',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_TRANSLATIONS_URL}/{{ns}}/locale/{{lng}}`,
      crossDomain: true,
    },
  })
export default i18n
