import React, { FC } from 'react'
import { useScreenClass } from 'react-grid-system'

import { IHeader } from '../../../types/sections'
import { WebsiteLogo, Title, Image } from './style'
import logoSvg from './Logo.svg'

type Props = {
  src: string
  data: IHeader
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const Logo: FC<Props> = (props: Props) => {
  const screenClass = useScreenClass()
  const { title, is_logo_visible, is_title_visible } = props.data

  return (
    <>
      <WebsiteLogo onClick={props.handleClick}>
        {is_logo_visible && <Image className={screenClass} src={props.src || logoSvg}></Image>}
        {is_title_visible && <Title>{title}</Title>}
      </WebsiteLogo>
    </>
  )
}

export default Logo
