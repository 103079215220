
import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 8px 16px;
  justify-content: center;
`

export const Button = styled.a`
  position: relative;
  background-color: #ededed;
  text-align: center;
  width: 200px;
  color: #889290;
  padding: 12px 24px;
  margin: 8px;
  border-radius: 2px;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background-color: rgba(237, 237, 237, 0.5);
  }
`

export const Input = styled.input`
  margin-bottom: 6px;
`

export const SaveButton = styled.button`
  background-color: #0090ff;
  border-radius: 5px;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  padding: 0 1.5em;
  line-height: 2.75em;
  min-width: 9em;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.025em;
  vertical-align: middle;
  margin: 0 16px;
`

export const ToolTip = styled.span<{ isToolTipVisible: boolean }>`
  display: ${({ isToolTipVisible }) => (isToolTipVisible ? 'block' : 'none')};
  opacity: ${({ isToolTipVisible }) => (isToolTipVisible ? 1 : 0)};
  visibility: ${({ isToolTipVisible }) => (isToolTipVisible ? 'visible' : 'hidden')};
  width: 200px;
  background-color: #ededed;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 16px;
  position: absolute;
  z-index: 10;
  transform: translate(0%, 56%);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  &:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #ededed transparent;
  }
`

export const ToolTipWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

