import React, { FC, ReactNode } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Slider, Wrapper, PrevArrowButton, NextArrowButton } from './style'

type GalleryProps = {
  children?: ReactNode
  settings?: {
    slidesToShow: number
  }
}

const Gallery: FC<GalleryProps> = (props: GalleryProps) => {
  const defaultSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,

    prevArrow: <PrevArrowButton />,
    nextArrow: <NextArrowButton />,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          variableWidth: false,
        },
      },
    ],
  }
  const settings = {
    ...defaultSettings,
    ...props.settings,
  }
  return (
    <Wrapper>
      <Slider {...settings}>{props.children}</Slider>
    </Wrapper>
  )
}

export default Gallery
