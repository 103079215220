import styled from '@optios-frontend-website/shared-ui/src/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const FeedbackScrollContainer = styled.div`
  display: flex;
  overflow: auto;
  overflow-x: scroll;
  white-space: nowrap;
  padding-bottom: 1em;
  margin: 1em 0;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: ${({ theme }) => theme.colors.background};
  }
`

export const FeedbackContainer = styled.section`
  background: ${({ theme }) => theme.colors.background};
`
export const Block = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.primary};
  width: 16em;
  margin: 0 auto;
  border-radius: 18px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`
export const Smile = styled.div`
  flex: 0 0 auto;
  margin-right: 0.5em;
`

export const Content = styled.div`
  flex: 1 1 auto;
`
export const Title = styled.div`
  font-weight: bold;
  line-height: 1.2em;
  height: 1.2em;
`

export const SubTitle = styled.span`
  line-height: 1.2em;
  height: 1.2em;
  font-style: italic;
  font-size: ${({ theme }) => theme.sizes.xsmall};
`

export const Text = styled.div`
  padding: 8px;
  font-size: ${({ theme }) => theme.sizes.medium};
  white-space: normal;
`

export const DotsWrapper = styled.div`
  margin-top: 20px;
  .carousel__dot {
    font-size: 0.6em;
    color: ${({ theme }) => theme.colors.gText};
  }

  .carousel__dot--selected {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

export const CarouselProviderWrapper = styled.div`
  .carousel {
    text-align: center;
  }
`

export const CarouselTitle = styled.h3`
  font-size: ${({ theme }) => theme.sizes.xlarge};
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.feedbackTitle};
  font-weight: 700;
  padding: 1em 0 3em 0;
`

export const SliderWrapper = styled.div`
  padding: 3rem 0 0.5rem 0;
  margin: 3em 0;
  height: 250px;
}
`

export const SliderContainer = styled.div``
export const CarouselDescriptionText = styled.h3`
  font-size: ${({ theme }) => theme.sizes.medium};
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.feedbackParagraph};
  font-weight: 300;
`

export const CarouselHorizontalLine = styled.hr`
  width: 80px;
  background-color: ${({ theme }) => theme.colors.feedbackParagraph};
  height: 2px;
  border: none;
`

export const CarouselClientText = styled.p`
  font-size: ${({ theme }) => theme.sizes.small};
  font-family: ${({ theme }) => theme.fonts.primary};
  color: ${({ theme }) => theme.colors.feedbackParagraph};
  font-style: italic;
`

export const NoFeedbackWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
`

export const NoFeedbackText = styled.p`
  padding: 16px;
  font-size: ${({ theme }) => theme.sizes.medium};
  white-space: normal;
`
