import { CreateStyled } from '@emotion/styled'
import styled from '@emotion/styled/macro'

import themeClassic from './themes/classic'
import themeDark from './themes/dark'
import themeLight from './themes/light'

export interface Theme {
  textAndImage: {
    title: {
      fontSize: string
      fontFamily: string
      fontWeight: string | number
      color: string
      lineHeight: string
      isSemiUnderlined: boolean
    }
    iconColor: string
    iconSize: string
    borderColor: string
  }
  colors: {
    [key: string]: string
  }
  shadows: {
    [key: string]: string
  }
  sizes: {
    [key: string]: string
  }
  fonts: {
    [key: string]: string
  }
  fontWeights: {
    [key: string]: string
  }
  title: {
    fontDecoration: 'normal' | 'uppercase'
    fontDecorationBottom: '1px solid black' | 'none'
  }
  pricing: {
    pricingContainerWidth: '750px' | '1140px'
    titleDirection: 'left' | 'center'
    titleDecoration: '1px solid black' | 'none'
    descriptionDecoration: '#5c5d5e' | '#d4d4d4'
    bookButtonMarginLeft: '24px' | 0
  }
  contact: {
    titleDecoration: '1px solid #d1d1d1;'
  }
  contactForm: {
    inputDirection: 'block' | 'flex'
  }
}

export { themeClassic, themeLight, themeDark }
export default styled as CreateStyled<Theme>
