import React, { FC } from 'react'

import { SpinnerWrapper, SpinnerBorder, SpinnerDot, Container, SpinnerCenter, SpinnerText } from './style'

type Props = {
  isLoaded: boolean
  text?: string
}

const Spinner: FC<Props> = (props: Props) => {
  return (
    <div>
      {props.isLoaded ? (
        <Container>
          <SpinnerCenter>
            <SpinnerWrapper>
              <SpinnerDot />
              <SpinnerBorder />
            </SpinnerWrapper>
          </SpinnerCenter>
          {props.text ? <SpinnerText>{props.text}</SpinnerText> : null}
        </Container>
      ) : null}
    </div>
  )
}

export default Spinner
