import { ITheme, ADD_THEMES, ThemeActionTypes } from './types'

const initialState: ITheme[] = []

export const themeReducer = (state = initialState, action: ThemeActionTypes): ITheme[] => {
  switch (action.type) {
    case ADD_THEMES:
      return [...state, ...action.payload]

    default:
      return state
  }
}
