import { IMenuCategory } from '../../../interface/IMenuCategory'

export const getNextCategories = (activeSelection: number[], menuCategories: IMenuCategory[]): IMenuCategory[] => {
  if (activeSelection.length === 0) {
    return menuCategories.filter((category: IMenuCategory) => category.isOnline)
  }
  const menuCategory = menuCategories.find((category: IMenuCategory) => category.id === activeSelection[0])

  if (typeof menuCategory === 'undefined') {
    return []
  }

  return getNextCategories(activeSelection.slice(1, activeSelection.length), menuCategory.categories || [])
}
