import styled from '../../theme'

export const Wrapper = styled.div<{ width: number }>`
  height: 2.2em;
  position: relative;
  top: ${({ width }) => (width > 900 ? '24%' : '0')};
`
export const LanguageNav = styled.div<{ themeTitle: string }>`
  right: 0;
  z-index: 2;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #999;
  cursor: pointer;
  position: absolute;
  margin: 0.2em 12px;
  width: ${({ themeTitle }) => (themeTitle === 'Classic' ? '10em' : '70px')};
`
export const List = styled.ul<{ themeTitle: string }>`
  width: ${({ themeTitle }) => (themeTitle === 'Classic' ? '10em' : '70px')};
  margin: 0;
  padding: 0;
  &:hover {
    li {
      display: block;
    }
  }
`
export const Item = styled.li`
  padding: 0.3em 0.5em 0.3em 0.5em;
  font-size: ${({ theme }) => theme.sizes.xsmall};
  font-family: ${({ theme }) => theme.fonts.primary};
  display: none;
  &.active {
    display: block;
  }
  a {
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
    color: ${(props) => props.theme.colors.link};
    text-decoration: none;
  }
  img {
    margin-right: 0.5em;
  }
  &:hover {
    background-color: #eeeeee;
  }
`
