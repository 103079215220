import { Theme } from '../theme'

const error = '#d24444'
const info = '#0090ff'
const infoHover = '#70befa'

const themeDark: Theme = {
  textAndImage: {
    title: {
      fontSize: '2em',
      fontFamily: 'Quicksand, sans-serif',
      fontWeight: 700,
      color: '#967c47',
      lineHeight: '1.2',
      isSemiUnderlined: true,
    },
    iconSize: '1.5em',
    iconColor: '#ffffff',
    borderColor: 'transparent',
  },
  colors: {
    backgroundFirst: '#ffffff',
    backgroundSecond: '#ede9e5',
    primary: '#000000',
    secondary: '#e5cd99',
    feedbackTitle: '#967c47',
    feedbackParagraph: '#000000',
    feedbackParagraphFallback: '#ffffff',
    blackFont: '#000000',
    whiteFont: '#ffffff',
    success: '#69d321',
    warning: '#fbb03b',
    error: error,
    info: info,
    infoHover: infoHover,
    destructive: error,
    danger: error,
    link: info,
  },
  shadows: {
    light: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    medium: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    dark: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
  },
  sizes: {
    xsmall: '0.8em',
    small: '1em',
    medium: '1.2em',
    large: '1.5em',
    xlarge: '2em',
  },
  fonts: {
    primary: 'Lato, sans-serif',
    secondary: 'Quicksand, sans-serif',
    third: 'Source Sans Pro, sans-serif',
    fourth: 'Roboto, sans-serif',
  },
  fontWeights: {
    light: '300',
    regular: '400',
    medium: '500',
    bold: '700',
  },
  // Theme sections customization

  // Navigation
  title: {
    fontDecoration: 'uppercase',
    fontDecorationBottom: 'none',
  },
  // Pricing
  pricing: {
    pricingContainerWidth: '1140px',
    titleDirection: 'center',
    titleDecoration: '1px solid black',
    descriptionDecoration: '#5c5d5e',
    bookButtonMarginLeft: 0,
  },
  // Contact
  contact: {
    titleDecoration: '1px solid #d1d1d1;',
  },
  // Contact form
  contactForm: {
    inputDirection: 'flex',
  },
}

export default themeDark
