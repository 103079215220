import React, { useState, FunctionComponent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SharedContactForm from '@optios-frontend-website/shared-ui/src/components/ContactForm'
import { Regexp } from '../../constants'
import Modal from 'shared/Modal'
import Button from 'shared/ButtonModal'
import InputField from 'shared/InputField'
import { checkIfValidEmail, editWebsite } from 'services/website-service'
import { successToast, errorToast } from 'services/toasts'
import { SetRecipient } from './style'
import { useSelector } from 'react-redux'
import { RootState } from 'store/index'
import { IProps, TEmailValues } from './IProps'

const ContactForm: FunctionComponent<IProps> = ({ website, position, col, updateSection, isEditing }) => {
  const { register, handleSubmit, reset } = useForm<TEmailValues>({
    defaultValues: { email: website.email },
  })
  const [emailValidationError, setEmailValidationError] = useState<string | undefined>()
  const [emailField, setEmailField] = useState<string | undefined>(website.email)
  const [modalIsOpen, setIsOpen] = useState(isEditing)
  const locale = useSelector<RootState, string>((state) => state.config.locale)
  const { t } = useTranslation()

  useEffect(() => {
    setIsOpen(isEditing)
  }, [isEditing])

  const onRequestClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setIsOpen(false)
  }

  const open = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setIsOpen(true)
  }

  const isEmailValidCheck = async () => {
    if (!emailField) {
      return setEmailValidationError(t('Please fill in a email.'))
    }
    checkIfValidEmail(emailField)
      .then(() => {
        return setEmailValidationError(undefined)
      })
      .catch((err) => {
        return setEmailValidationError(t(err.response.data.message))
      })
  }

  const submitHandler = handleSubmit((formData) => {
    isEmailValidCheck().then(() => {
      editWebsite({ ...website, email: formData.email }).then(
        () => {
          reset(formData)
          successToast()
        },
        () => {
          errorToast()
        },
      )
      updateSection('form', formData, position, col)
      setIsOpen(false)
    })

  })

  return (
    <>
      <SharedContactForm endPoint={process.env.REACT_APP_API_ENDPOINT} websiteId={website.id}>
        <SetRecipient onClick={open} type={'button'}>
          <span style={{ marginRight: '.5em' }}>{t('page:current_recipient', { lng: locale })}</span>
          <span style={{ fontWeight: 'bold' }}>
            {website.email}
            <i className={'icon icon-edit'} style={{ marginLeft: '.5em' }} />
          </span>
        </SetRecipient>
      </SharedContactForm>
      <Modal title={t('page:current_recipient', { lng: locale })} isOpen={modalIsOpen} onRequestClose={onRequestClose}>
        <form onSubmit={submitHandler}>
          <p style={{ marginBottom: '1em' }}>{t('contact_form:recipient:help', { lng: locale })}</p>
          <InputField
            registerRef={register({
              pattern: {
                value: RegExp(Regexp.EMAIL),
                message: t('contact_form:validation:email', { lng: locale }),
              },
            })}
            name="email"
            label={t('contact_form:email', { lng: locale })}
            error={emailValidationError}
            onChange={(e) => setEmailField(e.target.value)}
            onBlur={isEmailValidCheck}
          />
          <div style={{ textAlign: 'right' }}>
            <Button handleClick={onRequestClose} className="dialog-close">
              {t('page:cancel', { lng: locale })}
            </Button>
            <Button type="submit">{t('page:save', { lng: locale })}</Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default ContactForm
