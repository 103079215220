import React, { Fragment, FunctionComponent } from 'react'
import { Row, Col } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import {
  Cost,
  Title,
  PricingContainer,
  CategoryText,
  CategoryHorizontalLine,
  CategoryBookNowButton,
  PricingContainerWrapper,
  PricingContentWrapper,
  DescriptionText,
} from './style'

import { Api } from '../../constants'
import { IProps } from './IProps'

export const RenderPricingList: FunctionComponent<IProps> = ({
  isOnlineBooking,
  phoneNumber,
  handleClick,
  pricing,
}) => {
  const { t } = useTranslation()
  const onlineBookingUrl = Api.BOOKING_URL

  return (
    <PricingContainer onClick={handleClick}>
      <PricingContainerWrapper>
        <div>
          <Title>{t('page:pricing')}</Title>
        </div>
        {Object.entries(pricing).map((category) => {
          const categoryName = category[1].name
          const activityDetails = category[1].data
          if (!activityDetails) {
            return
          }

          return (
            <>
              <h5>{categoryName}</h5>
              {activityDetails.map((activityDetail, activityIndex) => (
                <Fragment key={activityIndex}>
                  <Row>
                    <Col>
                      <CategoryText>{activityDetail.category}</CategoryText>
                    </Col>
                    <PricingContentWrapper>
                      <Cost>&euro;&nbsp;{activityDetail.price}</Cost>
                    </PricingContentWrapper>
                    <Col xxl={1} xl={1} lg={1}>
                      <CategoryBookNowButton
                        href={isOnlineBooking ? onlineBookingUrl + activityDetail.path : `tel:${phoneNumber}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('Book')}
                      </CategoryBookNowButton>
                    </Col>
                  </Row>
                  {activityDetail.description && (
                    <Row>
                      <Col>
                        <DescriptionText>{activityDetail.description}</DescriptionText>
                      </Col>
                    </Row>
                  )}
                </Fragment>
              ))}
              <CategoryHorizontalLine />
            </>
          )
        })}
      </PricingContainerWrapper>
    </PricingContainer>
  )
}
