import React, { FC, useEffect } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useLocation } from 'react-router-dom'
import i18n from '@optios-frontend-website/shared-ui/src/i18n'

import { Settings, PublishBtn, NavLink, Link, SavePageBtn, DynamicSpan, LinkSpan } from './style'
import { useTranslation } from 'react-i18next'
import { IWebsite } from 'store/website/types'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store/index'

type Props = {
  editCurrentPage: () => void
  publish: () => void
  disabledPublish: boolean
  website: IWebsite
}

const SettingsComponent: FC<Props> = (props: Props) => {
  const { disabledPublish, website, publish, editCurrentPage } = props
  const location = useLocation()
  const { t } = useTranslation()
  const { currentPage, locale } = useSelector((state: ApplicationState) => ({
    currentPage: state.page.currentPage,
    locale: state.config.locale,
  }))

  let url = website.domain ? `https://${website.domain}` : `https://${website.slug}.optiosweb.net`
  const singleUrl = process.env.REACT_APP_PUBLIC_URL
  if (singleUrl && '' !== singleUrl) {
    url = singleUrl
  }

  useEffect(() => {
    i18n.loadLanguages(locale)
  }, [locale])

  return (
    <Settings>
      <Container style={{ width: '100%' }}>
        <Row>
          <Col>
            <div style={{ display: 'inline', width: '100%' }}>
              <NavLink>
                <Link to="/website-settings">
                  <>
                    <i className={'icon icon-settings'} /> {t('website.settings', { lng: locale })}
                  </>
                </Link>
              </NavLink>
              {location.pathname.includes('/update') && (
                <NavLink>
                  <Link to="/page">
                    <>
                      <i className={'icon icon-edit'} /> {t('page.settings', { lng: locale })}
                    </>
                  </Link>
                  <Link to="/page" className={'icon icon-on icon-edit'} />
                </NavLink>
              )}
              {location.pathname.startsWith('/page') && (
                <NavLink>
                  <Link to={`/update/${currentPage?.slug}`}>
                    <>
                      <i className={'icon icon-edit'} /> {t('Update page', { lng: locale })}
                    </>
                  </Link>
                  <Link to={`/update/${currentPage?.slug}`} className={'icon icon-on icon-edit'} />
                </NavLink>
              )}
              {location.pathname.includes('update') && currentPage && currentPage.isUpdated && (
                <SavePageBtn onClick={editCurrentPage}>
                  <DynamicSpan>
                    <>
                      <i className={'icon icon-checkbox'} /> {t('Save page', { lng: locale })}
                    </>
                  </DynamicSpan>
                  <DynamicSpan className={'icon icon-on icon-checkbox'} />
                </SavePageBtn>
              )}

              {website && currentPage && !currentPage.isUpdated && (
                <PublishBtn onClick={publish} disabled={disabledPublish}>
                  <DynamicSpan>
                    <>
                      <i className={'icon icon-trusted'} /> {t('publish.website', { lng: locale })}
                    </>
                  </DynamicSpan>
                  <DynamicSpan className={'icon icon-on icon-trusted'} />
                </PublishBtn>
              )}
              {website && website.slug && (
                <LinkSpan target={'_blank'} href={url}>
                  {website.domain ? `https://${website.domain}` : `https://${website.slug}.optiosweb.net`}
                  {currentPage ? `/${currentPage.locale}/${currentPage.slug}` : ''}
                </LinkSpan>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Settings>
  )
}

export default SettingsComponent
