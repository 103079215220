import React, { FC, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { IFeedback } from '../../types/sections'
import { useTranslation } from 'react-i18next'

import { getConfigAuth, getFeedback } from '@optios-frontend-website/website-admin/src/services/website-service'
import Spinner from '@optios-frontend-website/website-admin/src/components/shared/Spinner'

import {
  DotsWrapper,
  CarouselProviderWrapper,
  CarouselDescriptionText,
  CarouselClientText,
  CarouselHorizontalLine,
  CarouselTitle,
  SliderWrapper,
  Block,
  Content,
  Title,
  SubTitle,
  Text,
  NoFeedbackText,
  NoFeedbackWrapper,
  SliderContainer,
} from './style'

import { CarouselProvider, Dot, Slide, Slider } from 'pure-react-carousel'

import 'pure-react-carousel/dist/react-carousel.es.css'

type Item = {
  rating: 'good' | 'bad'
  customer: string
  date: string
  text: string
}

const formatDate = (date: string): string => {
  const mL = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const currentDate = new Date(date)
  return `${mL[currentDate.getMonth()]} ${('0' + currentDate.getDate()).slice(-2)},  ${currentDate.getFullYear()}`
}

type Props = {
  data: IFeedback[]
}

const Feedback: FC<Props> = () => {
  const { t } = useTranslation()
  const themeTitle = localStorage.getItem('theme-ui-color-mode') || 'Classic'

  const [feedbackData, setFeedbackData] = useState<IFeedback[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    getConfigAuth()
      .then(({ data }) => {
        return getFeedback(String(data.establishment_id))
      })
      .then(({ data }) => setFeedbackData(data))
      .finally(() => setIsLoading(false))
  }, [])

  const length = feedbackData && feedbackData.length

  return (
    <>
      <CarouselProviderWrapper>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={15}
          totalSlides={length}
          isPlaying={true}
          interval={10000}
        >
          <CarouselTitle>{t('What do our customers think?')}</CarouselTitle>
          {feedbackData.length > 0 ? (
            <SliderWrapper>
              <SliderContainer id="container">
                <Slider>
                  {themeTitle === 'Classic' ? (
                    <>
                      {Array.isArray(feedbackData) &&
                        feedbackData.map((item, index) => (
                          <>
                            <Slide index={index} key={item.date} tabIndex={index}>
                              <Block key={item.date}>
                                <Content key={item.date}>
                                  <Title key={item.date}>{item.customer}</Title>
                                  <SubTitle key={item.date}>{moment(item.date).format('MMMM D, YYYY')}</SubTitle>{' '}
                                  <Text>{item.text}</Text>
                                </Content>
                              </Block>
                            </Slide>
                          </>
                        ))}
                    </>
                  ) : (
                    <>
                      {Array.isArray(feedbackData) &&
                        feedbackData.map((item, index) => (
                          <>
                            <Slide index={index} key={item.date} tabIndex={index}>
                              <CarouselDescriptionText key={item.date}>{item.text}</CarouselDescriptionText>
                              <CarouselHorizontalLine />
                              <CarouselClientText key={item.date}>{item.customer}</CarouselClientText>
                            </Slide>
                          </>
                        ))}
                    </>
                  )}
                </Slider>
              </SliderContainer>
              <DotsWrapper>
                {Array.isArray(feedbackData) &&
                  feedbackData.map((item, index) => (
                    // @ts-ignore
                    <Dot
                      style={{
                        border: 'none',
                        background: 'transparent',
                      }}
                      slide={index}
                      key={item.date}
                      onFocus={() => console.log('focus')}
                    >
                      <FontAwesomeIcon key={item.date} icon={'circle'} />
                    </Dot>
                  ))}
              </DotsWrapper>
            </SliderWrapper>
          ) : (
            <NoFeedbackWrapper>
              ⚠️
              <NoFeedbackText>
                {t(`Currently there are no feedbacks`)}
              </NoFeedbackText>
              <NoFeedbackText>
                {t(`Make sure that you have feedback or delete this section`)}
              </NoFeedbackText>
              <NoFeedbackText>
                {t(`If you proceed and publish the page the Feedbacks section won't be shown`)}
              </NoFeedbackText>
            </NoFeedbackWrapper>
          )}
        </CarouselProvider>
      </CarouselProviderWrapper>
    </>
  )
}

export default Feedback
