import React, { FC, useState } from 'react'

import InputField from 'shared/InputField'
import {
  MediaBlock,
  DeleteSocial,
  CollapseForm,
  Button,
  SocialContent,
  SocialContentLink,
  SocialContentName,
  Text,
  SaveIcon,
} from './style'
import { Regexp } from '../../constants'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud } from '@fortawesome/free-solid-svg-icons'

type Props = {
  media: { name: string; link: string }
  addNewSocial: (data: { name: string; link: string }) => void
  deleteSocial: (name: string) => void
}

const SocialMedia: FC<Props> = ({ media, addNewSocial, deleteSocial }: Props) => {
  const [active, setActive] = useState(false)
  const [currentMedia, updateMedia] = useState(media)
  const { t } = useTranslation()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault()
    const { name, value } = e.target
    updateMedia({ ...currentMedia, [name]: value })
  }
  const onSave = () => {
    addNewSocial(currentMedia)
    setActive(false)
  }
  return (
    <MediaBlock key={media.link}>
      <SocialContent onClick={() => setActive(!active)}>
        <SocialContentName>{media.name}</SocialContentName>
        <SocialContentLink>{media.link}</SocialContentLink>
        <DeleteSocial onClick={() => deleteSocial(media.name)}>
          <FontAwesomeIcon icon={'times'} />
        </DeleteSocial>{' '}
      </SocialContent>
      <CollapseForm className={active ? 'active' : ''}>
        <InputField
          onChange={handleChange}
          value={currentMedia.link}
          error={!RegExp(Regexp.URL).test(currentMedia.link) ? 'url is not valid' : ''}
          name="link"
          label={t('footer:media_link')}
        />
        <div style={{ textAlign: 'right', paddingTop: '0.5em' }}>
          <Button
            onClick={(e: React.MouseEvent) => {
              e.preventDefault()
              onSave()
            }}
          >
            <SaveIcon icon={faCloud} />
            <Text>{t('Save changes')}</Text>
          </Button>
        </div>
      </CollapseForm>
    </MediaBlock>
  )
}

export default SocialMedia
