import themeStyled from '@optios-frontend-website/shared-ui/src/theme'

export const Wrapper = themeStyled.div<{ open: boolean }>`
  display: flex;
  flex-direction: ${({ open }) => (open ? 'column' : 'row')};
  margin-bottom: ${({ open }) => (open ? '6em' : 0)};

  .inner-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 1024px;
    margin: 0 auto;
  }
`