import styled from '@optios-frontend-website/shared-ui/src/theme'

export const Wrapper = styled.div<{
  pagesLength?: boolean
  themeTitle: string
  width: number
}>`
  cursor: pointer;
  padding: ${({ width, pagesLength }) => (width > 1600 && pagesLength ? '0 0.5em' : width > 1600 ? '0 1.5em' :  '0 0.5em')};
  line-height: 3;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 54px;
  margin: ${({ width }) => (width > 1600 ? '21px 10px' : '21px 0')};
  color: ${({ theme }) => theme.colors.feedbackTitle};

  &.active {
    color: ${({ theme }) => theme.colors.secondary};
    background: ${({ themeTitle }) => (themeTitle === 'Classic' ? '#e3e3e3' : 'none')};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    transition: color 0.3s ease 0s;
    border-bottom: ${({ theme }) => theme.title.fontDecorationBottom};
  }
  a {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ pagesLength , theme }) => pagesLength ? '0.65em' : theme.sizes.xsmall};
    text-transform: ${({ theme }) => theme.title.fontDecoration};
    text-decoration: none;
    color: inherit;
  }
  &.md,
  &.sm,
  &.xs {
    width: 100%;
  }
`

export const NavigationText = styled.p<{ themeTitle: string }>`
  line-height: ${({ themeTitle }) => (themeTitle === 'Classic' ? '3.5' : '4')};
  font-weight: ${({ themeTitle }) => (themeTitle === 'Classic' ? '100' : '')};
`
