import {
  IWebsite,
  ADD_WEBSITE,
  EDIT_WEBSITE,
  WebsiteAddAction,
  WebsiteEditAction,
  UPDATE_CURRENT_LANGUAGE,
  WebsiteUpdateCurrentLanguageAction,
} from './types'

export const addWebsite = (website: IWebsite): WebsiteAddAction => {
  return {
    type: ADD_WEBSITE,
    payload: website,
  }
}

export const editWebsite = (website: IWebsite): WebsiteEditAction => {
  return {
    type: EDIT_WEBSITE,
    payload: website,
  }
}

export const updateCurrentLanguage = (language: string): WebsiteUpdateCurrentLanguageAction => {
  return {
    type: UPDATE_CURRENT_LANGUAGE,
    payload: language,
  }
}
