import React, { FC, useEffect, useState } from 'react'

import FraIcon from './icons/fra.svg'
import DeIcon from './icons/de.svg'
import EnIcon from './icons/gbr.svg'
import NedIcon from './icons/nld.svg'
import { LanguageNav, List, Item, Wrapper } from './style'
import { ITheme } from '@optios-frontend-website/website-public/src/types/theme'
import {
  useWindowDimensions
} from '@optios-frontend-website/website-admin/src/helpers/hooks/useWindowDimensions/useWindowDimensions'

const languages = {
  en: { icon: EnIcon, title: 'English', shortTitle: 'EN' },
  de: { icon: DeIcon, title: 'Deutsch', shortTitle: 'DE' },
  nl: { icon: NedIcon, title: 'Nederlands', shortTitle: 'NL' },
  fr: { icon: FraIcon, title: 'Français', shortTitle: 'FR' },
}

type Props = {
  theme: ITheme
  currentLanguage: string
  languages: string[]
  updateLanguage: (language: string) => void
}

type LanCodes = 'en' | 'de' | 'nl' | 'fr'

const Languages: FC<Props> = (props: Props) => {
  const [current, setCurrent] = useState<LanCodes>(props.currentLanguage as LanCodes)
  const { windowWidth } = useWindowDimensions()


  return (
    <Wrapper width={windowWidth}>
      <LanguageNav themeTitle={props.theme.title}>
        <List themeTitle={props.theme.title}>
          {props.languages.map((language) => (
            <Item
              onClick={(): void => {
                setCurrent(language as LanCodes)
                props.updateLanguage(language)
              }}
              key={language}
              className={current === language ? 'active' : ''}
            >
              <a>
                <img src={languages[language as LanCodes].icon} width={20} />
                <span>
                  {props.theme.title === 'Classic'
                    ? languages[language as LanCodes].title
                    : languages[language as LanCodes].shortTitle}
                </span>
              </a>
            </Item>
          ))}
        </List>
      </LanguageNav>
    </Wrapper>
  )
}

export default Languages
