import React, { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { NavigationText, Wrapper } from './style'
import { IPageItem } from '../../../../../shared-ui/src/types/page'
import { useDispatch } from 'react-redux'
import { setCurrentPage } from 'store/page/actions'
import { useWindowDimensions } from '../../../helpers/hooks/useWindowDimensions/useWindowDimensions'

type Props = {
  item: IPageItem
  isActive: boolean
  open: boolean
  pages: IPageItem[]
}
const NavigationItem: FunctionComponent<Props> = ({ item, isActive, pages }: Props) => {
  const dispatch = useDispatch()
  const { windowWidth } = useWindowDimensions()
  const themeTitle = localStorage.getItem('theme-ui-color-mode') || 'Classic'
  let pagesLength = 0
  if (pages) {
    pagesLength = pages.length
  }

  return (
    <Wrapper
      pagesLength={pagesLength > 5}
      width={windowWidth}
      themeTitle={themeTitle}
      className={isActive ? 'active' : ''}
    >
      <Link to={`/update/${item.slug}`} onClick={() => dispatch(setCurrentPage(item))}>
        <NavigationText themeTitle={themeTitle}>{item.title}</NavigationText>
      </Link>
    </Wrapper>
  )
}

export default NavigationItem
