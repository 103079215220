import React, { FunctionComponent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { stringToHsl } from '../../../../../../utils/stringToHsl'
import { IActivityCategory } from '../../interface/IActivity'
import { IFinalSelection } from '../../interface/IActivitySelect'
import { Tile } from '../Tile/Tile'

import { IProps } from './IProps'
import {
  addOrRemoveActivity,
} from 'services/establishment-activity-call/establishment-activity-call'

export const ActivityCategorySelect: FunctionComponent<IProps> = ({
  activityList,
  activitySelectObject,
  setActivitySelectObject,
}) => {
  const [selectedPath, setSelectedPath] = useState<string>('')
  const [nextCategorySelection, setNextCategorySelection] = useState<IActivityCategory>()
  const { activeSelection } = activitySelectObject
  const { t } = useTranslation()

  const addSelectionId = (id: number) => {
    setActivitySelectObject({
      type: 'activity',
      activeSelection: [id],
      selectionDetails: null,
    })
    setNextCategorySelection(activityList.find((activity) => activity.id === id))
    return
  }

  const addSelectionUuid = (uuidArray: IFinalSelection[]) => {
    if (uuidArray) {
      addOrRemoveActivity(uuidArray[0].uuid, true)
        .then((response) => {
          console.log('Category added successfully', response)
        })
        .catch((error) => {
          console.error('Error adding category', error)
        })
    }
    setActivitySelectObject({
      type: 'selected',
      activeSelection,
      selectionDetails: uuidArray,
    })
    return
  }

  if (activeSelection && activeSelection.length === 0) {
    return (
      <>
        {activityList.map((extraActivity) => {
          return (
            <Tile
              key={extraActivity.id}
              tileTitle={t(`activities;;${extraActivity.name}`)}
              tileColor={stringToHsl(extraActivity.name, 50, 50)}
              onClick={() => {
                setSelectedPath(selectedPath + '/' + extraActivity.id)
                return addSelectionId(extraActivity.id)}}
            />
          )
        })}
      </>
    )
  }

  return (
    <>
      {nextCategorySelection &&
        nextCategorySelection.activities.map((activity) => (
          <Tile
            key={activity.id}
            tileTitle={t(`activities;;${activity.name}`)}
            tileColor={stringToHsl(activity.name, 50, 50)}
            onClick={() =>
              addSelectionUuid([
                {
                  uuid: activity.uuid,
                  selectionDetails: {
                    name: activity.name,
                    price: typeof activity.price === 'number' ? `${activity.price}` : '0',
                    id: activity.id.toString(),
                    path: selectedPath,
                    category: nextCategorySelection.name,
                  },
                },
              ])
            }
          />
        ))}
    </>
  )
}
