import styled from '@optios-frontend-website/shared-ui/src/theme'

import { BaseTitle } from '../../base-style'

export const Cost = styled.span`
  color: ${({ theme }) => theme.colors.text};
  font: inherit;
  font-weight: 600;
`

export const PricingContentWrapper = styled.div`
  box-sizing: border-box;
  min-height: 1px;
  position: relative;
  width: 35%;
  -webkit-flex: 1 0 0px;
  -ms-flex: 1 0 0px;
  flex: 0 1 100px;
  max-width: 100%;
  right: auto;
  left: auto;
`

export const CategoryText = styled.p`
  font-size: 1em;
  font-family: Lato, sans-serif;
  font-weight: 700;
  -webkit-letter-spacing: 0.3px;
  -moz-letter-spacing: 0.3px;
  -ms-letter-spacing: 0.3px;
  letter-spacing: 0.3px;
  line-height: 1.7;
  color: #5c5d5e;
  -webkit-font-smoothing: antialiased;
`

export const CategoryBookNowButton = styled.a`
  background: transparent;
  color: #5c5d5e;
  margin-left: ${({ theme }) => theme.pricing.bookButtonMarginLeft};
  border: 1px solid #5c5d5e;
  padding: 4px 12px;
  font-size: 0.8em;
  font-weight: 700;
  font-family: Roboto, sans-serif;
  text-decoration: none;
  &:hover {
    background: #5c5d5e;
    color: #ffffff;
    cursor: pointer;
  }
`

export const CategoryHorizontalLine = styled.hr`
  color: #d1d1d1;
  height: 1px;
  width: 106%;
  margin-left: -10px;
  margin-right: -30px;
`
export const Title = styled(BaseTitle)`
  text-align: left;

`

export const PricingContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PricingContainerWrapper = styled.div`
  width: 100%;
  padding: 5em 3em;
`

export const DescriptionText = styled.p`
  font-size: 0.85em;
  font-family: Lato, sans-serif;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 1.7;
  color: #707273;
  -webkit-font-smoothing: antialiased;
`
