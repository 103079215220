import styled from '@optios-frontend-website/shared-ui/src/theme'

export const Wrapper = styled.div``

export const InlineContainer = styled.div`
  width: 100%;
  padding: 16px;
`

export const BackgroundLayerWrapper = styled.div<{
  image: string
}>`
  height: 100%;
  padding: 5em 0 3em 0;
  position: relative;
  background-size: cover !important;
  background: ${({ image }) => (image ? `url(${image})` : 'none')} no-repeat center center;
`

export const BackgroundLayer = styled.div<{
  sliderValue: number
}>`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ sliderValue }) => `rgba(0, 0, 0, 0.${sliderValue})`};
`

export const Content = styled.div`
  width: 100%;
  margin: 20px auto;
  display: flex;
`

export const SaveIcon = styled.button`
  height: 40px;
  width: 45px;
  border-radius: 4px;
  border: none;
  background: rgba(39, 46, 59, 1);
  color: #ffffff;
  margin-left: 4px;
  &:hover {
    cursor: pointer;
    background-color: rgba(39, 46, 59, 0.85);
  }
`

export const RemoveIcon = styled.button`
  height: 40px;
  width: 45px;
  border-radius: 4px;
  border: none;
  background: rgba(39, 46, 59, 1);
  color: #ffffff;
  margin-left: 4px;
  &:hover {
    cursor: pointer;
    background-color: rgba(39, 46, 59, 0.85);
  }
`

export const Button = styled.button`
  font: inherit;
  background: ${({ theme }) => theme.colors.info};
  color: ${({ theme }) => theme.colors.wText};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0 1.5em;
  line-height: 2em;
  min-width: 9em;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.025em;
  outline: none;
  margin: 0;
  margin-top: 10px;
  float: right;
  :hover {
    background-color: ${({ theme }) => theme.colors.infoHover};
  }
  &.xs {
    width: 100%;
  }
`

export const AddSectionIcon = styled.i<{
  isToolbarVisible: boolean
}>`
  position: absolute;
  right: 205px;
  margin: 1.3em 20px 0 0;
  display: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: green;
  background: rgba(116, 224, 65, 0.3);
  border-radius: 0.25em;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.4);
  line-height: 40px;
  width: 45px;
  height: 40px;
  padding: 0px;
  text-align: center;
  z-index: 5;

  &:hover {
    background: rgba(116, 224, 65, 0.5);
    cursor: pointer;
  }
`

export const ButtonNewText = styled.p`
  color: ${({ theme }) => theme.colors.wText};
  font-size: ${({ theme }) => theme.sizes.small};
  font-family: ${({ theme }) => theme.fonts.primary};
`

export const ButtonNewWrapperContainer = styled.div`
  display: flex;
`

export const ButtonNew = styled.button`
  position: absolute;
  //display: none;
  display: inline-block;
  top: 26%;
  left: 88%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  right: 0;
  background: ${({ theme }) => theme.colors.info};
  color: ${({ theme }) => theme.colors.whiteFont};
  cursor: pointer;
  font-size: 0.8em;
  padding: 0.5rem;
  border: 0;
  border-radius: 4px;
  outline: none;
  font-weight: bold;
  height: 39px;

  &:hover {
    background: ${({ theme }) => theme.colors.infoHover};
    cursor: pointer;
  }
`
