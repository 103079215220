import React, { FC } from 'react'
import { useScreenClass } from 'react-grid-system'

import { Image } from './style'

import logoSvg from './Logo.svg'

type Props = {
  src?: string
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void
}

const Photo: FC<Props> = (props: Props) => {
  const screenClass = useScreenClass()

  return <Image className={screenClass} onClick={props.handleClick} src={props.src || logoSvg} />
}

export default Photo
