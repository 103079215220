import axios, { clientAxios } from './http'
import { AxiosPromise, AxiosResponse } from 'axios'
import { IWebsite } from 'store/website/types'
import { IConfig } from 'store/config/types'
import { IApiPageItem, IApiSection, IInline, IPageItem, ISection } from 'store/page/types'
import { ITheme } from 'store/theme/types'
import { IConfigData } from 'store/config/types'

export const createWebsite = (body: IWebsite): AxiosPromise => {
  delete body.id
  delete body.organization_id
  return axios.post('website', { ...body })
}

export const editWebsite = (body: IWebsite): AxiosPromise => {
  const websiteBody = {
    slug: body.slug,
    domain: body.domain,
    title: body.title,
    theme_id: body.theme_id,
    languages: body.languages,
    email: body.email,
    facebook_page_id_for_chat: body.facebookPageIdForChat,
    default_language: body.default_language,
  }
  return axios.put('website/' + body.id, websiteBody)
}

export const getConfigAuth = (): AxiosPromise => {
  return axios.get('config/auth')
}
export const deleteWebsite = (id: string): AxiosPromise => {
  return axios.delete('website/' + id)
}

export const getWebsite = (id: string): AxiosPromise<IWebsite> => {
  return axios.get<IWebsite>('website/' + id)
}

export const getWebsiteByOrganization = (organizationId: string): AxiosPromise<IWebsite> => {
  return axios.get<IWebsite>('organization/' + organizationId + '/website')
}

const convertToApiBody = (body: IPageItem): IApiPageItem => {
  return {
    ...body,
    sections: body.sections.map((section) => {
      if (section.name === 'inline') {
        const data: IInline = section.data as IInline
        return {
          ...section,
          data: data.sections,
          font_color: data.backgroundFontColor,
          background_image: data.backgroundImage,
          contrast: data.backgroundContrast,
        }
      }

      return section as IApiSection
    }),
  }
}

export const createPage = (body: IPageItem): AxiosPromise => {
  return axios.post('page', convertToApiBody(body))
}

export const editPage = (body: IPageItem): AxiosPromise => {
  return axios.put('page/' + body.id, convertToApiBody(body))
}

export const deletePage = (id: string): AxiosPromise => {
  return axios.delete('page/' + id)
}

export const getPage = (id: string): AxiosPromise<IPageItem> => {
  return axios.get('page/' + id)
}

export const getPageByWebsite = (id: string): Promise<AxiosResponse<Array<IPageItem>>> => {
  return axios.get<Array<IApiPageItem>>('website/' + id + '/page').then((response) => {
    const newResponse = {
      ...response,
      data: response.data.map((pageItem: IApiPageItem): IPageItem => {
        const newPageItem = {
          ...pageItem,
          sections: pageItem.sections.map((section): ISection => {
            if (section.name === 'inline') {
              return {
                ...section,
                data: {
                  sections: section.data,
                  backgroundImage: section.background_image,
                  backgroundContrast: section.contrast,
                  backgroundFontColor: section.font_color,
                },
              } as ISection
            }
            return section as ISection
          }),
        }

        return newPageItem
      }),
    }

    return newResponse
  })
}

export const getTheme = (): AxiosPromise<Array<ITheme>> => {
  return axios.get<Array<ITheme>>('theme')
}

export const getConfig = (): AxiosPromise<IConfig> => {
  return axios.get<IConfig>('config')
}

export const getConfiguration = (section: string): AxiosPromise<IConfigData> => {
  return axios.get('section/' + section + '/configuration')
}

export const getGallery = (asset_type: string): AxiosPromise => {
  return axios.get('section/gallery/configuration?asset_type=' + asset_type)
}

export const addFile = (asset_type: string, file: Blob): AxiosPromise<{ data: { id: number } }> => {
  const bodyFormData = new FormData()
  bodyFormData.set('asset_type', asset_type)
  bodyFormData.append('file', file)
  return axios({
    method: 'post',
    url: 'asset',
    data: bodyFormData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const deletePhoto = (imageId: number) => {
  return clientAxios.delete(`/files/${imageId}`).catch((error) => {
    console.error('Error deleting photo:', error)
  })
}

export const getFeedback = (establishmentId: string): AxiosPromise => {
  return clientAxios.get(`/anonymous/customers/feedback/${establishmentId}`, {
    headers: {
      common: {
        Authentication: false,
      },
    },
  })
}

export const deployWebsite = (websiteId: string): AxiosPromise => {
  return axios.post(`website/${websiteId}/build`)
}

type TimeData = {
  date: string
  timezone: string
  timezone_type: number
}

type EventData = {
  message: {
    status: number
    created: TimeData
    updated: TimeData
    website: string
  }
}
export const deployEvent = (websiteId: string, eventId: string): AxiosPromise<EventData> => {
  return axios.get(`website/${websiteId}/event/${eventId}`)
}

export const checkIfValidEmail = (email: string): AxiosPromise<{ message: string; valid: boolean }> => {
  return clientAxios.get(`email/validate/${email}`)
}

export const getClientMenus = (): AxiosPromise => {
  return clientAxios.get(`/anonymous/activity-menu`)
}

export const getClientActivities = (): AxiosPromise => {
  return clientAxios.get(`/anonymous/calendar/activity-tree`)
}
