import React, { FunctionComponent, useEffect, useState } from 'react'
import { Wrapper, Button, LinkToBack, IconBack, Title, SelectFieldWrapper, SelectFieldLabel } from './styles'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import InputField from 'shared/InputField'
import { useForm } from 'react-hook-form'
import { IConfig } from 'store/config/types'
import { IWebsite } from 'store/website/types'
import { ITheme } from 'store/theme/types'
import { ApplicationState, RootState } from 'store/index'
import { addPages, setCurrentPage } from 'store/page/actions'
import { addWebsite, updateCurrentLanguage } from 'store/website/actions'
import { connect, useSelector } from 'react-redux'
import {
  createWebsite,
  editWebsite,
  getConfigAuth,
  getPageByWebsite,
  getWebsite,
  getWebsiteByOrganization,
} from 'services/website-service'
import { errorToast, successToast } from 'services/toasts'

type Item = {
  label: string
  value: string
}

type Props = {
  config: IConfig
  website: IWebsite
  themes: ITheme[]
  setThemeValue: (theme: string) => void
}

type FormValues = {
  slug: string
  title: string
  languages: { [key: string]: boolean }
  theme_id: string
}
type AllProps = Props & typeof mapDispatch

export const WebsiteSettings: FunctionComponent<AllProps> = (props: AllProps) => {
  const { config, website, setThemeValue } = props
  const [themeList] = useState(website.theme_id)
  const [defaultLanguage, setDefaultLanguage] = useState<string | undefined>()
  const [languages, setLanguages] = useState<Item[]>([])

  const { register, handleSubmit, errors, setValue, getValues, watch } = useForm<FormValues>()
  const { locale, theme } = useSelector<RootState, { locale: string; theme: ITheme[] }>((state) => ({
    locale: state.config.locale,
    theme: state.theme,
  }))

  const [defaultThemeId, setDefaultThemeId] = useState<string>('')
  const [themeId, setThemeId] = useState<string>('')
  useEffect(() => {
    if (theme) {
      const themeId = theme.find((x) => x.title === 'Light')
      if (themeId) {
        setDefaultThemeId(themeId.id)
        localStorage.setItem('theme-ui-color-mode', 'Light')
      }
    }
  }, [theme])

  const { t } = useTranslation()
  const history = useHistory()

  const regexp = /^[a-z0-9-]+$/

  useEffect(() => {
    const languages = Object.keys(config.languages).map((key) => ({
      label: config.languages[key],
      value: key,
    }))
    setLanguages(languages)
  }, [config])

  useEffect(() => {
    if (website.id) {
      setDefaultLanguage(website.default_language)
      setValue('slug', website.slug)
      setValue('title', website.title)
      setValue('theme_id', website.theme_id)
      website.languages.forEach((lan) => {
        setValue('languages.' + lan, lan)
      })
    }
  }, [themeList, languages, setValue, website])

  const onSelect = (value: string) => {
    setValue('theme_id', value)
    setThemeId(value)
    const findSelectedTheme = theme.find((x) => x.id === value) || 'undefined'
    if (findSelectedTheme) {
      // @ts-ignore
      if ('title' in findSelectedTheme) {
        localStorage.setItem('theme-ui-color-mode', findSelectedTheme.title)
        setThemeValue(findSelectedTheme.title)
        setThemeId(findSelectedTheme.id)
      }
    }
  }

  watch('languages')

  const onSelectDefaultLanguage = (value: string) => {
    const availableLanguages = getValues().languages || {}
    if (true === availableLanguages[value]) {
      setDefaultLanguage(value)
    }
  }

  const transformedThemeData: Item[] = theme.map((item) => {
    return {
      label: item.title,
      value: item.id,
    }
  })

  const onSave = (data: FormValues) => {
    const languages = Object.keys(data.languages).reduce((state: string[], language) => {
      if (data.languages[language]) {
        state.push(language)
      }
      return state
    }, []) as string[]

    delete data.languages
    if (website.id) {
      const newForm = {
        ...data,
        id: website.id,
        default_language: defaultLanguage,
        languages,
        theme_id: themeId ? themeId : defaultThemeId,
        domain: website.domain,
      } as IWebsite

      editWebsite(newForm).then(
        () => {
          if (website.id) {
            getWebsite(website.id).then(({ data }) => {
              props.addWebsite(data)
            })
          }
          successToast()
        },
        ({ response }) => {
          errorToast(response?.data?.message)
        },
      )
    } else {
      const newForm = { ...data, languages, default_language: defaultLanguage } as IWebsite

      createWebsite(newForm).then(
        () => {
          getConfigAuth().then(({ data }) => {
            getWebsiteByOrganization(data.organization_uuid as string).then(({ data: website }) => {
              const lang =
                navigator.languages[1] && website.languages.includes(navigator.languages[1])
                  ? navigator.languages[1]
                  : website.languages[0]
              updateCurrentLanguage(lang)
              addWebsite(website)
              getPageByWebsite(website.id as string).then(({ data }) => {
                addPages(data)
                const currentPage = data.find((item) => item.locale === lang)
                if (currentPage || (data && data.length)) {
                  const newCurrentPage = currentPage || data[0]
                  setCurrentPage(newCurrentPage)
                  history.push('/update')
                }
              })
            })
          })
          successToast()
        },
        ({ response }) => {
          errorToast(response?.data?.message)
        },
      )
    }
  }

  // @ts-ignore

  return (
    <Wrapper>
      <LinkToBack to="/update">
        <>
          <IconBack>
            <FontAwesomeIcon icon={'arrow-left'} />
          </IconBack>
          {t('page:back', { lng: locale })}
        </>
      </LinkToBack>
      <Title>{t('website_form:form_title', { lng: locale })}</Title>
      <form
        onSubmit={handleSubmit((data) => {
          onSave(data)
        })}
      >
        <InputField
          name="title"
          registerRef={register({
            required: t('This field is required', { lng: locale }).toString(),
          })}
          label={t('website_form:title', { lng: locale })}
          error={errors?.title?.message}
        />
        <InputField
          name={'slug'}
          registerRef={register({
            required: t('This field is required', { lng: locale }).toString(),
            pattern: {
              value: RegExp(regexp),
              message: t('Please provide a lowercase slug', { lng: locale }),
            },
          })}
          error={errors?.slug?.message}
          label={t('website_form:slug', { lng: locale })}
        />
        <SelectFieldWrapper>
          <SelectFieldLabel htmlFor="themes">{t('Theme')}</SelectFieldLabel>
          <select
            className="select-field"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSelect(e.currentTarget.value)}
          >
            {transformedThemeData.map((data) => (
              <>
                <option id={data.value} value={data.value}>
                  {data.label}
                </option>
              </>
            ))}
          </select>
        </SelectFieldWrapper>
        <p>{t('website_form:languages', { lng: locale })}</p>
        {languages.map((language) => (
          <InputField
            key={language.value}
            name={'languages.' + language.value}
            type="checkbox"
            registerRef={register}
            label={t('languages:' + language.value, { lng: locale })}
            disabled={language.value === defaultLanguage}
          />
        ))}
        <SelectFieldWrapper>
          <SelectFieldLabel htmlFor="themes">{t('Default language')}</SelectFieldLabel>
          <select
            className="select-field"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSelectDefaultLanguage(e.currentTarget.value)}
          >
            {languages
              .filter((language) => {
                const selectedLanguages = getValues().languages
                if (selectedLanguages) {
                  return selectedLanguages[language.value] === true
                }

                return false
              })
              .map((language) => (
                <option id={language.value} value={language.value} key={`defaultLanguage${language.value}`}>
                  {language.label}
                </option>
              ))}
          </select>
        </SelectFieldWrapper>
        <Button type="submit">{t('page:submit', { lng: locale })}</Button>
      </form>
    </Wrapper>
  )
}

const mapState = (state: ApplicationState) => ({
  config: state.config,
  website: state.website,
  themes: state.theme,
})

const mapDispatch = {
  setCurrentPage,
  addPages,
  addWebsite,
  updateCurrentLanguage,
}

export default connect(mapState, mapDispatch)(WebsiteSettings)
