import styled from '@emotion/styled'
import ReactSlider from 'react-slick'

export const Wrapper = styled.div`
  padding: 30px 0px;
`
export const Slider = styled(ReactSlider)``
export const ArrowButton = styled.button`
  top: 0;
  color: #fff;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
  webkit-transition: all 0.25s ease-in;
  -moz-transition: all 0.25s ease-in;
  -ms-transition: all 0.25s ease-in;
  -o-transition: all 0.25s ease-in;
  transition: all 0.25s ease-in;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  border: 0;
  width: 30px;
  font-size: 32px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
  height: none;
  transform: none;
  height: unset;
  &:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: '';
  }
  &:hover {
    background: rgba(0, 0, 0, 0.4);
    &:before {
      opacity: 0.8;
    }
  }
`

export const PrevArrowButton = styled(ArrowButton)`
  left: 0;
  &:before {
    border-right: 8px solid #fff;
  }
`

export const NextArrowButton = styled(ArrowButton)`
  right: 0;
  &:before {
    border-left: 8px solid #fff;
  }
`
