import React, { FC, useState } from 'react'

import { IMap } from '../../types/sections'
import { InputSearch, Container, StyledMaps } from './style'

import Geocode from 'react-geocode'

type Props = {
  data: IMap
  updateAddress?: (address: string) => void
  onlyMarker?: boolean
  position?: number
}

const KEY_MAP = 'AIzaSyBXVzvBuZ1xx9PWS-HSsXJBjkd8I0WjGxg'
const isSafari = typeof navigator === 'undefined' ? false : /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const Map: FC<Props> = (props: Props) => {
  const [mapAddress, setAddress] = useState(props.data.address)

  const { updateAddress, onlyMarker = false, position, data } = props

  const defaultProps = {
    center: {
      lat: 50.826,
      lng: 5.2551,
    },
    zoom: 6,
  }

  const googleFuncMarker = (map, maps, address?: string): void => {
    const currentMarker: google.maps.Marker = new maps.Marker({
      map: map,
      draggable: false,
    })

    const infoWindow: google.maps.InfoWindow = new maps.InfoWindow()
    const geocoder = new maps.Geocoder()

    const input: HTMLInputElement = document.getElementById('searchTextField' + position) as HTMLInputElement

    const geocodeLatLng = (options: { location?: google.maps.LatLng; address?: string }): void => {
      try {
        geocoder.geocode(options, (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
          if (status === 'OK') {
            if (results[0]) {
              infoWindow.setContent(results[0].formatted_address)
              input && (input.value = results[0].formatted_address)

              // Focus of the pin opening, makes browser window scroll
              isSafari || infoWindow.open(map, currentMarker)

              setAddress(results[0].formatted_address)
              currentMarker.setPosition(options.location || results[0].geometry.location)
            } else {
              console.log('No results found')
            }
          } else {
            console.log('Geocoder failed due to: ' + status)
          }
        })
      } catch (e) {
      }
    }

    if (address) {
      geocodeLatLng({ address: address })
    }

    const addMarkerByClick = (map, maps): void => {
      maps.event.addListener(map, 'click', (event: google.maps.MouseEvent) => {

        Geocode.setApiKey(KEY_MAP)

        const latlng = new maps.LatLng(event.latLng.lat(), event.latLng.lng())

        let clickLat = Object.values(event.latLng.toJSON())[0]

        let clickLng = Object.values(event.latLng.toJSON())[1]

        Geocode.fromLatLng(clickLat, clickLng).then(
          (response) => {
            const extractedAddress = response.results[0].formatted_address
            updateAddress && updateAddress(extractedAddress)
          },
          (error) => {
            console.error(error)
          },
        )

        currentMarker.setPosition(latlng)
        map.setZoom(16)
        geocodeLatLng({ location: latlng })

        maps.event.addListener(currentMarker, 'dragend', (dragEvent: google.maps.MouseEvent) => {

          Geocode.setApiKey(KEY_MAP)

          const latlng = new maps.LatLng(dragEvent.latLng.lat(), dragEvent.latLng.lng())

          let dragLat = Object.values(dragEvent.latLng.toJSON())[0]

          let dragLng = Object.values(dragEvent.latLng.toJSON())[1]

          Geocode.fromLatLng(dragLat, dragLng).then(
            (response) => {
              const extractedAddress = response.results[0].formatted_address
              updateAddress && updateAddress(extractedAddress)
            },
            (error) => {
              console.error(error)
            },
          )

          geocodeLatLng({ location: latlng })
          updateAddress && updateAddress(mapAddress)
        })
      })
    }

    const renderAutocomplete = (map: any, maps: any): void => {
      const defaultBounds = maps.LatLngBounds(maps.LatLng(-33.8902, 151.1759), maps.LatLng(-33.8474, 151.2631))

      const options = {
        bounds: defaultBounds,
        types: ['geocode'],
      }
      const autocomplete = new maps.places.Autocomplete(input, options)

      maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace()

        currentMarker.setPosition(place.geometry.location)
        map.setZoom(16)

        infoWindow.setContent(place.formatted_address)
        updateAddress && updateAddress(place.formatted_address)
        infoWindow.open(map, currentMarker)
        maps.event.addListener(currentMarker, 'dragend', (dragEvent: google.maps.MouseEvent) => {
          const latlng = new maps.LatLng(dragEvent.latLng.lat(), dragEvent.latLng.lng())
          geocodeLatLng({ location: latlng })
          updateAddress && updateAddress(mapAddress)
        })
        map.setCenter(currentMarker.getPosition())
      })
    }

    if (!onlyMarker) {
      currentMarker.setOptions({ draggable: true })
      renderAutocomplete(map, maps)
      addMarkerByClick(map, maps)
    }
  }

  return (
    <Container style={{ overflowAnchor: 'none' }} tabIndex={-1}>
      {!onlyMarker && (
        <InputSearch
          id={'searchTextField' + position}
          type="text"
          size={35}
          placeholder="Enter your address!"
          tabIndex={-1}
        />
      )}
      <StyledMaps
        yesIWantToUseGoogleMapApiInternals={true}
        bootstrapURLKeys={{ key: KEY_MAP, libraries: 'places' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => {
          googleFuncMarker(map, maps, data.address)
        }}
      />
    </Container>
  )
}

export default Map
