import React, { ReactNode, FC } from 'react'
import { useScreenClass } from 'react-grid-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CloseButton, Title, ReactModal, DialogContent } from './style'

type ModalProps = {
  className?: string
  isOpen: boolean
  title: string
  children: ReactNode
  onSubmit?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onRequestClose: (event: React.MouseEvent<HTMLElement>) => void
  minWidth?: string
}

const customStyles = {
  overlay: {
    zIndex: 999,
    background: 'rgba(0,0,0,0.5)',
  },
}

const Modal: FC<ModalProps> = (props: ModalProps): JSX.Element => {
  const { isOpen, children, onRequestClose, title, className } = props
  const screenClass = useScreenClass()

  return (
    <ReactModal
      className={className + ' ' + screenClass}
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={onRequestClose}
      minWidth={props.minWidth}
    >
      <DialogContent>
        <CloseButton onClick={onRequestClose}>
          <FontAwesomeIcon icon={'times'} />
        </CloseButton>
        <Title>{title}</Title>
        {children}
      </DialogContent>
    </ReactModal>
  )
}

export default Modal
