import {
  ADD_CONFIG,
  ADD_CONFIG_SLUG,
  ConfigActionTypes,
  GET_EXTRA_ACTIVITIES,
  GET_EXTRA_ACTIVITIES_FAILURE,
  GET_EXTRA_ACTIVITIES_SUCCESS,
  GET_MENU_CATEGORIES,
  GET_MENU_CATEGORIES_FAILURE,
  GET_MENU_CATEGORIES_SUCCESS,
  IConfig,
} from './types'
import { initialState } from './initialState'

export const configReducer = (state = initialState, action: ConfigActionTypes): IConfig => {
  switch (action.type) {
    case ADD_CONFIG:
      return { ...state, ...action.payload }
    case ADD_CONFIG_SLUG:
      return { ...state, data: { ...state.data, [action.payload.name]: action.payload.section } }
    case GET_EXTRA_ACTIVITIES_SUCCESS:
      return { ...state, isLoading: false, data: { ...state.data, [action.payload.name]: action.payload.activity } }
    case GET_EXTRA_ACTIVITIES_FAILURE:
      return { ...state, isLoading: false }
    case GET_EXTRA_ACTIVITIES:
      return { ...state, isLoading: true }
    case GET_MENU_CATEGORIES_SUCCESS:
      return { ...state, data: { ...state.data, [action.payload.name]: action.payload.menu } }
    case GET_MENU_CATEGORIES_FAILURE:
      return { ...state, isLoading: false }
    case GET_MENU_CATEGORIES:
      return { ...state, isLoading: true }
    default:
      return state
  }
}
