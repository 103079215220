import styled from '@emotion/styled'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const TileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  padding: 1.375em 2.2em 0 5em;
  cursor: pointer;
  color: #2164db;
`

export const TileGraphic = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  position: absolute;
  left: 0;
  top: 0;
  height: 2.5em;
  width: 2.5em;
  text-align: center;
`

export const TileTextContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  padding: 0.8em 0.5em 0.5em;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3) 50%);
`

export const TileText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
`

export const NextIcon = styled(FontAwesomeIcon)``
