import styled from '@optios-frontend-website/shared-ui/src/theme'
import { Link } from 'react-router-dom'

export const Title = styled.h3`
  margin-bottom: 1em;
`
export const Wrapper = styled.div`
  margin: 50px auto;
  width: 30em;
`
export const Button = styled.button`
  font: inherit;
  background-color: ${(props) => props.theme.colors.submit};
  color: ${({ theme }) => theme.colors.text};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0 1.5em;
  line-height: 2.75em;
  min-width: 9em;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.025em;
  outline: none;
  margin: 0;
  margin-top: 10px;
  float: right;
  :hover {
    background-color: ${(props) => props.theme.colors.submit_hover};
  }
  &.xs {
    width: 100%;
  }
`
export const IconBack = styled.i`
  margin-right: 1em;
`

export const LinkToBack = styled(Link)`
  text-decoration: none;
  margin-bottom: 2em;
  display: inline-block;
  color: ${(props) => props.theme.colors.link};
`

export const SelectFieldWrapper = styled.form`
  display: flex;
  cursor: pointer;
  margin: 0 0 0.7em;
  min-height: 1.6em;
  position: relative;
`

export const SelectFieldLabel = styled.label`
  display: block;
  padding: 0.2em 0.5em 0.2em 0;
  -webkit-flex: 0 0 30%;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  line-height: 1.4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
