import React, { useState, FC, useEffect } from 'react'

import { IFileDataConfig } from 'store/config/types'
import SharedHeader from '@optios-frontend-website/shared-ui/src/components/Header'
import ModalLogo from './components/ModalLogo'
import ModalHeader from './components/ModalHeader'
import { addFile, getGallery } from 'services/website-service'
import { WEBSITE_BANNER, WEBSITE_LOGO, SectionNames } from '../../constants'
import { IHeader, PageSectionTypes } from 'store/page/types'
import SectionControl from 'shared/SectionControl'
import { ITheme } from 'store/theme/types'

type Props = {
  prefixUrl: string
  logo: IFileDataConfig | null
  banner: IFileDataConfig | null
  data: IHeader
  updateSection: (name: string, section: PageSectionTypes, position: number) => void
  position: number
  bookingId: string | undefined
  addConfigBySlug: (name: string, section: IFileDataConfig) => void
  theme: ITheme
  logoIsOpen: boolean
  setLogoOpen: (logoIsOpen: boolean) => void
}

const Header: FC<Props> = (props: Props) => {
  const { data, prefixUrl, updateSection, position, addConfigBySlug } = props
  const [header, setHeader] = useState<IHeader>(data)
  const [headerIsOpen, setHeaderOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('')
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [errorMessage])
  const addWebsiteFile = (assetType: typeof WEBSITE_LOGO | typeof WEBSITE_BANNER, file: File) => {
    addFile(assetType, file).then(
      (response) => {
        if (assetType === WEBSITE_LOGO) {
          onSubmitHeader({ ...header, logo: response.data.data.id.toString() })
        } else if (assetType === WEBSITE_BANNER) {
          getGallery(WEBSITE_BANNER).then(({ data }) => {
            addConfigBySlug('banner', data as IFileDataConfig)
          })
        }
      },
      (error) => {
        if (error.message === 'Network Error') {
          setErrorMessage('Uploaded file is too big. Please select another one')
        } else if (error.response) {
          console.log('HTTP error with status code:', error.response.status)
        } else {
          console.log('Unknown error occurred:', error.message)
        }
      },
    )
  }

  const onSubmitHeader = (data: IHeader) => {
    const newHeader = { ...header, ...data }
    setHeader(newHeader)
    updateSection(SectionNames.HEADER, newHeader, position)
    setHeaderOpen(false)
    props.setLogoOpen(false)
  }

  return (
    <>
      <SectionControl position={position} isDelete={false}>
        <SharedHeader
          data={props.data}
          headerSrc={`${props.prefixUrl}/?id=${data.background_image}`}
          logoSrc={data.logo && `${props.prefixUrl}/?id=${data.logo}`}
          handleHeaderClick={() => setHeaderOpen(true)}
          handleLogoClick={(event) => {
            event.stopPropagation()
            props.setLogoOpen(true)
          }}
          bookingId={props.bookingId}
          theme={props.theme}
          isVideo={!!data.is_video}
        />
      </SectionControl>

      <ModalHeader
        addWebsiteFile={addWebsiteFile}
        prefixUrl={prefixUrl}
        bannerFiles={props.banner ? props.banner?.files : []}
        currentFile={data.background_image}
        data={data}
        open={headerIsOpen}
        setIsOpen={setHeaderOpen}
        onSubmit={onSubmitHeader}
        updateSection={updateSection}
        position={position}
        errorMessage={errorMessage}
      />
      <ModalLogo
        data={data}
        addWebsiteFile={addWebsiteFile}
        onSubmit={onSubmitHeader}
        open={props.logoIsOpen}
        setIsOpen={props.setLogoOpen}
      />
    </>
  )
}

export default Header
