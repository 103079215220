export const colorSwatches = [
  {
    id: '1',
    name: 'Black',
    hex: '#000000',
  },
  {
    id: '2',
    name: 'Lighter black',
    hex: '#080808',
  },
  {
    id: '3',
    name: 'Dark grey',
    hex: '#bdbdbd',
  },
  {
    id: '4',
    name: 'Grey',
    hex: '#d2d2d2',
  },
  {
    id: '5',
    name: 'Light grey',
    hex: '#f2f2f2',
  },
  {
    id: '6',
    name: 'White',
    hex: '#ffffff',
  },
]
