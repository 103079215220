import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const ErrorMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5c77d;
  border-radius: 20px;
  margin: 12px 0px;
`

export const ErrorMessageIcon = styled(FontAwesomeIcon)`
  color: #f79900;
`

export const ErrorMessageText = styled.p`
  color: #fff;
  padding: 12px;
`
