import React, { FunctionComponent, useMemo, useState } from 'react'

import { Tile } from '../Tile/Tile'

import { IProps } from './IProps'
import { getNextCategories } from './utils/getNextCategories'
import { IFinalSelection } from 'components/PricingCrud/components/ActivitySelect/interface/IActivitySelect'
import { stringToHsl } from '../../../../../../utils/stringToHsl'
import { addOrRemoveCategory } from 'services/establishment-activity-call/establishment-activity-call'

export const MenuSelect: FunctionComponent<IProps> = ({
  menuList,
  setActivitySelectObject,
  activitySelectObject,
}) => {
  const [selectedPath, setSelectedPath] = useState<string>('')
  const { activeSelection } = activitySelectObject

  const selectionCheck = activeSelection ? activeSelection : []
  const nextCategorySelection = useMemo(() => {
    return getNextCategories(activeSelection || [], menuList)
  }, [activeSelection, menuList])

  const addSelectionId = (newItem: number | IFinalSelection[]) => {
    if (typeof newItem !== 'number') {
      addOrRemoveCategory(newItem[0].uuid, true)
        .then((response) => {
          console.log('Category added successfully', response)
        })
        .catch((error) => {
          console.error('Error adding category', error)
        })
    }

    if (typeof newItem === 'number') {
      setSelectedPath(selectedPath + '/' + newItem)
      setActivitySelectObject({
        type: 'menu',
        activeSelection: [...selectionCheck, newItem],
        selectionDetails: null,
      })
      return
    }
    setActivitySelectObject({
      type: 'selected',
      activeSelection,
      selectionDetails: newItem,
    })
    return
  }

  return (
    <>
      {nextCategorySelection.map((item) => {
        const clickedTileInfo: number | IFinalSelection[] =
          typeof item.activities !== 'undefined'
            ? item.activities.map((activity) => ({
                uuid: activity.uuid,
              selectionDetails: {
                  name: activity.name,
                  price: typeof activity.price === 'number' ? `${activity.price}` : '0',
                  id: activity.id.toString(),
                  path: selectedPath,
                  category: item.name,
                },
              }))
            : item.id
        return (
          <Tile
            key={item.id}
            tileColor={stringToHsl(item.name, 50, 50)}
            tileTitle={item.name}
            onClick={() => {
              addSelectionId(clickedTileInfo)
            }}
          />
        )
      })}
    </>
  )
}
