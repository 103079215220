import React, { FC, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { SectionNames } from '@optios-frontend-website/shared-ui/src/constants'
import Contacts from 'components/Contacts'
import ContactForm from 'components/ContactForm'
import CustomHTML from 'components/CustomHTML'
import {
  IInlineSections,
  PageSectionTypes,
  IHTML,
  IContact,
  IPhoto,
  IInline,
  IEmployeeCard,
  ILinkButton,
} from 'store/page/types'
import { IWebsite } from 'store/website/types'
import {
  Content,
  SaveIcon,
  RemoveIcon,
  BackgroundLayer,
  BackgroundLayerWrapper,
  InlineContainer,
} from './style'
import SectionControl from 'shared/SectionControl'
import SelectField from 'shared/SelectField'
import { Item } from 'shared/SelectField/style'
import Photo from 'components/Photo'
import { IConfig } from 'store/config/types'
import { SectionBackground } from 'shared/SectionBackground'
import { RenderEmployee } from 'shared/Employee/RenderEmployee/RenderEmployee'
import { ButtonsContainer } from 'shared/ButtonsContainer'

type Item = {
  label: string
  value: SectionNames
}

const list: Item[] = [
  { label: 'Text', value: SectionNames.HTML },
  { label: 'Contact', value: SectionNames.CONTACT },
  { label: 'Form', value: SectionNames.FORM },
  { label: 'Photo', value: SectionNames.PHOTO },
  { label: 'Employee', value: SectionNames.EMPLOYEE },
  { label: 'Button', value: SectionNames.BUTTON },
]

type Props = {
  website: IWebsite
  position: number
  data: IInline
  updateSection: (name: string, section: PageSectionTypes, position: number) => void
  addInlineSection: (position: number, name: SectionNames) => void
  config: IConfig
  fontColor: string
  setFontColor: (fontColor: string) => void
  sliderValue: number
  setSliderValue: (sliderValue: number) => void
  imageUrl: string
  setImageUrl: (imageUrl: string) => void
  prefixUrl: string
}

const colData: {
  [key: number]: {
    [key: number]: {
      xs: number
      sm: number
      md: number
      lg: number
      xl: number
      xxl: number
    }
  }
} = {
  1: { 0: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12, xxl: 12 } },
  2: { 0: { xs: 12, sm: 12, md: 12, lg: 6, xl: 6, xxl: 6 }, 1: { xs: 12, sm: 12, md: 12, lg: 6, xl: 6, xxl: 6 } },
  3: {
    0: { xs: 12, sm: 12, md: 6, lg: 4, xl: 4, xxl: 4 },
    1: { xs: 12, sm: 12, md: 6, lg: 4, xl: 4, xxl: 4 },
    2: { xs: 12, sm: 12, md: 12, lg: 4, xl: 4, xxl: 4 },
  },
}

const DynamicContainer: FC<Props> = (props: Props) => {
  const { website, position, data, updateSection, prefixUrl } = props

  const [open, setOpen] = useState(false)
  const [count, setCount] = useState(data.sections.length)
  const [selectedItem, setItem] = useState<Item | null>(null)
  const [isToolbarVisible, setIsToolbarVisible] = useState<boolean>(false)

  const { t } = useTranslation()

  const htmlSections = props.data.sections.filter((section) => section.name === 'html')
  const renderInlineSection = (section: IInlineSections, col: number) => {
    switch (section.name) {
      case SectionNames.HTML:
        return (
          <CustomHTML
            data={section.data as IHTML}
            position={position}
            col={col}
            updateSection={updateSection}
            key={`customhtml${props.position}_${position}`}
            fontColor={props.fontColor}
          />
        )
      case SectionNames.CONTACT:
        return <Contacts data={section.data as IContact} position={position} col={col} updateSection={updateSection} />
      case SectionNames.FORM:
        return <ContactForm website={website as IWebsite} position={position} col={col} updateSection={updateSection} />
      case SectionNames.PHOTO:
        return (
          <Photo
            position={position}
            updateSection={updateSection}
            data={section.data as IPhoto}
            config={props.config}
            col={col}
          />
        )
      case SectionNames.EMPLOYEE:
        return (
          <RenderEmployee
            data={section.data as IEmployeeCard}
            position={position}
            col={col}
            updateSection={updateSection}
          />
        )
      case SectionNames.BUTTON:
        return (
          <ButtonsContainer
            data={section.data as ILinkButton}
            updateSection={updateSection}
            position={position}
            col={col}
          />
        )
    }
  }
  const addInlineSection = () => {
    if (selectedItem) {
      props.addInlineSection(props.position, selectedItem.value)
      setOpen(false)
    }
  }

  const handleSelect = (name: string, value: string) => {
    setItem({ label: name, value: value as SectionNames })
  }

  return (
    <SectionControl
      position={props.position}
      isDelete={false}
      data={data}
      open={open}
      setAddOpen={setOpen}
      setCount={setCount}
    >
      <InlineContainer>
        {/* Display the upload image only if there is text section */}
        {htmlSections.length === props.data.sections.length ? (
          <>
            <SectionBackground
              fontColor={props.fontColor}
              setFontColor={props.setFontColor}
              sliderValue={props.sliderValue}
              setSliderValue={props.setSliderValue}
              setImageUrl={props.setImageUrl}
              updateSection={props.updateSection}
              position={props.position}
              data={props.data}
              setIsToolbarVisible={setIsToolbarVisible}
              isToolbarVisible={isToolbarVisible}
              prefixUrl={prefixUrl}
            />

            <BackgroundLayerWrapper id="wrapper-background" image={props.imageUrl}>
              <BackgroundLayer sliderValue={props.sliderValue} />
              <Row>
                {props.data.sections.map((section, index) => {
                  return (
                    <Col
                      key={uuidv4()}
                      xxl={colData[open ? count : data.sections.length][index].xxl}
                      xl={colData[open ? count : data.sections.length][index].xl}
                      lg={colData[open ? count : data.sections.length][index].lg}
                      md={colData[open ? count : data.sections.length][index].md}
                      sm={colData[open ? count : data.sections.length][index].sm}
                      xs={colData[open ? count : data.sections.length][index].xs}
                    >
                      <SectionControl
                        position={props.position}
                        isAdd={false}
                        col={index}
                        data={data}
                        open={open}
                        setAddOpen={setOpen}
                        setCount={setCount}
                      >
                        {renderInlineSection(section, index)}
                      </SectionControl>
                    </Col>
                  )
                })}
                {open && (
                  <Col>
                    <Content>
                      <SelectField
                        list={list}
                        name="theme_id"
                        label={t('page:add_new_section')}
                        handleSelect={handleSelect}
                      />
                      <SaveIcon onClick={addInlineSection}>
                        <FontAwesomeIcon icon={'floppy-disk'} />
                      </SaveIcon>
                      <RemoveIcon
                        onClick={() => {
                          setCount(data.sections.length - 1)
                          setOpen(false)
                        }}
                      >
                        <FontAwesomeIcon icon={'times'} />
                      </RemoveIcon>
                    </Content>
                  </Col>
                )}
              </Row>
            </BackgroundLayerWrapper>
          </>
        ) : (
          <>
            <Row>
              {props.data.sections.map((section, index) => {
                return (
                  <Col
                    key={uuidv4()}
                    xxl={colData[open ? count : data.sections.length][index].xxl}
                    xl={colData[open ? count : data.sections.length][index].xl}
                    lg={colData[open ? count : data.sections.length][index].lg}
                    md={colData[open ? count : data.sections.length][index].md}
                    sm={colData[open ? count : data.sections.length][index].sm}
                    xs={colData[open ? count : data.sections.length][index].xs}
                  >
                    <SectionControl
                      position={props.position}
                      isAdd={false}
                      col={index}
                      data={data}
                      open={open}
                      setAddOpen={setOpen}
                      setCount={setCount}
                    >
                      {renderInlineSection(section, index)}
                    </SectionControl>
                  </Col>
                )
              })}
              {open && (
                <Col>
                  <Content>
                    <SelectField
                      list={list}
                      name="theme_id"
                      label={t('page:add_new_section')}
                      handleSelect={handleSelect}
                    />
                    <SaveIcon onClick={addInlineSection}>
                      <FontAwesomeIcon icon={'floppy-disk'} />
                    </SaveIcon>
                    <RemoveIcon
                      onClick={() => {
                        setCount(data.sections.length - 1)
                        setOpen(false)
                      }}
                    >
                      <FontAwesomeIcon icon={'times'} />
                    </RemoveIcon>
                  </Content>
                </Col>
              )}
            </Row>
          </>
        )}
      </InlineContainer>
    </SectionControl>
  )
}

export default DynamicContainer
