import React, { FC, FunctionComponent, useState } from 'react'
import { Row, useScreenClass } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { Regexp } from '../../constants'
import { Section, Title, Input, Textarea, Col, Button, Error, DivButton, InputWrapper } from './style'
import { toast } from 'react-toastify'

type FormValue = {
  name: string
  email: string
  message: string
}

type FormData = {
  name: string
  email: string
  message: string
  'g-recaptcha-response': string
}

type IProps = {
  websiteId: string | undefined
  endPoint: string | undefined
}

const ContactForm: FunctionComponent<IProps> = (props) => {
  const { websiteId, endPoint } = props
  const { t } = useTranslation()
  const [isSubmit, setSubmit] = useState(false)
  const [errors, setErrors] = useState<FormValue>({} as FormValue)
  const [form, setForm] = useState({
    name: '',
    email: '',
    message: '',
  })
  const [ReCaptchaValue] = useState('')

  const sendForm = (data: FormData) => {
    if (websiteId && endPoint) {
      const xhr = new XMLHttpRequest()
      xhr.open('POST', endPoint + 'public/website/' + websiteId + '/form', true)
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(JSON.stringify(data))
      xhr.onload = function () {
        toast.success('Your feedback was sent!')
      }
      xhr.onerror = function () {
        toast.error('Something is wrong! Try late')
      }
    }
  }

  const validForm = (form: FormValue) => {
    Object.keys(form).forEach((name) => {
      switch (name) {
        case 'name': {
          errors.name = form.name.length ? '' : t('error:name_is_required')
          break
        }
        case 'email': {
          errors.email = RegExp(Regexp.EMAIL).test(form.email) ? '' : t('error:email_is_not_valid')
          break
        }
        case 'message': {
          errors.message = form.message.length ? '' : t('error:message_is_required')
          break
        }
      }
    })
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    let valid = true
    validForm(form)
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false))
    setErrors({ ...errors })
    if (!isSubmit) {
      setSubmit(true)
    }
    if (valid) {
      sendForm({
        email: form.email,
        name: form.name,
        message: form.message,
        'g-recaptcha-response': ReCaptchaValue,
      })
      setForm({
        name: '',
        email: '',
        message: '',
      })
    } else {
    }
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target
    const newForm = { ...form, [name]: value }
    setForm(newForm)
    if (isSubmit) {
      validForm(newForm)
      setErrors({ ...errors })
    }
  }

  const screenClass = useScreenClass()

  return (
    <Section>
      <form onSubmit={handleSubmit}>
        <Row>
          <Col className={screenClass}>
            <Title>{t('page:get_in_touch')}</Title>
          </Col>
        </Row>
        {props.children ? (
          <Row>
            <Col className={screenClass}>{props.children}</Col>
          </Row>
        ) : null}
        <InputWrapper>
          <Col className={screenClass}>
            <Input
              className={screenClass}
              placeholder={t('form:name')}
              value={form.name}
              name="name"
              onChange={onChange}
            />
            {errors.name && <Error>{errors.name}</Error>}
          </Col>
          <Col className={screenClass}>
            <Input
              className={screenClass}
              type="email"
              value={form.email}
              placeholder={t('form:email')}
              name="email"
              onChange={onChange}
            />
            {errors.email && <Error>{errors.email}</Error>}
          </Col>
        </InputWrapper>
        <Row className={screenClass}>
          <Col className={screenClass}>
            <Textarea
              className={screenClass}
              placeholder={t('form:message')}
              value={form.message}
              name="message"
              onChange={onChange}
            />
            {errors.message && <Error>{errors.message}</Error>}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="submit" className={screenClass}>
              {t('form:send_message')}
            </Button>
          </Col>
        </Row>
      </form>
    </Section>
  )
}

export default ContactForm
