import styled from '@optios-frontend-website/shared-ui/src/theme'

export const Button = styled.button`
  display: inline-block;
  appearance: none;
  padding: 0.8em 1.1em;
  margin: 0 0 0 0.3em;
  border: 3px solid;
  border-radius: 99px;
  background: none;
  color: ${(props) => props.theme.colors.text};
  font-weight: 600;
  font-size: 1.1em;
  transition: all 0.2s ease;
  cursor: pointer;
  vertical-align: middle;
  &:focus {
    outline: none;
  }
  &:hover {
    background: ${(props) => props.theme.colors.primary};
    border-color: transparent;
    color: #fff;
  }
  &.dialog-close {
    color: rgba(0, 0, 0, 0.3);
    &:hover {
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
    }
  }
  &.dialog-delete {
    color: #e41b17;
    &:hover {
      background: #e41b17;
      color: #fff;
    }
  }
  &.left {
    float: left;
  }
  &.small {
    font-size: 0.8em;
    padding: 0.7em 0.8em 0.6em 0.8em;
  }
`
