import themeStyled from '../../../theme'

export const WebsiteLogo = themeStyled.div`
  margin: auto;
`

export const Title = themeStyled.h1`
  color: #fff;
  font-size: 2em;
  font-weight: 600;
  margin: 0;
  text-shadow: 1px 1px 1px #333;
  line-height: 1em;
`

export const Image = themeStyled.img`
  max-height: 200px;
  &.sm {
    max-height: 150px;
  }
  &.xs {
    max-height: 110px;
  }
`
