import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ContentSection,
  ContentSectionHeading,
  ContentSectionWrapper,
  Image,
} from 'shared/SectionControl/NewSectionModal/components/ModalContent/pages/style'

import FormSectionImage from '../../assets/FormSection/form-section.png'

export const FormPage = () => {
  const { t } = useTranslation()

  return (
    <ContentSectionWrapper>
      <ContentSectionHeading>{t('Form section')}</ContentSectionHeading>
      <ContentSection>
        <Image alt="" src={FormSectionImage} />
      </ContentSection>
    </ContentSectionWrapper>
  )
}
