import styled from '@emotion/styled'
import GoogleMapReact from 'google-map-react'

export const Container = styled.section`
  height: 25vh;
  width: 100%;
  display: flex;
`

export const InputSearch = styled.input`
  position: absolute;
  z-index: 2;
`

export const StyledMaps = styled(GoogleMapReact)`
  transform: none;
  animation: none;
`
