import styled from '../../theme'
import { Col as SystemCol } from 'react-grid-system'

import { BaseTitle } from '../../base-style'

export const Section = styled.section``
export const Title = styled(BaseTitle)`
  :first-of-type {
    margin-top: 0px;
  }
  margin-bottom: 0.4em;
  margin-top: 0.8em;
`

export const Col = styled(SystemCol)`
  margin-bottom: 25px;
  &.xxl {
    margin-bottom: 20px;
  }
  &.xl,
  &.lg,
  &.md {
    margin-bottom: 15px;
  }
  &.sm,
  &.xs {
    margin-bottom: 10px;
  }
`

export const InputWrapper = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  display: ${({ theme }) => theme.contactForm.inputDirection};
  flex-flow: row wrap;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: normal;
  position: relative !important;
  justify-content: flex-start;
`

export const Input = styled.input`
  transition: border-color 0.2s ease-in-out;
  background: #fff;
  border: solid 1px ${(props) => props.theme.colors.border};
  border-radius: 5px;
  color: inherit;
  display: block;
  outline: 0;
  padding: 0.75em;
  text-decoration: none;
  width: 100%;
  font-size: 16pt;
  font-weight: 300;
  &.xxl,
  &.xl {
    font-size: 14pt;
  }
  &.lg,
  &.md {
    font-size: 13pt;
  }
  &.sm,
  &.xs {
    font-size: 11pt;
  }
  line-height: 1em;
  &:focus {
    border-color: ${(props) => props.theme.colors.link};
  }
`
export const Textarea = styled(Input.withComponent('textarea'))`
  @media screen and (max-width: 1680px) {
    line-height: 1.5em;
  }
  @media screen and (max-width: 1280px) {
    line-height: 1.5em;
  }
  @media screen and (max-width: 736px) {
    font-size: 11pt;
    line-height: 1.35em;
  }
`
export const Button = styled.button`
  font: inherit;
  background-color: ${(props) => props.theme.colors.submit};
  color: ${({ theme }) => theme.colors.text};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0 1.5em;
  line-height: 2.75em;
  min-width: 9em;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.025em;
  outline: none;
  margin: 0;
  :hover {
    background-color: ${(props) => props.theme.colors.submit_hover};
  }
  &.xs {
    width: 100%;
  }
`
export const Error = styled.span`
  color: ${(props) => props.theme.colors.danger};
`

export const DivButton = styled.div`
  font: inherit;
  background-color: ${(props) => props.theme.colors.submit};
  color: ${({ theme }) => theme.colors.text};
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
    -webkit-box-shadow 0.2s ease-in-out;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  padding: 0 1em;
  line-height: 2.75em;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.025em;
  outline: none;
  margin: 0;
  float: right;
  :hover {
    background-color: ${(props) => props.theme.colors.submit_hover};
  }
  &.xs {
    width: 100%;
  }
`
