import React, { FunctionComponent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { DeleteImageIcon, SlickSlide } from '../style'

import { Image } from './style'
import { IProps } from './IProps'

export const CustomSlide: FunctionComponent<IProps> = ({ src, deleteImage }: IProps) => {
  return (
    <SlickSlide>
      <Image src={src} />
      <DeleteImageIcon onClick={() => deleteImage()}>
        <FontAwesomeIcon icon={'times'} />
      </DeleteImageIcon>
    </SlickSlide>
  )
}
