import React, { FC } from 'react'
import { Section, Title, Address, Link } from './style'
import { IContact } from '../../types/sections'
import { useTranslation } from 'react-i18next'
import { nl2br } from '../../utils/nl2br'

type ContactsProps = {
  data: IContact
  handleClick?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const Contacts: FC<ContactsProps> = (props: ContactsProps) => {
  const { t } = useTranslation()
  const { data, handleClick } = props

  return (
    <Section onClick={handleClick}>
      <Title>{t('page:address')}</Title>
      <Address>{nl2br(data.address)}</Address>
      <Title>{t('page:email')}</Title>
      <Link href={`mailto:${data.email}`}>{data.email}</Link>
      <Title>{t('page:phone')}</Title>
      <Link href={`tel:${data.phone}`}>{data.phone}</Link>
    </Section>
  )
}

export default Contacts
