import React, { FC, useEffect, useState } from 'react'

import { ImageGrid, Item, Image, ItemDelete, ItemDeleteWrapper, ItemDeleteIcon } from './style'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { PageSectionTypes } from '../../../../../../shared-ui/src/types/sections'
import { deletePhoto, getGallery } from 'services/website-service'
import { WEBSITE_BANNER } from '../../../../constants'
import { useSelector } from 'react-redux'
import { ApplicationState } from 'store/index'
import Spinner from 'shared/Spinner'

type ImageListProps = {
  activeId: string
  url: string
  bannerFiles: Array<{ id: number; mime: string }>
  onImageSelected: (id: string, isVideo: boolean) => void
  updateSection: (name: string, section: PageSectionTypes, position: number) => void
  position: number
}

const ImageList: FC<ImageListProps> = (props: ImageListProps) => {
  const [imageGallery, setImageGallery] = useState<any>(null)

  const { images } = useSelector((state: ApplicationState) => ({
    images: state.config.data.banner,
  }))

  useEffect(() => {
    setImageGallery(images?.files)
  }, [images])

  useEffect(() => {
    getGallery(WEBSITE_BANNER).then((updatedGallery) => {
      setImageGallery(updatedGallery.data.files)
    })
  }, [])

  const deleteImage = async (imageId: number) => {
    try {
      await deletePhoto(imageId)
      setImageGallery((prevImages: any) => prevImages.filter((image: any) => image.id !== imageId))
    } catch (error) {
      console.error('Error deleting image:', error)
    }
  }

  return (
    <ImageGrid>
      {imageGallery ? (
        <>
          {imageGallery.map(
            (item: any): JSX.Element => (
              <React.Fragment key={item.id}>
                <Item
                  onClick={() => props.onImageSelected(item.id.toString(), item.mime.startsWith('video'))}
                  className={props.activeId === item.id.toString() ? 'active' : ''}
                  key={item.id}
                >
                  {item.mime.startsWith('video') ? (
                    <video width="234" height="134" autoPlay={true} loop={true}>
                      <source src={`${props.url}?id=${item.id}`} type={item.mime} />
                    </video>
                  ) : (
                    <Image src={`${props.url}?id=${item.id}&w=234&h=134&fit=crop`} />
                  )}
                </Item>
                <ItemDelete>
                  <ItemDeleteWrapper onClick={() => deleteImage(item.id)}>
                    <ItemDeleteIcon icon={faCircleXmark} />
                  </ItemDeleteWrapper>
                </ItemDelete>
              </React.Fragment>
            ),
          )}
        </>
      ) : (
        <Spinner isLoaded />
      )}
    </ImageGrid>
  )
}

export default ImageList
