import styled from '@optios-frontend-website/shared-ui/src/theme'

export const SetRecipient = styled.button`
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    color: #0090ff;
  }
`
