import { getConfiguration, getGallery } from 'services/website-service'
import { WEBSITE_BANNER, WEBSITE_GALLERY, WEBSITE_LOGO } from '../constants'
import {
  ISectionsConfig,
  SectionName,
  IConfigData,
  ConfigAddBySlugAction,
  IFeedbackDataConfig,
  ITextAndImageConfig,
  IFileDataConfig,
  IBookingDataConfig,
} from 'store/config/types'

export const addDataConfig = (
  sections: ISectionsConfig,
  addConfigBySlug: (name: string, data: IConfigData) => ConfigAddBySlugAction,
): void => {
  Object.keys(sections).forEach((section) => {
    if (sections[section as SectionName].configurable) {
      if (sections[section as SectionName].slug === 'textandimages') {
        getConfiguration('textandimage').then(({ data }) => {
          addConfigBySlug('textandimages', data as ITextAndImageConfig)
        })
      } else {
        switch (sections[section as SectionName].slug) {
          case 'feedback': {
            getConfiguration(sections[section as SectionName].slug).then(
              ({ data }) => {
                addConfigBySlug(sections[section as SectionName].slug, data as IFeedbackDataConfig)
              },
              (error) => {
                console.log(error)
              },
            )
            break
          }
          case 'gallery': {
            getGallery(WEBSITE_GALLERY).then(
              ({ data }) => {
                addConfigBySlug('gallery', data as IFileDataConfig)
              },
              (error) => {
                console.log(error)
              },
            )
            break
          }
          case 'header': {
            getGallery(WEBSITE_LOGO).then(({ data }) => {
              addConfigBySlug('logo', data as IFileDataConfig)
            })
            getGallery(WEBSITE_BANNER).then(({ data }) => {
              addConfigBySlug('banner', data as IFileDataConfig)
            })
            break
          }
          case 'booking': {
            getConfiguration(sections[section as SectionName].slug).then(
              ({ data }) => {
                addConfigBySlug(sections[section as SectionName].slug, data as IBookingDataConfig)
              },
              (error) => {
                console.log(error)
              },
            )
            break
          }
          default: {
          }
        }
      }
    }
  })
}
