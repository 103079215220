import React, { FunctionComponent, useEffect, useState, useCallback } from 'react'
import { IProps } from 'shared/ButtonsContainer/IProps'
import { IButton } from '../../../../../shared-ui/src/types/sections'

import { Container } from 'shared/ButtonsContainer/style'
import { useTranslation } from 'react-i18next'
import Spinner from 'shared/Spinner'
import { Button, Input, SaveButton, ToolTip, ToolTipWrapper } from 'shared/ButtonsContainer/style'

export const ButtonsContainer: FunctionComponent<IProps> = ({ data, updateSection, position, col }) => {
  const { t } = useTranslation()

  const [inputName, setInputName] = useState<string>('')
  const [inputLink, setInputLink] = useState<string>('')
  const [isToolTipVisible, setIsToolTipVisible] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [currentToolTipId, setCurrentToolTipId] = useState<string | null>(null)

  useEffect(() => {
    if (data.content) {
      setInputName(data.content.name)
      setInputLink(data.content.link)
    }
  }, [data])

  const handleMouseEnter = (event: any) => {
    event.stopPropagation()
    setIsToolTipVisible(true)
    setIsEditing(true)
  }

  useEffect(() => {
    if (isToolTipVisible) {
      setCurrentToolTipId(`tooltipId-${col}`)
    }
  }, [isToolTipVisible, col])

  const handleClickOutside = useCallback(
    (event: any) => {
      if (currentToolTipId) {
        const tooltip = document.getElementById(currentToolTipId)
        if (tooltip && !tooltip.contains(event.target)) {
          setIsToolTipVisible(false)
        }
      }
    },
    [currentToolTipId, setIsToolTipVisible],
  )
  useEffect(() => {
    if (currentToolTipId) {
      document.addEventListener('click', handleClickOutside)
      return () => {
        document.removeEventListener('click', handleClickOutside)
      }
    }
  }, [currentToolTipId, handleClickOutside])

  const handleMouseLeave = () => {
    if (!isEditing) {
      setIsToolTipVisible(false)
    }
  }

  const handleSave = () => {
    setIsToolTipVisible(false)
    setIsEditing(false)

    const formattedLink = inputLink.startsWith('https://') ? inputLink : `https://${inputLink}`

    const buttonDataObject: IButton = {
      name: inputName,
      link: formattedLink,
    }

    updateSection('button', { content: buttonDataObject }, position, col)
  }

  if (!data) {
    return <Spinner isLoaded={true} />
  }

  return (
    <>
      <Container>
        <Button
          href={
            inputLink ? (/^https?:\/\//.test(inputLink) ? inputLink : `https://${inputLink}`) : 'https://example.com'
          }
          target="_blank"
          onMouseEnter={(event) => handleMouseEnter(event)}
          onMouseLeave={handleMouseLeave}
        >
          {inputName ? inputName : 'Default'}
        </Button>
        <ToolTip isToolTipVisible={isToolTipVisible} id={`tooltipId-${col}`}>
          <ToolTipWrapper>
            <Input
              value={inputName ? inputName : ''}
              onChange={(e) => setInputName(e.target.value)}
              placeholder="ex. Link"
            />
            <Input
              value={inputLink ? inputLink : ''}
              onChange={(e) => setInputLink(e.target.value)}
              placeholder="ex. https://example.com"
            />
            <SaveButton onClick={handleSave}>{t('Save')}</SaveButton>
          </ToolTipWrapper>
        </ToolTip>
      </Container>
    </>
  )
}
