import styled from '@optios-frontend-website/shared-ui/src/theme'
import { css } from '@emotion/core'

export const commonMedia = css`
  font-size: 16pt;
  &.xl {
    font-size: 14pt;
  }
  &.lg {
    font-size: 13pt;
  }
  &.md,
  &.sm,
  &.xs {
    font-size: 11pt;
  }
`
export const pageWrapperMediaQueries = css`
  @media (max-width: 1023px) {
    padding: 3.45em 0 0 3.45em;
  }
  @media (max-width: 767px) {
    padding: 45px 0 0 0;
  }
`

// Page  wrapper is shared between admin and public, padding should be gone on public !!!!!
export const PageWrapper = styled.div`
  color: ${({ theme }) => theme.colors.text};
  background: ${({ theme }) => theme.colors.backgroundFirst};
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  line-height: 1.65em;
  padding: 50px 0 0 80px;
  input,
  textarea {
    font-family: 'Source Sans Pro', sans-serif;
  }
  ${commonMedia};
  ${pageWrapperMediaQueries};
`

export const MenuWrapper = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 100;
  overflow-x: none;
`
