import { IWebsite, WebsiteActionTypes, ADD_WEBSITE, EDIT_WEBSITE, UPDATE_CURRENT_LANGUAGE } from './types'

const initialState: IWebsite = {
  establishmentId: '',
  slug: '',
  domain: '',
  title: '',
  theme_id: '',
  languages: [],
  currentLanguage: '',
  default_language: 'en',
}

export const websiteReducer = (state = initialState, action: WebsiteActionTypes): IWebsite => {
  switch (action.type) {
    case ADD_WEBSITE:
      return { ...state, ...action.payload }
    case EDIT_WEBSITE:
      return { ...state, ...action.payload }
    case UPDATE_CURRENT_LANGUAGE:
      return { ...state, currentLanguage: action.payload }
    default:
      return state
  }
}
