import React, { FC } from 'react'
import SharedMap from '@optios-frontend-website/shared-ui/src/components/Map'
import SectionControl from 'shared/SectionControl'
import { IMap } from 'store/page/types'

type Props = {
  data: IMap
  updateSection: (name: string, section: IMap, position: number) => void
  position: number
}

const Map: FC<Props> = (props: Props) => {
  const updateAddress = (address: string) => {
    props.updateSection('map', { address }, props.position)
  }

  return (
    <SectionControl position={props.position}>
      <SharedMap updateAddress={updateAddress} data={props.data} position={props.position}></SharedMap>
    </SectionControl>
  )
}

export default Map
