import Dropzone from 'shared/Dropzone'
import React, { FunctionComponent, useState } from 'react'
import { addFile } from 'services/website-service'
import { SectionNames, WEBSITE_PHOTO } from '../../constants'
import { errorToast, successToast } from 'services/toasts'
import { IPhoto, PageSectionTypes } from '../../../../shared-ui/src/types/sections'
import SharedPhoto from '@optios-frontend-website/shared-ui/src/components/Photo'
import { IConfig } from 'store/config/types'

type FileData = {
  name: string | null
  data: string | ArrayBuffer | null
}

type Props = {
  position: number
  data: IPhoto
  updateSection: (name: string, section: PageSectionTypes, position: number, col: number) => void
  config: IConfig
  col: number
}

const Photo: FunctionComponent<Props> = ({ updateSection, position, config, data, col }: Props) => {
  const [fileData, setFile] = useState<FileData>({ data: null, name: null })
  const [id, setId] = useState<number>(data.id)

  const onDrop = (acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const data = reader.result
        setFile({ data, name: file.name })
        addFile(WEBSITE_PHOTO, file).then(
          (response) => {
            if (response.status >= 299) {
              errorToast()

              return
            }

            setId(response.data.data.id)
            updateSection(SectionNames.PHOTO, { id: response.data.data.id }, position, col)
            successToast()
          },
          () => {
            errorToast('The image is too large. Try again with another one.')
          },
        )
      }
      reader.readAsArrayBuffer(file)
    })
  }

  return (
    <>
      <SharedPhoto src={`${config.storageConfig.url}/?id=${id}&w=1024&h=1024&fit=max`} />
      <Dropzone onDrop={onDrop} fileName={fileData.name} multiple={false} />
    </>
  )
}

export default Photo
