import React, { FunctionComponent } from 'react'

import { faPlus } from '@fortawesome/free-solid-svg-icons'

import Button from 'shared/ButtonModal'

import { IProps } from './IProps'
import { AddIcon } from './style'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const IdleSelectState: FunctionComponent<IProps> = ({ setActivitySelectObject }) => {
  const faAddIcon = faPlus as IconProp
  return (
    <>
      <Button
        handleClick={() =>
          setActivitySelectObject({
            type: 'menu',
            activeSelection: [],
            selectionDetails: null,
          })
        }
      >
        <AddIcon icon={faAddIcon} />
        Menu
      </Button>
      <Button
        handleClick={() =>
          setActivitySelectObject({
            type: 'activity',
            activeSelection: [],
            selectionDetails: null,
          })
        }
      >
        <AddIcon icon={faAddIcon} />
        Activity
      </Button>
    </>
  )
}
