import React, { FC, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import InputField from 'shared/InputField'
import SelectField from 'shared/SelectField'
import {
  createWebsite,
  getWebsiteByOrganization,
  editWebsite,
  getConfigAuth,
  getPageByWebsite,
  getWebsite,
} from 'services/website-service'
import { Wrapper, Button, LinkToBack, IconBack, Title } from './style'
import { IConfig } from 'store/config/types'
import { IWebsite } from 'store/website/types'
import { ITheme } from 'store/theme/types'
import { successToast, errorToast } from 'services/toasts'
import { ApplicationState } from 'store'
import { addWebsite, updateCurrentLanguage } from 'store/website/actions'
import { addPages, setCurrentPage } from 'store/page/actions'
import { useTranslation } from 'react-i18next'

type Item = {
  label: string
  value: string
}

type Props = {
  config: IConfig
  website: IWebsite
  themes: ITheme[]
}

type FormValues = {
  slug: string
  domain: string
  title: string
  theme_id: string
  languages: { [key: string]: boolean }
}

type AllProps = Props & typeof mapDispatch

const Website: FC<AllProps> = (props: AllProps) => {
  const { themes, website, config, updateCurrentLanguage, addWebsite, addPages, setCurrentPage } = props
  const [themeList, setTheme] = useState<Item[]>([])
  const [languages, setLanguages] = useState<Item[]>([])
  const { register, handleSubmit, errors, setValue, getValues } = useForm<FormValues>()

  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    const languages = Object.keys(config.languages).map((key) => ({
      label: config.languages[key],
      value: key,
    }))
    setLanguages(languages)
  }, [config])

  useEffect(() => {
    const list = themes.map((theme) => ({ label: theme.title, value: theme.id }))
    if (!website.id && list.length) {
      setValue('theme_id', list[0].value)
    }
    setTheme(list)
  }, [themes, website, setValue])

  useEffect(() => {
    if (website.id) {
      setValue('slug', website.slug)
      setValue('domain', website.domain)
      setValue('title', website.title)
      setValue('theme_id', website.theme_id)
      website.languages.forEach((lan) => {
        setValue('languages.' + lan, lan)
      })
    } else {
      if (themeList.length && languages.length) {
        setValue('theme_id', themeList[0].value)
        languages.forEach((lan) => {
          setValue('languages.' + lan.value, lan.value)
        })
      }
    }
  }, [themeList, languages, setValue, website])

  const handleSelect = (name: string, value: string) => {
    setValue(name, value)
  }


  const onSave = (data: FormValues) => {
    const languages = Object.keys(data.languages).reduce((state: string[], language) => {
      if (data.languages[language]) {
        state.push(language)
      }
      return state
    }, []) as string[]

    delete data.languages
    if (website.id) {
      const newForm = { ...data, id: website.id, languages } as IWebsite
      editWebsite(newForm).then(
        () => {
          if (website.id) {
            getWebsite(website.id).then(({ data }) => {
              props.addWebsite(data)
            })
          }
          successToast()
        },
        ({ response }) => {
          errorToast(response?.data?.message)
        },
      )
    } else {
      const newForm = { ...data, languages } as IWebsite


      createWebsite(newForm).then(
        () => {
          getConfigAuth().then(({ data }) => {
            getWebsiteByOrganization(data.organization_uuid as string).then(({ data: website }) => {
              const lang =
                navigator.languages[1] && website.languages.includes(navigator.languages[1])
                  ? navigator.languages[1]
                  : website.languages[0]
              updateCurrentLanguage(lang)
              addWebsite(website)
              getPageByWebsite(website.id as string).then(({ data }) => {
                addPages(data)
                const currentPage = data.find((item) => item.locale === lang)
                if (currentPage || (data && data.length)) {
                  const newCurrentPage = currentPage || data[0]
                  setCurrentPage(newCurrentPage)
                  history.push('/update')
                }
              })
            })
          })
          successToast()
        },
        ({ response }) => {
          errorToast(response?.data?.message)
        },
      )
    }
  }

  return (
    <Wrapper>
      {website.id ? (
        <LinkToBack to="/update">
          <>
            <IconBack>
              <FontAwesomeIcon icon={'arrow-left'} />
            </IconBack>
            {t('page:back')}
          </>
        </LinkToBack>
      ) : null}
      <Title>{t('Website')}</Title>
      <form
        onSubmit={handleSubmit((data) => {
          onSave(data)
        })}
      >
        <InputField
          name="slug"
          registerRef={register({
            required: 'This is required',
          })}
          error={errors?.slug?.message}
          label="Slug"
        />

        <InputField name="domain" error={errors?.domain?.message} registerRef={register} label="Domain" />
        <InputField
          name="title"
          registerRef={register({
            required: 'This is required',
          })}
          label="Title"
          error={errors?.title?.message}
        />

        <SelectField
          list={themeList}
          defaultValue={themeList.find((item) => item.value === (getValues('theme_id') || website.theme_id))}
          name="theme_id"
          registerRef={register}
          label="Theme"
          handleSelect={handleSelect}
        />
        <p>Languages:</p>
        {languages.map((language) => (
          <InputField
            key={language.value}
            name={'languages.' + language.value}
            type="checkbox"
            registerRef={register}
            label={language.label}
          />
        ))}
        <Button type="submit">{t('page:submit')}</Button>
      </form>
    </Wrapper>
  )
}

const mapState = (state: ApplicationState) => ({
  config: state.config,
  website: state.website,
  themes: state.theme,
})

const mapDispatch = {
  setCurrentPage,
  addPages,
  addWebsite,
  updateCurrentLanguage,
}

export default connect(mapState, mapDispatch)(Website)
