import React, { FC } from 'react'

import SharedFeedback from '@optios-frontend-website/shared-ui/src/components/Feedback'
import SectionControl from 'shared/SectionControl'

import { IFeedback } from '../../../../shared-ui/src/types/sections'

type Props = {
  position: number
  data: IFeedback[] | null
}

const Feedback: FC<Props> = (props: Props) => {
  return (
    <div>
      <SectionControl position={props.position}>
        <SharedFeedback data={props.data || []} />
      </SectionControl>
    </div>
  )
}

export default Feedback
