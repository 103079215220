import styled from '@optios-frontend-website/shared-ui/src/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const ModalWrapper = styled.div`
  display: flex;
`

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`

export const Card = styled.div`
  width: 1000px;
  height: 850px;
  position: absolute;
  top: 52%;
  left: 53%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: grid;
  grid-template-columns: 1fr 2fr;
`

export const NavigationWrapper = styled.div``

export const ContentWrapper = styled.div`
  padding: 10px;
  background: #f4f0f7;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5em 0.4em;
`

export const ButtonIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  margin-right: 8px;
`
export const SelectSectionWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 58%;
`

export const Button = styled.button`
  margin-left: 10px;
  padding: 8px 16px;
  border-radius: 4px;
  min-width: 110px;
  height: 45px;
  font-size: ${({ theme }) => theme.sizes.xsmall};
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  background: rgba(39, 46, 59, 1);
  transition: background 0.3s;
  position: relative;
  z-index: 2;
  justify-content: center;
  text-align: center;
  border: none;
  &:hover {
    background: rgba(39, 46, 59, 0.8);
    cursor: pointer;
  }
`
