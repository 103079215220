import styled from '@optios-frontend-website/shared-ui/src/theme'

export const PictureUpload = styled.div`
  padding: 1em 2em;
  margin: 0 0 1em;
  margin-top: 1em;
  border: 4px dashed ${({ theme }) => theme.colors.text};
  border-radius: 15px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.9em;
  line-height: 1.2;
  position: relative;
  display: flex;
  align-items: center;
  outline: none;
  p {
    width: 100%;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: ${({ theme }) => theme.sizes.small};
  }
  &.active {
    border-color: ${(props) => props.theme.colors.customers};
  }
`
