import React, { FC, useState, useEffect } from 'react'

import InputField from 'shared/InputField'
import Modal from 'shared/Modal'
import { IFooter, IPageItem, PageSectionTypes } from 'store/page/types'
import SectionControl from 'shared/SectionControl'
import Button from 'shared/ButtonModal'
import SocialForm from './SocialForm'
import SocialMedia from './SocialMedia'
import { Footer as FooterWrapper, List, Item, Socials, Title } from './style'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { setCurrentPage } from 'store/page/actions'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  data: IFooter
  updateSection: (name: string, section: PageSectionTypes, position: number) => void
  position: number
  pages: IPageItem[]
  locale?: string
  isEditing?: boolean
}

type FormValues = {
  copyright: string
  vat: string
}

type Media = {
  name: string
  link: string
}

const Footer: FC<Props> = (props: Props) => {
  const { register, handleSubmit, errors, reset } = useForm<FormValues>({
    defaultValues: { copyright: props.data.copyright, vat: props.data.vat },
  })

  useEffect(() => {
    reset({ copyright: props.data.copyright, vat: props.data.vat })
  }, [props.data, reset])

  const dispatch = useDispatch()

  const [modalIsOpen, setIsOpen] = useState(false)
  const [medias, setMedia] = useState<Media[]>(props.data.social || [])
  const [submitClicked, setSubmitClicked] = useState<boolean>(false)

  const { t } = useTranslation()
  const onRequestClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setIsOpen(false)
    setSubmitClicked(false)
  }

  const open = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsOpen(true)
  }

  const onSave = (data: FormValues) => {
    setSubmitClicked(true)
    props.updateSection('footer', { ...data, social: medias }, props.position)
    setIsOpen(false)
    setSubmitClicked(false)
  }

  const deleteSocial = (name: string) => {
    const index = medias.findIndex((media) => media.name === name)
    if (index !== -1) {
      medias.splice(index, 1)
    }
    setMedia([...medias])
  }

  const addNewSocial = (data: { name: string; link: string }) => {
    const index = medias.findIndex((media) => media.name === data.name)
    if (index === -1) {
      medias.push({ ...data })
    } else {
      medias.splice(index, 1, data)
    }
    setMedia([...medias])
  }

  return (
    <>
      <SectionControl position={props.position} isAdd={false}>
        <FooterWrapper onClick={open}>
          <nav>
            <Socials>
              {props.data.social.map((item) => {
                return (
                  <li key={item.name}>
                    <a href={item.link} rel="noopener noreferrer" target="_blank">
                      <FontAwesomeIcon icon={['fab', item.name as never]} />
                    </a>
                  </li>
                )
              })}
            </Socials>
            <List>
              {props.data.copyright && <Item>{props.data.copyright}</Item>}
              {props.data.vat && <Item>{props.data.vat}</Item>}
              {props.pages
                .filter((page) => page.nav_location === 'footer' && page.locale === props.locale)
                .map((page) => (
                  <Item key={`footer${page.id}`}>
                    <Link
                      to={`/update/${page.slug}`}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        dispatch(setCurrentPage(page))
                      }}
                    >
                      {page.title}
                    </Link>
                  </Item>
                ))}
            </List>
          </nav>
        </FooterWrapper>
      </SectionControl>
      <Modal isOpen={modalIsOpen} onRequestClose={onRequestClose} title="Footer part">
        <div>
          <form
            onSubmit={handleSubmit((data) => {
              onSave(data)
            })}
          >
            <InputField
              registerRef={register}
              name={'copyright'}
              label={t('footer:copyright')}
              error={errors.copyright?.message}
            />
            <InputField
              registerRef={register({ required: 'This field is required' })}
              name={'vat'}
              label={t('footer:vat')}
              error={errors.vat?.message}
            />
            <Title>{t('footer:media')} : </Title>
            {medias.map((media, index) => (
              <SocialMedia
                deleteSocial={deleteSocial}
                key={media.link + index}
                media={{ ...media }}
                addNewSocial={addNewSocial}
              />
            ))}
            <div>
              <SocialForm addNewSocial={addNewSocial} submitClicked={submitClicked} />
            </div>
            <div style={{ textAlign: 'right' }}>
              <Button handleClick={onRequestClose} className="dialog-close">
                {t('page:cancel')}
              </Button>
              <Button type="submit">{t('page:save')}</Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default Footer
