import React, { useState, FunctionComponent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SharedContacts from '@optios-frontend-website/shared-ui/src/components/Contacts'
import Modal from 'shared/Modal'
import Button from 'shared/ButtonModal'
import InputField from 'shared/InputField'

import { Regexp } from '../../constants'
import { IProps, TFormValues } from './IProps'
import { checkIfValidEmail } from 'services/website-service'

const Contacts: FunctionComponent<IProps> = ({ updateSection, position, col, data, isEditing }) => {
  const { register, handleSubmit, errors } = useForm<TFormValues>({ defaultValues: data })
  const [emailValidationError, setEmailValidationError] = useState<string | undefined>()
  const [emailField, setEmailField] = useState<string | undefined>(data.email)
  const [modalIsOpen, setIsOpen] = useState(isEditing)
  const { t } = useTranslation()

  useEffect(() => {
    setIsOpen(isEditing)
  }, [isEditing])
  const onRequestClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setIsOpen(false)
  }

  const open = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsOpen(true)
  }

  const isEmailValidCheck = async () => {
    if (!emailField) {
      return setEmailValidationError(t('Please fill in a email.'))
    }
    checkIfValidEmail(emailField)
      .then(() => {
        return setEmailValidationError(undefined)
      })
      .catch((err) => {
        return setEmailValidationError(t(err.response.data.message))
      })
  }

  const submitHandler = handleSubmit((formData) => {
    isEmailValidCheck().then(() => {
      updateSection('contact', formData, position, col)
      setIsOpen(false)
    })
  })

  return (
    <>
      <SharedContacts data={data} handleClick={open} />
      <Modal title="Contacts" isOpen={modalIsOpen} onRequestClose={onRequestClose}>
        <form onSubmit={submitHandler}>
          <InputField
            type="textarea"
            name="address"
            registerRef={register({
              required: 'This is required',
            })}
            label="Address"
            error={errors?.address?.message}
          />
          <InputField
            registerRef={register({
              pattern: {
                value: RegExp(Regexp.EMAIL),
                message: 'Enter valid email',
              },
            })}
            name="email"
            label="E-mail"
            error={emailValidationError}
            onChange={(e) => setEmailField(e.target.value)}
          />
          <InputField
            name="phone"
            registerRef={register({
              pattern: {
                value: RegExp(Regexp.PHONE),
                message: 'Enter valid phone number',
              },
            })}
            label="Phone"
            error={errors?.phone?.message}
          />
          <div style={{ textAlign: 'right' }}>
            <Button handleClick={onRequestClose} className="dialog-close">
              {t('page:cancel')}
            </Button>
            <Button type="submit">{t('page:save')}</Button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default Contacts
