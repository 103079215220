import { combineReducers, createStore, compose } from 'redux'

import { IConfig } from './config/types'
import { IWebsite } from './website/types'
import { IAdminPage } from './page/types'
import { ITheme } from './theme/types'

import { configReducer } from './config/reducers'
import { websiteReducer } from './website/reducers'
import { pageReducer } from './page/reducers'
import { themeReducer } from './theme/reducers'

export interface ApplicationState {
  config: IConfig
  page: IAdminPage
  website: IWebsite
  theme: ITheme[]
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const rootReducer = combineReducers<ApplicationState>({
  config: configReducer,
  page: pageReducer,
  website: websiteReducer,
  theme: themeReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(rootReducer, composeEnhancers())
export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof rootReducer>
