import { Employee } from 'shared/Employee'
import React, { FunctionComponent, useMemo } from 'react'
import { IProps } from 'shared/Employee/RenderEmployee/interfaces/IProps'

export const RenderEmployee: FunctionComponent<IProps> = ({ data, position, col, updateSection }) => {
  return useMemo(
    () => <Employee data={data} position={position} col={col} updateSection={updateSection} />,
    [data, position, col, updateSection],
  )
}
