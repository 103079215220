import {
  IConfig,
  ConfigAddAction,
  ADD_CONFIG,
  ADD_CONFIG_SLUG,
  ConfigAddBySlugAction,
  IConfigData,
  GET_MENU_CATEGORIES_SUCCESS,
  GET_MENU_CATEGORIES_FAILURE,
  GET_EXTRA_ACTIVITIES_SUCCESS,
  GET_EXTRA_ACTIVITIES_FAILURE,
  GET_EXTRA_ACTIVITIES,
  GET_MENU_CATEGORIES,
} from './types'
import { IMenuCategory } from 'components/PricingCrud/components/ActivitySelect/interface/IMenuCategory'
import { IActivityCategory } from 'components/PricingCrud/components/ActivitySelect/interface/IActivity'
import {
  getActivitySelectCall,
  getMenuCategoriesCall,
} from 'services/establishment-activity-call/establishment-activity-call'
import { Dispatch } from 'react'

export const addConfig = (config: IConfig): ConfigAddAction => {
  return {
    type: ADD_CONFIG,
    payload: config,
  }
}

export const addConfigBySlug = (name: string, section: IConfigData): ConfigAddBySlugAction => {
  return {
    type: ADD_CONFIG_SLUG,
    payload: { name, section },
  }
}

export const getMenuCategoriesSuccess = (name: string, menu: IMenuCategory[]) => {
  return {
    type: GET_MENU_CATEGORIES_SUCCESS,
    payload: { name, menu },
  }
}

export const getMenuCategoriesFailure = () => {
  return {
    type: GET_MENU_CATEGORIES_FAILURE,
    payload: { isLoading: false },
  }
}

export const getMenuCategories = (establishmentId: string, dispatch: Dispatch<any>) => {
  getMenuCategoriesCall(establishmentId).then(
    (menuArray) => dispatch(getMenuCategoriesSuccess('menu', menuArray)),
    () => dispatch(getMenuCategoriesFailure()),
  )

  return {
    type: GET_MENU_CATEGORIES,
    payload: { isLoading: true },
  }
}

export const getActivitiesSuccess = (name: string, activity: IActivityCategory[]) => {
  return {
    type: GET_EXTRA_ACTIVITIES_SUCCESS,
    payload: { name, activity, isLoading: true },
  }
}

export const getActivitiesFailure = () => {
  return {
    type: GET_EXTRA_ACTIVITIES_FAILURE,
    payload: { isLoading: false },
  }
}

export const getActivities = (establishmentId: string, dispatch: Dispatch<any>) => {
  getActivitySelectCall(establishmentId).then(
    (activityArray) => dispatch(getActivitiesSuccess('activities', activityArray)),
    () => dispatch(getActivitiesFailure()),
  )
  return {
    type: GET_EXTRA_ACTIVITIES,
    payload: { isLoading: true },
  }
}
