import themeStyled from '@optios-frontend-website/shared-ui/src/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const ImageGrid = themeStyled.div`
  display: flex;
  flex-wrap: wrap;
  box-shadow: inset 1px  1px 0 rgba(0,0,0,0.1);
`
export const Item = themeStyled.div`
  width: 250px;
  box-shadow: inset -1px -1px 0 rgba(0,0,0,0.1);
  cursor: pointer;
  padding: .5em;
  &.active{
    background-color: ${(props) => props.theme.colors.info};
  }
`

export const ItemDelete = themeStyled.div`
  display: flex;
  justify-content: flex-end;
     &:hover {
   cursor: pointer
   }
`

export const ItemDeleteWrapper = themeStyled.div`
    background: white;
    width: 19px;
    height: 19px;
    position: absolute;
    border-radius: 50%;
`

export const ItemDeleteIcon = themeStyled(FontAwesomeIcon)`
  color:#859391;
  height: 1.2em;
   &:hover {
    color: #f55656;
   }
`

export const Image = themeStyled.img`
  width: 100%;

`
