import styled from '@optios-frontend-website/shared-ui/src/theme'
import { css } from '@emotion/core'
import { Link as RouterLink } from 'react-router-dom'

export const ToolbarButtonStyle = css`
  font-family: 'proxima-nova', sans-serif; !important;
  font-style: normal !important;
  font-weight: normal !important;
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  fontsize: 16px;
  padding: 0.6em 1em;
  margin: 0 1px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 3px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 3px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  display: inline-block;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  cursor: pointer;
  vertical-align: middle;
  border-radius: 0;

  &:hover,
  &:focus,
  &:active {
    background: rgba(255, 255, 255, 0.2);
  }
  &.selected {
    background: #2164db;
  }
`

export const LinkStyle = css`
  font-family: 'proxima-nova', sans-serif; !important;
  font-style: normal !important;
  font-weight: normal !important;
  color: #fff;
  fontsize: 16px;
  display: inline-block;
  cursor: pointer;
  padding: 0 1em;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
`
export const OverrideDefaultButton = css`
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
`
export const breakTextToIcon = css`
  &.icon {
    display: none;
  }
  @media (max-width: 767px) {
    display: none;
    &.icon {
      display: block;
    }
  }
`

export const Settings = styled.div`
  pointer-events: auto;
  position: fixed;
  z-index: 20;
  top: 0;
  left: 5em;
  right: 0;
  background: #232934;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: space-between;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 12px 10px;
  font-size: 16px;
  @media (max-width: 1023px) {
    left: 3.75em;
  }
  @media (max-width: 767px) {
    max-height: 48px;
    left: 48px;
    padding: 6px 4px;
  }
`
export const Navigation = styled.nav``

export const NavLinks = styled.ul`
  list-style: none;
`
export const NavLink = styled.li`
  ${ToolbarButtonStyle}
`

export const Link = styled(RouterLink)`
  color: #fff;
  text-decoration: none;
  ${breakTextToIcon}
`

export const PublishBtn = styled.button`
  ${OverrideDefaultButton}
  ${ToolbarButtonStyle}
  align-self: flex-end
`
export const SavePageBtn = styled.button`
  ${OverrideDefaultButton}
  ${ToolbarButtonStyle}
  align-self: flex-end
`

export const DynamicSpan = styled.span`
  ${breakTextToIcon}
`

export const LinkSpan = styled.a`
  ${LinkStyle}
  @media (max-width: 767px) {
    white-space: nowrap;
    width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
