export enum WebsiteImage {
  WEBSITE_GALLERY = 'WEBSITE_GALLERY',
  WEBSITE_BANNER = 'WEBSITE_BANNER',
  WEBSITE_LOGO = 'WEBSITE_LOGO',
}

export enum Recaptcha {
  SITE_KEY = '6Le3j7EZAAAAADDB1o6I5kySgx8jTRgKdjPyIrPb',
  SECRET_KEY = '6Le3j7EZAAAAAIKVIeIck_NngzmgatHy7s8wX49w',
}

export enum Regexp {
  EMAIL = '^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$',
  URL = '^(http:\\/\\/www\\.|https:\\/\\/www\\.|http:\\/\\/|https:\\/\\/)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$',
  PHONE = '^[-\\s\\./\\+0-9]+$',
}

export enum Api {
  BOOKING_URL = 'https://booking.optios.net/',
  REQUEST_URL = 'http://api.optios.local/',
  PRIVACY = 'https://optios.net/en/privacybeleid/',
}

export enum SectionNames {
  TEXTANDIMAGES = 'textandimages',
  GALLERY = 'gallery',
  HEADER = 'header',
  PRICING = 'pricing',
  CONTACT = 'contact',
  HTML = 'html',
  FORM = 'form',
  MAP = 'map',
  FOOTER = 'footer',
  FEEDBACK = 'feedbacks',
  BOOKING = 'booking',
  INLINE = 'inline',
  PHOTO = 'photo',
  EMPLOYEE = 'employee',
  BUTTON = 'button',
  FIXEDBUTTON = 'fixedbutton',
  FIXEDBUTTONSINGLE = 'fixedbuttonsingle',
}

export enum EventStatus {
  STATUS_CREATED = 1,
  STATUS_BUILDING = 2,
  STATUS_BUILDING_COMPLETE = 3,
  STATUS_BUILDING_FAILED = 4,
  STATUS_UPLOADING = 5,
  STATUS_UPLOADING_COMPLETE = 6,
  STATUS_UPLOADING_FAILED = 7,
  STATUS_ROUTING = 8,
  STATUS_ROUTING_COMPLETE = 9,
  STATUS_ROUTING_FAILED = 10,
  STATUS_DONE = 11,
}
