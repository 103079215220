import themeStyled from '../../theme'
import styled from '@emotion/styled'

export const VideoSection = styled.section<{ windowWidth: number }>`
  top: 50%;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 32em;
  overflow: hidden;
  transform: translateY(1%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.xl {
    height: 24em;
  }
  &.lg,
  &.md {
    height: 20.5em;
  }
  &.sm,
  &.xs {
    height: 10em;
  }
`

export const VideoBanner = styled.video`
  z-index: -1;
  position: absolute;
  top: 30%;
  left: 50%;
  min-height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
`

export const Section = styled.section<{ src: string }>`
  background-image: url(${({ src }) => src});
  background-position: center center;
  background-size: cover;
  height: 28em;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  &.xl {
    height: 24em;
  }
  &.lg,
  &.md {
    height: 20.5em;
  }
  &.sm,
  &.xs {
    height: 18em;
  }
`
export const HeaderStyled = styled.header`
  text-align: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.secondary};
  color: #fff;
  padding: 1.5em 0;
  &.md,
  &.sm {
    padding: 0.8em 0;
  }
  &.xs {
    padding: 1em 0;
  }
  em {
    font-style: normal;
    font-weight: 200;
  }
`
export const Title = styled.h2`
  display: inline-block;
  margin: 0;
  font-size: 1.25em;
  vertical-align: middle;
`

export const Button = themeStyled.a`
  background-color: ${(props) => props.theme.colors.info};
  border-radius: 5px;
  border: 0;
  color: ${({ theme }) => theme.colors.whiteFont};
  cursor: pointer;
  display: inline-block;
  padding: 0 1.5em;
  line-height: 2.75em;
  min-width: 9em;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  letter-spacing: -0.025em;
  vertical-align: middle;
  margin-left: 1em;
  &:hover{
    border-color: transparent;
  }
`
