import styled from '@optios-frontend-website/shared-ui/src/theme'

import { ReactComponent as ArrowSvg } from 'assets/images/select-arrow.svg'

export const SelectArrow = styled(ArrowSvg)`
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 0;
  position: absolute;
  pointer-events: none;
  margin: 6px 0;
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  &.active {
    transform: rotate(180deg);
  }
`

export const Label = styled.label`
  display: flex;
  cursor: pointer;
  margin: 0 0.7em 0.7em 0;
  min-height: 1.6em;
  position: relative;
  width: 100%;
  z-index: 1;
`

export const Text = styled.div`
  padding: 0.2em 0.5em 0.2em 0;
  flex: 0 0 30%;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.blackFont};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.sizes.small};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const Input = styled.input`
  left: 0;
  width: 100%;
  bottom: 0;
  opacity: 0;
  position: absolute;
`
export const Select = styled.div`
  font: inherit;
  flex: 1 0 0;
  background: rgba(255, 255, 255, 0);
  position: relative;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  color: #666;
  cursor: pointer;
  height: 1.4em;
  line-height: 1;
  min-width: 200px;
  outline: 0;
  z-index: 1;
  &.active {
    box-shadow: 0 1px 0 #2164db;
  }
`
export const SelectMenu = styled.div`
  height: 2.1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  border-radius: 6px 6px 0 0;
`

export const Selected = styled.span`
  padding: 0.2em 0;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.text};
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
`
export const List = styled.ul`
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  display: block;
  height: 0;
  list-style: none;
  opacity: 0;
  overflow: hidden;
  padding: 0 1px;
  width: 100%;
  z-index: 2;
  &.active {
    height: auto;
    opacity: 1;
  }
  min-width: 120px;
  max-height: 112px;
  min-width: 16px;
  overflow-x: hidden;
  overflow-y: auto;
`
export const Item = styled.li`
  padding: 0.2em 22px;
  line-height: 1.4;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.sizes.small};
  font-family: ${({ theme }) => theme.fonts.primary};
  &:hover {
    background-color: #eeeeee;
  }
`
export const Error = styled.span`
  color: ${(props) => props.theme.colors.danger};
`
