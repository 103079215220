import styled from '@optios-frontend-website/shared-ui/src/theme'

export const WrapperLayer = styled.div<{
  sliderValue: number
}>`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ sliderValue }) => `rgba(0, 0, 0, 0.${sliderValue})`};
`

export const Wrapper = styled.section<{
  image?: string | null
}>`
  height: 100%;
  padding: 5em 0 3em 0;
  position: relative;
  background: ${({ image }) => (image ? `url(${image})` : 'none')} no-repeat center center;
  background-size: cover;
`

