import React, { FC, useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import SharedCustomHTML from '@optios-frontend-website/shared-ui/src/components/CustomHTML'
import { IHTML, PageSectionTypes } from 'store/page/types'
import Modal from 'shared/Modal'
import { EditorContainer } from './style'
import Button from 'shared/ButtonModal'
import { useSelector } from 'react-redux'
import { RootState } from 'store/index'

type Props = {
  data: IHTML
  updateSection: (name: string, section: PageSectionTypes, position: number, col: number) => void
  position: number
  col: number
  fontColor: string
  isEditing?: boolean
}
//NOG FEEDBACK VRAGEN OP VLAK VAN TYPESCTIPT ETC
const customQuillModul = {
  toolbar: [
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'link'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
  ],
}

const CustomHTML: FC<Props> = (props: Props) => {
  const [value, setValue] = useState(props.data.content)
  const locale = useSelector<RootState, string>((state) => state.config.locale)
  const [modalIsOpen, setIsOpen] = useState(props.isEditing)
  const { t } = useTranslation()

  useEffect(() => {
    setIsOpen(props.isEditing)
  }, [props.isEditing])

  const onRequestClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setIsOpen(false)
  }

  const onSave = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setIsOpen(false)

    props.updateSection('html', { content: value }, props.position, props.col)
  }

  const open = () => {
    setIsOpen(true)
  }

  return (
    <>
      <SharedCustomHTML fontColor={props.fontColor} handleClick={open} data={props.data} />
      {modalIsOpen ? (
        <Modal
          isOpen={modalIsOpen}
          onSubmit={onSave}
          onRequestClose={onRequestClose}
          title={t('html_part:title_modal', { lng: locale })}
        >
          <EditorContainer>
            <ReactQuill theme="snow" modules={customQuillModul} value={value} onChange={setValue} id={uuidv4()} />
          </EditorContainer>
          <div style={{ textAlign: 'right' }}>
            <Button handleClick={onRequestClose} className="dialog-close">
              {t('page:cancel', { lng: locale })}
            </Button>
            <Button type="submit" handleClick={onSave}>
              {t('page:save', { lng: locale })}
            </Button>
          </div>
        </Modal>
      ) : null}
    </>
  )
}

export default CustomHTML
