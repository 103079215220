import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'

const spinner = keyframes`
  100% {
    transform: rotate(360deg);
  }
`
export const Container = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
`
export const SpinnerCenter = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  display: inline-block;
}
`
export const SpinnerWrapper = styled.div`
  position: relative;
  width: 77.1429px;
  height: 77.1429px;
  animation: 0.6s linear 0s infinite normal forwards running ${spinner};
`
export const SpinnerBorder = styled.div`
  width: 60px;
  height: 60px;
  opacity: 0.1;
  box-sizing: content-box;
  border-radius: 100%;
  border-width: 8.57143px;
  border-style: solid;
  border-color: #0090ff;
  border-image: initial;
`
export const SpinnerDot = styled.div`
  width: 8.57143px;
  height: 8.57143px;
  background-color: #0090ff;
  opacity: 0.8;
  position: absolute;
  top: 25.7143px;
  border-radius: 100%;
  animation: 0.6s linear 0s infinite normal forwards running ${spinner};
`

export const SpinnerText = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 77px;
  font-family: 'proxima-nova', sans-serif; !important;
`
