import React, { FC, ReactNode } from 'react'
import { Container, Row, Col } from 'react-grid-system'

import { IFixedButtonSingle, ITextAndImage, PageSectionTypes } from '../../types/sections'
import Item from './Item'
import { Wrapper, WrapperLayer } from './style'

type Props = {
  data: ITextAndImage[] | IFixedButtonSingle[]
  onClick?(id?: number): void
  children?: ReactNode
  updateSection: (name: string, section: PageSectionTypes, position: number) => void
  position: number
  config: any
}

const TextImage: FC<Props> = (props: Props) => {
  const numberOfItems: number = props.data.length + (props.children ? 1 : 0)

  return (
    <>
      <Wrapper
        id='wrapper'
      >

        <Container>
          <Row>
            {props.data.map((item, index) => (
              <Col lg={numberOfItems > 2 ? 4 : 6} md={12} key={index} offset={{ lg: numberOfItems === 1 ? 3 : 0 }}>
                <Item
                  className={index === 0 ? 'first' : ''}
                  index={index}
                  item={item}
                  onClick={props.onClick}
                />
              </Col>
            ))}
            {props.children && (
              <Col lg={numberOfItems > 2 ? 4 : 6} md={12} offset={{ lg: numberOfItems === 1 ? 3 : 0 }}>
                {props.children}
              </Col>
            )}
          </Row>
        </Container>
      </Wrapper>
    </>
  )
}

export default TextImage
