import React, { FunctionComponent } from 'react'

import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { IProps } from './IProps'
import { NextIcon, TileContainer, TileGraphic, TileText } from './style'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const Tile: FunctionComponent<IProps> = ({ tileColor, tileTitle, onClick }) => {
  const faArrowIcon = faChevronRight as IconProp
  return (
    <TileContainer onClick={onClick}>
      <TileGraphic bgColor={tileColor} />
      <TileText>{tileTitle}</TileText>
      <NextIcon icon={faArrowIcon} />
    </TileContainer>
  )
}
